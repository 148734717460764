
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    registerSuccess: '',
    registerFailure: '' ,
    employerDetails: {},
    loading: false,
    employerSuccess: '',
    employerFailure: '',
    employerList: [],
    employerUpdateFailure:"",
    employerUpdateSuccess:"",
    employerDetailsResponse:"",
    addJobFailure: "",
    addJobSuccess: "",
    jobsListData: [],
    bulkJobPostSuccess: "",
    bulkJobPostFailure: "",
    jobDetails:"",
    updateJobSuccess: "",
    updateJobFailure: "",
    bulkJobsList: [],
    profilesList: [],
    profileStatusSuccess: "",
    profileStatusFailed: "",
    sentMsgSuccess: "",
    sentMsgFailed: "",
    noteSSuccess: "",
    noteSFail: "",
    messageList: [],
    notesList: [],
    matchedJobs: [],
    followingCommunities: [],
    subscribedList: "",
    employerListLoading: false,
    profileMatchesLoading: false,
    jobsListLoading:false,
    subscribeListLoad: false,
    empCandidatesLoad: false,
    candidatesMatched: [],
    empTopSkills: [],
    empTopSkillsLoad: false,
    joinedGroups: [],
    joinedGroupsLoading: false,
    recentLogins: [], 
    recentLoginsLoading: false,
    rejectedStats: [], 
    interviewedStats: [], 
    hiredStats: [], 
    hireStatsLoading: false, 
    rejectedStatsLoading: false, 
    interviewStatsLoading: false,
    recentMessagesLoading: false,
    recentMessages: [],
    hiredAvgLoading: false,
    hiredAvgDays: '',
    rejectedAvgLoading: false,
    rejectedAvgDays: [],
    appliedJobsStatsLoading: false,
    mostAppliedJobs: [],
    appliedListLoading:  false,
    appliedTalent: [],
    jobDeleteStatusSuccess:'',
    jobDeleteStatusFailed :'',    
    companySuccesStatus:'',
    companyFailStatus:''

    
};

const  employerSlice = createSlice({
    name: 'employer',
    initialState,
    reducers: {
        employerRegisterRequest: (state,action) => {
            state.loading = true;
        },
         employerRegisterSuccess: (state,action) => {
            state.registerSuccess = action.payload;
            state.registerFailure = '';
            state.loading = false;
        },
         employerRegisterFailure: (state,action) => {
            state.registerSuccess = '';
            state.registerFailure = action.payload;
            state.loading = false;
        },
         employerDetailsSuccess: (state,action) => {
            state. employerDetails = action.payload;
        },
         employerDetailsFailure: (state,action) => {
            state. employerDetails = {};
        },
        inviteEmployerFailure: (state, action) => {
            state.employerFailure = action.payload;
        },
        inviteEmployerSuccess: (state, action) => {
            state.employerSuccess = action.payload;
        },
        employerListSuccess: (state,action) => {
            state.employerList = action.payload
            state.employerListLoading = false
        },
        employerListFailure: (state,action) => {
            state.employerList = []
            state.employerListLoading = false
        },
        employerListRequest: (state) => {
            state.employerList = []
            state.employerListLoading = true
        },
        employerToastReset: (state) => {
            state.employerSuccess = ''
            state.employerFailure = ''
            state.registerSuccess = ''
            state.registerFailure = ''
            state.addJobFailure= ''
            state.addJobSuccess = ''
            state.updateJobFailure= ''
            state.updateJobSuccess = ''
            state.profileStatusSuccess= ""
            state.profileStatusFailed= ""
            state.sentMsgSuccess = ""
            state.sentMsgFailed = ""
            state.jobDeleteStatusFailed=""
            state.jobDeleteStatusSuccess=""
            state.companySuccesStatus=''
            state.companyFailStatus=''
        },
        updateEmployerFailure: (state, action) => {
            state.employerUpdateFailure = action.payload;
        },
        updateEmployerSuccess: (state, action) => {
            state.employerUpdateSuccess = action.payload;
        },
        employerDetailsData: (state, action) => {
            state.employerDetailsResponse = action.payload;
        },
        employerSucessMessageRest: (state, action) => {
            state.employerUpdateSuccess = "";
            state.employerUpdateFailure = "";
            state.addJobFailure= ''
            state.addJobSuccess = ''
            state.bulkJobPostFailure = ""
            state.bulkJobPostSuccess = ""
        } ,
        setAddJobSuccess: (state, action ) => {
            state.addJobSuccess = action.payload
            state.addJobFailure = ""
            state.loading = false
        } ,
        setAddJobFailure : (state, action) => {
            state.addJobSuccess = ""
            state.addJobFailure = action.payload
            state.loading = false
        } ,
        setJobsList : (state, action) => {
            state.jobsListData = action.payload
            state.jobsListLoading = false
        },
        setBulkJobsPostSuccess:  (state, action) => {
            state.bulkJobPostSuccess = action.payload
            state.bulkJobPostFailure = ""
            state.loading = false
        },
        setBulkJobsPostFailure:  (state, action) => {
            state.bulkJobPostSuccess = ""
            state.bulkJobPostFailure = action.payload
            state.loading = false
        },
        setJobsDetails : (state, action) => {
            state.jobDetails = action.payload
            state.loading = false
        },
        setUpdateJobSuccess: (state, action ) => {
            state.updateJobSuccess = action.payload
            state.updateJobFailure = ""
            state.loading = false
        },
        setUpdateJobFailure: (state, action ) => {
            state.updateJobSuccess = ""
            state.updateJobFailure = action.payload
            state.loading = false
        },
        setBulkJobsList: (state, action) => {
            state.bulkJobsList = action.payload
            state.loading = false
        },
        setMatchedProfilesList: (state, action) => {
            state.profilesList = action.payload
            // state.loading = false
            state.profileMatchesLoading = false
            
        },
        profileUpdateStatusSuccess: (state, action) => {
            state.profileStatusSuccess = action.payload
            state.loading = false
        },
        profileUpdateStatusFailure: (state, action) => {
            state.profileStatusFailed = action.payload
            state.loading = false
        }, 
        messageSentSuccess: (state, action) => {
            state.sentMsgSuccess = action.payload
            state.loading = false
        },
        messageSentFailed: (state, action) => {
            state.sentMsgFailed = action.payload
            state.loading = false
        }, noteSentSuccess: (state, action) => {
            state.noteSSuccess = action.payload
            state.loading = false
        },
        noteSentFailed: (state, action) => {
            state.noteSFail = action.payload
            state.loading = false
        },
        setMessageList: (state, action) => {
            state.messageList = action.payload
            state.loading = false
        },
        setNoteList: (state, action) => {
            state.notesList = action.payload
            state.loading = false
        },
        jobsListRequest: (state) => {
            state.jobsListData = []
            state.jobsListLoading = true
        },
        jobsMatchedLoading: (state) => {
            state.jobsListLoading = true
        },
        setMatchedJobsList: (state, action) => {
            state.matchedJobs = action.payload
            state.matchedJobsLoading = false
        },
        setFollowingCommunities: (state, action) => {
            state.followingCommunities = action.payload
            state.loading = false
        },
        setSubscribedCommunities: (state, action) => {
            state.subscribedList = action.payload
            state.subscribeListLoad = false
        },
        bulkExternalUploadSuccess: (state, action) => {
            state.externalBulkUploadSuccess = action.payload
            state.loading = false
        },
        bulkExternalUploadFailed: (state, action) => {
            state.externalBulkUploadFailure = action.payload
            state.loading = false
        },
        bulkExternalUploadReset: (state) => {
            state.externalBulkUploadSuccess = ""
            state.externalBulkUploadFailure = ""
        },
        setExternalJobsList: (state, action) => {
            state.loading = false
            state.externalJobsList= action.payload
        },
        resetSubscriberList: (state) => {
            state.subscribedList = []
        },
        setMatchProfileListRequest: state => {
            state.profileMatchesLoading = true 
            state.profilesList = []
        },
        matchedJobsRequest: state => {
            state.matchedJobs = []
            state.matchedJobsLoading = true
        }, jobsListReset: state => {
            state.jobsListData = []
            state.jobsListLoading = false
        },
        subscribeListRequest: (state) => {
            state.subscribeListLoad = true 
            state.subscribedList = []
        },
        empCandidatesMatchesReq: (state) => {
            state.empCandidatesLoad = true 
            state.candidatesMatched = []
        },
        setEmpCandidateMatches: (state, action) => {
            state.empCandidatesLoad = false 
            state.candidatesMatched = action.payload
        },
        empTopSkillsReq: state => {
            state.empTopSkills = [] 
            state.empTopSkillsLoad = true
        },
        setEmpTopSkills: (state, action) => {
            state.empTopSkills = action.payload
            state.empTopSkillsLoad = false
        },
        setJoinedGroups: (state, action) =>{
            state.joinedGroups = action.payload
            state.joinedGroupsLoading = false
        },
        joinedGroupRequest: (state, action) =>{
            state.joinedGroups = []
            state.joinedGroupsLoading = true
        },
        recentLoginsReq: (state) => {
            state.recentLogins = []
            state.recentLoginsLoading = true
        },
        setRecentLogins: (state, action) => {
            state.recentLogins = action.payload
            state.recentLoginsLoading = false
        },
        hireStatsRequest: state => {
            state.hireStatsLoading = true 
            state.hiredStats = []
        },
        setHiredStats: (state, action) => {
            state.hiredStats = action.payload
            state.hireStatsLoading = false 
        },
        interviewStatsRequest: state =>{
            state.interviewStatsLoading = true 
            state.interviewedStats = []
        },
        setInterviewdStats: (state, action) => {
            state.interviewedStats = action.payload
            state.interviewStatsLoading = false 
        },
        rejectedStatsRequest: state =>{
            state.rejectedStatsLoading = true 
            state.rejectedStats = []
        },
        setRejectedStats: (state, action) => {
            state.rejectedStats = action.payload
            state.rejectedStatsLoading = false 
        },
        setRecentMessages: (state, action) => {
            state.recentMessagesLoading = false
            state.recentMessages =  action.payload
        },
        recentMessagesReq: (state, action) => {
            state.recentMessagesLoading = true
            state.recentMessages = []
        },
        setHiredAvgDays: (state, action) => {
            state.hiredAvgLoading = false
            state.hiredAvgDays = action.payload
        }, 
        hiredAvgDaysRequest: (state) => {
            state.hiredAvgLoading = true
            state.hiredAvgDays = []
        },
        setRejectedAvgDays: (state, action) => {
            state.rejectedAvgLoading = false
            state.rejectedAvgDays = action.payload
        }, 
        rejectedAvgDaysRequest: (state) => {
            state.rejectedAvgLoading = true
            state.rejectedAvgDays = []
        },   
        requestTopAppliedJobs: (state) => {
            state.appliedJobsStatsLoading = true
            state.mostAppliedJobs = []
        }, 
        setTopAppliedJobs: (state, action) => {
            state.appliedJobsStatsLoading = false
            state.mostAppliedJobs = action.payload
        }, 
        setAppliedTalentList: (state, action) => {
            state.appliedListLoading = false
            state.appliedTalent = action.payload
        },
        requestAppliedTalent: (state) => {
            state.appliedListLoading = true
            state.appliedTalent = []
        }, 
        setjobDeleteStatusSuccess: (state, action) => {
            state.jobDeleteStatusSuccess = action.payload
            state.loading=false
        },
        setjobDeleteStatusFailure:(state, action) => {
            state.jobDeleteStatusFailed = action.payload
            state.loading=false
        },
        setCompanyDeleteSuccess: (state, action) => {
            state.companySuccesStatus = action.payload
            state.companyFailStatus = ''
            state.loading = false
            
          },
        setCompanyDeleteFailed: (state, action) => {
            state.companyFailStatus = action.payload
            state.companySuccesStatus = ''
            state.loading = false
        }

    }
});

export const {setJobsDetails,employerSucessMessageRest, employerDetailsData,updateEmployerSuccess,updateEmployerFailure,employerRegisterSuccess,  employerRegisterFailure, employerRegisterRequest,inviteEmployerFailure,inviteEmployerSuccess, employerListSuccess, employerListFailure,employerListRequest,employerToastReset, setAddJobSuccess,setAddJobFailure,
    setJobsList ,setBulkJobsPostFailure,setBulkJobsPostSuccess,
    setUpdateJobSuccess, setUpdateJobFailure ,setBulkJobsList,
    setMatchedProfilesList,  profileUpdateStatusSuccess,
    profileUpdateStatusFailure, messageSentFailed,
    messageSentSuccess, noteSentSuccess,
    noteSentFailed,setMessageList,setNoteList,jobsListRequest, setMatchedJobsList,
    setFollowingCommunities, setSubscribedCommunities,
    bulkExternalUploadSuccess,bulkExternalUploadFailed,setExternalJobsList,bulkExternalUploadReset,
    resetSubscriberList, setMatchProfileListRequest,profileMatchesLoading,
    matchedJobsRequest,jobsMatchedLoading,jobsListReset,subscribeListRequest,
    empCandidatesMatchesReq, setEmpCandidateMatches,
    setEmpTopSkills, empTopSkillsReq,
    setJoinedGroups,joinedGroupRequest,
    setRecentLogins, recentLoginsReq,
    hireStatsRequest,
    rejectedStatsRequest,
    interviewStatsRequest,
    setHiredStats, setInterviewdStats, setRejectedStats,
    recentMessagesReq, setRecentMessages,
    setHiredAvgDays, hiredAvgDaysRequest,
    rejectedAvgDaysRequest, setRejectedAvgDays,
    requestTopAppliedJobs, setTopAppliedJobs,setCompanyDeleteSuccess,setCompanyDeleteFailed,
    setAppliedTalentList, requestAppliedTalent,setjobDeleteStatusSuccess,setjobDeleteStatusFailure
} =  employerSlice.actions;
export default  employerSlice.reducer;
