
import React from 'react';
import { Pagination } from 'react-bootstrap';

function PaginationComponent({ currentPage, totalItems, itemsPerPage, onPageChange }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageItems = [];
  const pageNums = 10;
  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };
  if (totalItems > 0) {
    pageItems.push(
      <Pagination.Prev key="prev" onClick={handlePrevClick} disabled={currentPage === 1} />
    );
  }

  if (totalPages < pageNums) {
    for (let number = 1; number <= totalPages; number++) {
      pageItems.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
  }
  else {
    let leftSide = (currentPage - pageNums / 2) > 1
    let rightSide = (currentPage + pageNums / 2) < totalPages
    let start = Math.max(1, Math.round(currentPage - pageNums / 2))
    let end = Math.min(totalPages, Math.round(currentPage + pageNums / 2))
    if (leftSide) {
      pageItems.push(
        <Pagination.Ellipsis
          key='leftellipsis'
        />
      )
    }
    for (let i = start; i <= end; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </Pagination.Item>
      )
    }
    if (rightSide) {
      pageItems.push(
        <Pagination.Ellipsis
          key='rightellipsis'
        />
      )
    }
  }

  if (totalItems > 0) {
    pageItems.push(
      <Pagination.Next key="next" onClick={handleNextClick} disabled={currentPage === totalPages} />
    );
  }
  return (
    <Pagination>
      {pageItems}
    </Pagination>
  );
}

export default PaginationComponent;
