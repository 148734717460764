import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { Controller } from 'react-hook-form';
import '@/assets/styles/rangeSlider.scss'

function RangeSlider({ label, onSliderChange, sliderValue, onChange, fieldName, rangeValue, control, handleRangeChange, inputValue }) {
  return (
    <React.Fragment>
      <div className='range-slider'>
        <Form.Label>{label}</Form.Label>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={sliderValue}
          render={({ field }) => (
            
            <Slider
            {...field}
              min={1}
              max={5}
              step={1}
              value={field.value}
              name={fieldName}
              // onChange={(e, value) => onSliderChange(e, value)}
              onChange={(value) =>{
                field.onChange(value);
                 onSliderChange(value, fieldName)}
              }
              tooltip={false}
            />
          )}
        />
        
        <p className='slides'>
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>5</span>
        </p>
      </div>

    </React.Fragment>
  );
}

export default RangeSlider;
