import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Controller } from 'react-hook-form';
import { checkProperty } from "../utils/helpers"
function SimpleRadio({ fieldName, isRequired, errors, errorText, control, id, defaultCheck, onRadioChange, label }) {
  return (
    <React.Fragment>
      <div className="radio-buttons">
        {label && <Form.Label className="form-labels">{isRequired ? label + "*" : label}</Form.Label>}
        <Controller
          name={fieldName}
          control={control}
          defaultValue={""+defaultCheck}
          rules={{ required: isRequired }}
          render={({ field }) => (
            <>
              <Form.Check
                label="Yes"
                name={field.name}
                type="radio"
                id={fieldName + "yes"}
                value={"true"}
                checked={(""+defaultCheck) === "true"?field.value:false}
                onChange={(event) => {
                  field.onChange(event);
                  onRadioChange(event);
              }}
              />
              
              <Form.Check
                label="No"
                name={field.name}
                type="radio"
                id={fieldName + "no"}
                value={"false"}
                checked={(""+defaultCheck) === "false"?true:false}
                onChange={(event) => {
                  field.onChange(event);
                  onRadioChange(event);
              }}
              />
            </>
          )}
        />

        {errors && fieldName && checkProperty(fieldName.split('.'), 'length') > 2 &&
          checkProperty(errors, fieldName.split('.')[0], fieldName.split('.')[1]) &&
          checkProperty(errors[fieldName.split('.')[0]][fieldName.split('.')[1]], fieldName.split('.')[2], 'type') === "required" &&
          <span className='form-error'>{"* " + errorText + ' is reqired'}</span>}

        {errors && fieldName && checkProperty(fieldName.split('.'), 'length') > 1 &&
          checkProperty(errors, fieldName.split('.')[0], fieldName.split('.')[1]) &&
          checkProperty(errors[fieldName.split('.')[0]], fieldName.split('.')[1], 'type') === "required" &&
          <span className='form-error'>{"* " + errorText + ' is reqired'}</span>}

        {errors[fieldName] && (
          <span className="form-error">{"* " + errorText} is required</span>
        )}
      </div>
    </React.Fragment>
  );
}

export default SimpleRadio;
