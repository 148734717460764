import React from 'react';
function FileIcon() {
    return (
      <div className='file_svg'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30">             
            <g transform="translate(-6)">
                <path class="a" d="M32,25H19.5a.5.5,0,1,0,0,1H32a.5.5,0,0,0,0-1Z" transform="translate(-6.498 -12.5)"/>
                <path class="a" d="M19.5,18h5a.5.5,0,1,0,0-1h-5a.5.5,0,1,0,0,1Z" transform="translate(-6.499 -8.5)"/>
                <path class="a" d="M32,33H19.5a.5.5,0,0,0,0,1H32a.5.5,0,0,0,0-1Z" transform="translate(-6.498 -16.5)"/>
                <path class="a" d="M32,41H19.5a.5.5,0,0,0,0,1H32a.5.5,0,0,0,0-1Z" transform="translate(-6.498 -20.5)"/>
                <path class="a" d="M32,49H19.5a.5.5,0,1,0,0,1H32a.5.5,0,0,0,0-1Z" transform="translate(-6.498 -24.498)"/>
                <path class="a" d="M27.5,7.293V0H6V27.5H8.5V30H30V9.793ZM23,4.207l4.5,4.5.793.793H23ZM7,26.5V1H26.5V6.293L22.707,2.5H8.5v24ZM9.5,29V3.5H22v7h7V29Z"/>
            </g>
        </svg>
      </div>
    )
}

export default FileIcon;