
import { lazy } from 'react';
const TalentDashboard = lazy(() => import('../views/Talent/Dashboard'));
const TalentRegister = lazy(() => import('../views/Talent/Register'));
const EditStudentProfile = lazy(() => import('../views/Profile/editStudentProfile'));
const Jobs = lazy(() => import('../views/Talent/Jobs'));
const  Talent  = lazy(() => import('../views/Dashboard/Talent'));

const talentRoutes = [
  { path: '/talent/dashboard', component: TalentDashboard, exact: true },
  { path: '/talent/register', component: TalentRegister, exact: true },
  { path: '/invitation/talent', component:  TalentRegister, exact: true },
  { path: '/student/edit-profile', component:  EditStudentProfile, exact: true },
  { path: '/matched/jobs', component: Jobs  , exact: true },
  { path: '/dashboard/talent', component: Talent  , exact: true }
  
  
];

export default talentRoutes;
