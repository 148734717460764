import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./views/Utils.js";
import "../src/assets/styles/style.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AppRoutes from "./routes/index.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider, useSelector } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { getMasterData, uploadFile } from "./services/authService.js";
import {
  updateCommunityInfo,
  updateUserInfo,
  getCommunityDetails,
  userDetails,
} from "./services/communityService.js";
import {
  updateEmployerInfo,
  employerDatails,
} from "./services/employerService.js";
import IconChatBot from "./views/iconChatBot.js";
//import FileUploader from './components/FileUploader.js'; 

function App() {
  // const [bot, setBot] = useState(false)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <UserProvider>
          <BrowserRouter>
            <div className="layout">
              <Header
                getMasterData={getMasterData}
                updateCommunityInfo={updateCommunityInfo}
                updateUserInfo={updateUserInfo}
                getCommunityDetails={getCommunityDetails}
                userDetails={userDetails}
                updateEmployerInfo={updateEmployerInfo}
                employerDatails={employerDatails}
                // FileUploader={FileUploader}
                fileUploader={uploadFile}
              />
              <div className="maincontent">
                <AppRoutes />
              </div>
              <IconChatBot />
             
              <Footer />
            </div>
          </BrowserRouter>
        </UserProvider>
        <ToastContainer />
      </PersistGate>
    </Provider>
  );
}

export default App;
