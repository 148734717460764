// Redux action imports for user management.
import { 
    bulkInviteSuccess,
    bulkInviteFailure,
    bulkInviteRequest,
    userStatusUpdateSuccess,
    userStatusUpdateFailure,
    resendActivationLinkSuccess,
    resendActivationLinkFailure,
    setBulkInviteList,
    setDashboardStats
  } from '../redux/slices/userSlice';
  import {apiService} from './apiService';
  import { 
    commonUploads,
    getSignedUrl,
    signupIndividualSuccess,
    signupIndividualFailure,
    individualSignupRequest,
    viewCountSuccess,
    viewCountFailure,
    createDiscussionSuccess,
    createDiscussionFailure,
    discussionsDetailsSuccess,
    discussionsDetailsFailure,
    setFeCommunitySuccess,
    setFeCommunityFailure,
    setFeatureCommList,
    followFeatCommunitySuccess,
    followFeatCommunityFailure,
    joinFailure, joinSuccess,
    setUnfollowFailure, setUnfollowSuccess,
    setUnjoinSuccess, setUnjoinFailure,
    setPlansList,
    setPaymentMethodSuccess,
    setPaymentMethodFailure,
    attachPaymentRequest,
    attachPaymentSuccess,
    attachPaymentFailure,
    subscribePlanSuccess,
    subscribePlanFailure,
    chargeForPlanSuccess,
    chargeForPlanFailure,
    eventSaveSuccess,
    eventSaveFailure,
    setInvoiceList,
    createPlanSuccess,
    createPlanFailure,
    claimRequestSuccess,
    claimRequestFailure,
    confirmOtpSuccess,
    confirmOtpFailure,
    masterAddSuccess,
    masterAddFailure,
    featCommunityListRequest,

  } from '../redux/slices/commonSlice';
  import { 
    talentRegisterRequest
  } from '../redux/slices/talentSlice';
  import { showToast } from '../views/Utils';

import axios from "axios";
export function EmployerRegister({url,payLoad}) {
  return axios.post(url, payLoad)
    .then((response) => {
        return response
    }, (error) => {
        return error
    });
}
export function CommunityRegister({url,payLoad}) {
    return axios.post(url, payLoad)
      .then((response) => {      
          return response
      }, (error) => {
          return error
      });
  }
  export function SignIn({url,payLoad}) {
    return axios.post(url, payLoad)
      .then((response) => {      
          return response
      }, (error) => {
          return error
      });
  }
  export function Resetpassword({url,payLoad}) {
    return axios.post(url, payLoad)
      .then((response) => {      
          return response
      }, (error) => {
          return error
      });
  }
  export function Forgotpassword({url,payLoad}) {
    return axios.post(url, payLoad)
      .then((response) => {      
          return response
      }, (error) => {
          return error
      });
  }

  
export const uploadFiles = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post('s3/upload', payLoad?.formData,{
      headers: {
        'Content-Type': 'multipart/form-data',
      }});
    const returnData = response.data?.result;
    dispatch(commonUploads(returnData));
  } catch (error) {
      // Detailed error handling can be incorporated based on requirements.
      const errorMessage = error.response?.data?.message || error.message;
      console.log("bowwwwwwwwwwwwwwww")
   
  }
};


    /**
   * To invite employer or talent in bulk
   * 
   * @param {Object} payLoad - Contains invitation details.
   * @returns {Promise} - Returns the server response or throws an error.
   */
 export const sendBulkInvites = (payLoad) => async (dispatch) => {
    // Dispatches an action to indicate the bulk invite request has started.
    dispatch(bulkInviteRequest());
 
     const config = {
        headers: {
          'Content-Type':  'multipart/form-data',
          // You can add more headers here if needed
        },
      }
    try {
        // Makes an API call to invite the employers or talent in bulk.
        const response = await apiService.post('invites/bulk-invites', payLoad,config);
        const returnData = response.data?.result.message;
        console.log("invite bulk success +++++++++++  ",returnData)
        // Dispatches an action to store the bulk invite success response in the redux store.
        dispatch(bulkInviteSuccess(returnData));
    } catch (error) {
        // Handles any errors that occur during the API request.
        const errorMessage = error.response?.data?.message || error.message;
        console.log("invite bulk errorMessage -------------  ",errorMessage)

        // Dispatches an action to show bulk invite request failed.
        dispatch(bulkInviteFailure(errorMessage));

    }
};
  /**
   * Handles the status update for a user.
   * 
   * @param {Object} payLoad - Contains the details required for user status update.
   * @returns {Promise} - If successful, updates the Redux store with update success, otherwise dispatches an error.
   */
  export const updateUserStatus = (payLoad) => async (dispatch) => {
    // Dispatches an action to signal that the update status request has started.
    // dispatch(());

    try {
        // Sends a POST request to user status update api.
        const response = await apiService.post('users/update-status', payLoad);
        const returnData = response.data?.result;

        // If successful, updates the Redux store with status updated response.
        dispatch(userStatusUpdateSuccess(returnData));
        showToast(returnData?.message, 'success');
    } catch (error) {
        // Handles errors that might occur during the API call.
        const errorMessage = error.response?.data?.message || error.message;
        
        // Dispatches an action signaling a failure in the update status process.
        dispatch(userStatusUpdateFailure(errorMessage));

        // Displays the error to the user via a toast notification.
        showToast(errorMessage, 'error');
    }
};

  /**
   * Handles resending the user activation link.
   * 
   * @param {Object} payLoad - Contains the details required to resend the activation link.
   * @returns {Promise} - If successful, updates the Redux store with resending activation link, otherwise dispatches an error.
   */
  export const resendActivationLink = (payLoad) => async (dispatch) => {
    // Dispatches an action to signal that the  request has started.
    // dispatch(());

    try {
        // Sends a POST request to resend activation link.
        const response = await apiService.post('users/resend-verify-link', payLoad);
        const returnData = response.data?.result;

        // If successful, updates the Redux store with resend activation link response.
        dispatch(resendActivationLinkSuccess(returnData));
        showToast(returnData?.message, 'success');
    } catch (error) {
        // Handles errors that might occur during the API call.
        const errorMessage = error.response?.data?.message || error.message;
        
        // Dispatches an action signaling a failure in the  resend activation link process.
        dispatch(resendActivationLinkFailure(errorMessage));

        // Displays the error to the user via a toast notification.
        showToast(errorMessage, 'error');
    }
};
    /**
   * To get the list of bulk invites
   * 
   * @param {Object} payLoad - Contains details of filters.
   * @returns {Promise} - Returns the server response or throws an error.
   */
    export const getBulkInvitesList = (payLoad) => async (dispatch) => {
      // Dispatches an action to indicate the bulk invite list request has started.
      dispatch(bulkInviteRequest());
  
      try {
          // Makes an API call to get bulk invites list.
          const response = await apiService.post('invites/list', payLoad);
          const returnData = response.data?.result;
          // Dispatches an action to store the  get bulk invite list response in the redux store.
          dispatch(setBulkInviteList(returnData));
      } catch (error) {
          // Handles any errors that occur during the API request.
          const errorMessage = error.response?.data?.message || error.message;
  
          // Dispatches an action to show failed to get bulk invite list.
          dispatch(setBulkInviteList([]));
  
      }
  };
  export const fetchSignedUrl = (payLoad) => async (dispatch) => {
    try {
      const response = await apiService.post('s3/get-signed-url', payLoad,{
        headers: {
          'Content-Type': 'multipart/form-data',
        }});
      const returnData = response.data?.result?.data;
      dispatch(getSignedUrl(returnData));
    } catch (error) {
        // Detailed error handling can be incorporated based on requirements.
        const errorMessage = error.response?.data?.message || error.message;
     
    }
  };
    /**
   * To get the dashboard stats details
   * 
   * @returns {Promise} - Returns the server response or throws an error.
   */
    export const getDashboardStats = (payLoad) => async (dispatch) => {
      // Dispatches an action to indicate the dashboard stats request has started.
      dispatch(bulkInviteRequest());
  
      try {
          // Makes an API call to get dashboard stats details.
          const response = await apiService.post('dashboard/stats-count');
          const returnData = response.data?.result?.[0];
          // Dispatches an action to store the  get dashboard stats details response in the redux store.
          dispatch(setDashboardStats(returnData));
      } catch (error) {
          // Handles any errors that occur during the API request.
          const errorMessage = error.response?.data?.message || error.message;
  
          // Dispatches an action to show failed to get dashboard stats details.
          dispatch(setDashboardStats([]));
  
      }
  };

     /**
   * To sign up an individual user
   * 
   * @returns {Promise} - Returns the server response or throws an error.
   */
     export const singupIndividualUser = (payLoad) => async (dispatch) => {
      // Dispatches an action to indicate the sing up request has started.
      dispatch(individualSignupRequest());
  
      try {
          // Makes an API call to sign up an individual user.
          const response = await apiService.post('auth/individual-sigup',payLoad);
          const returnData = response.data?.result;
          // Dispatches an action to store the sign up an individual user details response in the redux store.
          dispatch(signupIndividualSuccess(returnData));
      } catch (error) {
          // Handles any errors that occur during the API request.
          const errorMessage = error.response?.data?.message || error.message;
  
          // Dispatches an action to show failed to get dashboard stats details.
          dispatch(signupIndividualFailure(errorMessage));
  
      }
  };

/**
* to update the view count of topic
* 
* @returns {Promise} - Returns the server response or throws an error.
*/
export const increaseViewCount = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the view count of topic request has started.
  dispatch(individualSignupRequest());

  try {
    // Makes an API call to view count of topic.
    const response = await apiService.post('topic/view-count-update', payLoad);
    const returnData = response.data?.result?.message;
    // Dispatches an action to store the view count of topic details response in the redux store.
    dispatch(viewCountSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show failed to update view count of topic
    dispatch(viewCountFailure(errorMessage));
  }
};

export const createDiscussion = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest());
  try {
    const response = await apiService.post("topic/discussion-create", payLoad);
    const returnData = response.data?.result?.message;
    dispatch(createDiscussionSuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(createDiscussionFailure(errorMessage));
  }
};


export const getDiscussionsForTopic = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest());
  try {
    const response = await apiService.post("topic/discussion-details", payLoad);
    const returnData = response.data?.result;
    dispatch(discussionsDetailsSuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(discussionsDetailsFailure(errorMessage));
  }
};

//to add community as featured community
export const addFeaturedCommunity = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest());
  try {
    const response = await apiService.post("community/add-featured-community", payLoad);
    const returnData = response.data?.result?.message;
    dispatch(setFeCommunitySuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(setFeCommunityFailure(errorMessage));
  }
};

//to get list of featured community
export const getFeaturedCommunityList = (payLoad) => async (dispatch) => {
  dispatch(featCommunityListRequest());
  try {
    const response = await apiService.post("community/featured-community-list", payLoad);
    const returnData = response.data?.result;
    dispatch(setFeatureCommList(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(setFeatureCommList([]));
  }
};

//to follow a featured community
export const followCommunity = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest());
  try {
    const response = await apiService.post("community/follow", payLoad);
    const returnData = response.data?.result?.message;
    dispatch(followFeatCommunitySuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(followFeatCommunityFailure(errorMessage));
  }
};

//to join a featured community
export const joinCommunity = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest());
  try {
    const response = await apiService.post("community/join-community", payLoad);
    const returnData = response.data?.result?.message;
    dispatch(joinSuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(joinFailure(errorMessage));
  }
};

export const unfollowCommunity = (payload) => async (dispatch) => {
  dispatch(individualSignupRequest())
  try{
    const response = await apiService.post("community/unfollow",payload);
    const returnData = response.data?.result?.message;
    dispatch(setUnfollowSuccess(returnData));
  }catch(error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(setUnfollowFailure(errorMessage));
  }
}

export const unjoinCommunity = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest())
try{
  const response = await apiService.post("community/unjoin",payLoad);
  const returnData = response.data?.result?.message;
  dispatch(setUnjoinSuccess(returnData));
}
catch(error) {
  const errorMessage = error.response?.data?.message || error.message;
  dispatch(setUnjoinFailure(errorMessage));
}
}

// to get all the plans for end user
export const getEndUserPlans = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest())
  try{
    const response = await apiService.post("plans/list-for-end-users",payLoad);
    const returnData = response.data?.result;
    dispatch(setPlansList(returnData));
  }
  catch(error){
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(setPlansList([]))
  }
}

// to get all the plans for end user
export const getPaymentMethods = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest())
  try{
    const response = await apiService.post("billing/get-payment-methods",payLoad);
    const returnData = response.data?.result;
    dispatch(setPaymentMethodSuccess(returnData));
  }
  catch(error){
    const errorMessage = error.response?.data?.result?.errorMessage?.message || error.message;
    dispatch(setPaymentMethodFailure(errorMessage))
  }
}

// attach a payment method  
export const attachPaymentMethod = (payLoad) => async (dispatch) => {
  dispatch(attachPaymentRequest())
  try{
    const response = await apiService.post("billing/attach-payment-method",payLoad);
    const returnData = response.data?.result;
    dispatch(attachPaymentSuccess(returnData));
  }
  catch(error){
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(attachPaymentFailure(errorMessage))
  }
}

// to subscribe to a plan
export const subscribePlan = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest())
  try{
    const response = await apiService.post("plans/subscribe",payLoad);
    const returnData = response.data?.result;
    dispatch(subscribePlanSuccess(returnData));
  }
  catch(error){
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(subscribePlanFailure(errorMessage))
  }
}

// to charge per plan
export const chargeForPlan = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest())
  try{
    const response = await apiService.post("billing/charge-for-plan",payLoad);
    const returnData = response.data?.result;
    dispatch(chargeForPlanSuccess(returnData));
  }
  catch(error){
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(chargeForPlanFailure(errorMessage))
  }
}

//to save an event 

export const saveEvent = (payLoad) =>  async (dispatch) => {
  dispatch(individualSignupRequest())
  try{
    const response = await apiService.post("events/save-event",payLoad);
    const returnData = response?.data?.result?.message
    dispatch(eventSaveSuccess(returnData));
  }
  catch(error){
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(eventSaveFailure(errorMessage));
  }
}

// to get the invoices list

export const getInvoiceList = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest())
  try{
    const response = await apiService.post("invoices/list",payLoad);
    const returnData = response?.data?.result;
    dispatch(setInvoiceList(returnData));
  }
  catch(error){
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(setInvoiceList([]))
  }
}

// add plan 
export const addPlan = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest())
  try{
    const response = await apiService.post("plans/plan-for-employer",payLoad);
    const returnData = response.data?.result;
    dispatch(createPlanSuccess(returnData));
  }
  catch(error){
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(createPlanFailure(errorMessage))
  }
}
// update plan status 
export const updatePlanStatus = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest())
  try{
    const response = await apiService.post("plans/update-status",payLoad);
    const returnData = response.data?.result;
    dispatch(createPlanSuccess(returnData));
  }
  catch(error){
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(createPlanFailure(errorMessage))
  }
}

// add plan 
export const updatePlan = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest())
  try{
    const response = await apiService.post("plans/update-plan-for-employer",payLoad);
    const returnData = response.data?.result;
    dispatch(createPlanSuccess(returnData));
  }
  catch(error){
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(createPlanFailure(errorMessage))
  }
}

//to claim a community request
export const claimRequest = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest());
  try {
    const response = await apiService.post("community/claim-request", payLoad);
    const returnData = response.data?.result?.message;
    dispatch(claimRequestSuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(claimRequestFailure(errorMessage));
  }
};


//confirmClaimOtp
export const confirmClaimOtp = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest());
  try {
    const response = await apiService.post("community/claim-community", payLoad);
    const returnData = response.data?.result?.message;
    dispatch(confirmOtpSuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(confirmOtpFailure(errorMessage));
  }
};

export const addMasterData = (payLoad) => async (dispatch) => {
  try {
      const response = await apiService.post('masterdata/add', payLoad);
      const returnData = response.data?.result?.data;
      dispatch(masterAddSuccess(returnData));
  } catch (error) {
      // Handling errors for all categories via a general action.
      // In a more advanced setup, different actions could be dispatched for different error scenarios.
      const errorMessage = error.response?.data?.message || error.message;
      dispatch(masterAddFailure(errorMessage));
  }
};


//to get list of featured community
export const getRelatedCommunitiesList = (payLoad) => async (dispatch) => {
  dispatch(individualSignupRequest());
  try {
    const response = await apiService.post("/employer/similar-communities", payLoad);
    const returnData = response.data?.result;
    dispatch(setFeatureCommList(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(setFeatureCommList([]));
  }
};
