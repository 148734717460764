import React, { useEffect, useState} from 'react';
import {TextField, Autocomplete, Chip} from '@mui/material';

import { Controller } from 'react-hook-form';
import { checkProperty } from '../utils/helpers';
import Form from "react-bootstrap/Form";
import { addMasterData } from '../services/commonServices';
import { useDispatch, useSelector } from 'react-redux';
import { addedCategory, masterAddReset } from '../redux/slices/commonSlice';
import { getMdOptions, getOptionsList } from '../services/talentService';
import { cancelCommunityListRequest } from '../views/Utils';


function AutoSearch ({
    options = [],
    onSelectCity,
    freeSolo = false,
    type = '',
    isMulti = true,
    placeholder,
    control,
    defaultValue,
    label,
    errors,
    register,
    isRequired,
    errorText,
    fieldName,
    value,
    mdCategory='',
    subCategory='',
    onOptionAdded,
    isDisable=false,
    notMaster=''
}){
    const { addedOption } = useSelector(state => state.common);
    const {  isAuthenticated } = useSelector(state => state.auth)
    const [inputValue, setInputValue] = useState('');
    const [filOptions, setFilOptions] = useState([])
    const [cancelTokenSource, setCancelTokenSource] = useState(null)
    let timerId;
    const [searchKey, setSearchKey] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        if (addedOption) {
          if (onOptionAdded && typeof onOptionAdded == 'function') {
            onOptionAdded(mdCategory, addedOption, fieldName, subCategory)
          }
          dispatch(masterAddReset())
        }
      }, [addedOption]);

    useEffect(() => {
        if (searchKey.trim()) {
            let prevOpts = value?.length > 0 ? value?.map((opt) => opt.id) : []
            cancelCommunityListRequest(cancelTokenSource)
            const source = new AbortController();
            setCancelTokenSource(source)
            let myResponse = notMaster ? getOptionsList({payload: {
                filters: {
                  title: searchKey,
                  createdDateRange: [],
                  branch: 0
                },
                getMasterData: true,
                page: 1,
                perpage: 500,
                accesscheck: isAuthenticated ? "yes" : "no",
                sorting: {
                  createdOn: -1
                }
              }, endPoint: notMaster, cancelTokenSource: source}) :   
              getMdOptions({
                category: mdCategory,
                subCategory: subCategory,
                title: searchKey,
                cancelTokenSource: source
            });
            myResponse.then((response) => {
                // console.log("response ", response)
                if (!!subCategory) {
                    setFilOptions(response?.filter((optin) => (optin.category == subCategory && (!(prevOpts?.indexOf(optin.id) > -1)))))
                }
                else {
                    setFilOptions(response.filter(option =>
                        !(prevOpts?.indexOf(option.id) > -1)
                    ))
                }
            })
                .catch((error) => {
                    console.log("error", error);
                });
        } else {
            setFilOptions([])
        }
    }, [searchKey])

    useEffect(()=>{
        if(mdCategory == 'all_locations'){
            const source = new AbortController();
            setCancelTokenSource(source)
            getMdOptions({
                category: mdCategory,
                subCategory: subCategory,
                // title: searchKey,
                cancelTokenSource: source
            }).then((response)=>{
                setFilOptions(response)
            }).catch((error) => {
                console.log("error", error);
            });
        }
    },[])

    const onSearchKey = (e, newInputValue) => {
        setInputValue(newInputValue);
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            // console.log("SEARCH KEY ", newInputValue)
            setSearchKey(newInputValue)
        }, 100)
    }

    const formatOptionLabel = (option) => {
        if (type == 'cities') {
            return option.name + "(" + option.stateName + ")"
        }
        return option.name
    }
    return (
        <div className="simpleselect_container form-group">
            {label && <Form.Label>{isRequired ? label + "*" : label}
            </Form.Label>}
            <Controller
                name={fieldName}
                id={fieldName}
                control={control}
                defaultValue={defaultValue}
                rules={{ required: isRequired }}
                // disabled={isDisable}
                render={({ field }) => 
                   <Autocomplete
                        {...field}
                        freeSolo={freeSolo}
                        getOptionLabel={formatOptionLabel}
                        onChange={(e, newValue)=>{ 
                            if(freeSolo && newValue.length > 0 && (typeof newValue[newValue?.length - 1] == 'string')){
                                let newOption = inputValue.trim()
                                if(newOption?.length > 2 && mdCategory){
                                    let payload = {
                                        "category": mdCategory,
                                        "subCategory": subCategory,
                                        "name": inputValue
                                      }
                                      dispatch(addedCategory({
                                        "category": mdCategory,
                                        "subCategory": subCategory,
                                      }))
                                      dispatch(addMasterData(payload));
                                }
                            }else{
                                onSelectCity(newValue, fieldName)
                                field.onChange(newValue)
                            }
                           }}
                        inputValue={inputValue}
                        onInputChange={onSearchKey}
                        id={field.id}
                        name={field.name}
                        // value={value}
                        options={filOptions}
                        sx={{ width: 300 }}
                        multiple={isMulti}
                        noOptionsText={""}
                        filterSelectedOptions={true}
                        limitTags={1}
                        getLimitTagsText={(more) => `+${more}`}
                        renderInput={(params) => <TextField {...params}
                            variant='outlined'
                            fullWidth
                            InputLabelProps={{
                                shrink: false
                            }}
                            label={(inputValue || (value?.length > 0)) ? "" : placeholder} />}
                    />
                }
            />

            {errors &&
                fieldName &&
                checkProperty(fieldName.split("."), "length") > 2 &&
                checkProperty(
                    errors,
                    fieldName.split(".")[0],
                    fieldName.split(".")[1]
                ) &&
                checkProperty(
                    errors[fieldName.split(".")[0]][fieldName.split(".")[1]],
                    fieldName.split(".")[2],
                    "type"
                ) === "required" && (
                    <span className="form-error">{"* " + errorText + " is required"}</span>
                )}

            {errors &&
                fieldName &&
                checkProperty(fieldName.split("."), "length") > 1 &&
                checkProperty(
                    errors,
                    fieldName.split(".")[0],
                    fieldName.split(".")[1]
                ) &&
                checkProperty(
                    errors[fieldName.split(".")[0]],
                    fieldName.split(".")[1],
                    "type"
                ) === "required" && (
                    <span className="form-error">{"* " + errorText + " is required"}</span>
                )}
            {errors[fieldName] && (
                <span className="form-error">{"* " + errorText} is required</span>
            )}
        </div>
    );
}

export default AutoSearch;