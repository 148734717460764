import { createAction } from '@reduxjs/toolkit';
import { persistor } from './store';

export const logout = () => async (dispatch) => {
    // Dispatch the logout action to update the state.
    dispatch(logoutAction());
    
    // Then purge the persisted state.
    persistor.purge();
};

export const logoutAction = createAction('user/logout');

