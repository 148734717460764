import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { encryptTransform } from 'redux-persist-transform-encrypt';
import apiService from '../services/apiService';

// Define an encryption key will move to env
const encryptionKey = 'xRRfy/pG66pWB5KJOHKiKZhn1ArYGH5PDtrRvJP2your-secret-key';

const encryptor = encryptTransform({
  secretKey: encryptionKey
});

const persistConfig = {
  key: 'root', 
  blacklist: ['community','talent','employer','user'],
  storage,
  transforms: [encryptor]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer
});

apiService.setUpInterceptors(store);

export const persistor = persistStore(store); 

export default store;
