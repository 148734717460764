
import { lazy } from 'react';
const  Dashboard  = lazy(() => import('../views/Employer/dashboard'));
const  Employerdashboard  = lazy(() => import('../views/Dashboard/Employer'));
const  EmployerRegister = lazy(() => import('../views/Employer/Register'));
const  JobsList = lazy(() => import('../views/Employer/jobs'));
const  AddJob = lazy(() => import('../views/Employer/addJob'));
const  MatchedProfiles = lazy(() => import('../views/Employer/profilesList'));
const Payments = lazy(() => import('../views/Payments'))
const  CommunitiesList = lazy(() => import('../views/Employer/employeerCommunities'));
const ExternalImport = lazy(() => import('../views/Employer/externalJobsImport'));
const  AnalaysisForCompany  = lazy(() => import('../views/Dashboard/analysisEmpDshbrd'));

const employerRoutes = [
    // { path: '/employer/dashboard', component: Dashboard, exact: false },
    { path: '/dashboard/employer', component: Employerdashboard, exact: false },
    { path: '/invitation/employer', component:  EmployerRegister, exact: true },
    { path: '/employer/jobs', component:  JobsList, exact: true },
    { path: '/employer/add-job', component:  AddJob, exact: true },
    { path: '/employer/matched-profiles/:jobId', component:  MatchedProfiles, exact: true },
    {path: '/employer/matched-jobs', component: JobsList, exact: true},
    { path: '/employer/payments', component: Payments, exact: true },
    { path: '/employer/communities', component: CommunitiesList , exact: true},
    { path: '/employer/bulk-external-import', component: ExternalImport, exact: true },
    { path: '/employer/analysis', component: AnalaysisForCompany, exact: true }, 


];

export default employerRoutes;
