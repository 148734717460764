import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '@/assets/styles/header.scss';
import Form from 'react-bootstrap/Form';
import SimpleInput from '../../components/SimpleInput';
import SimpleSelect from '../../components/SimpleSelect';
import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap';
import FileUploader from '../../components/FileUploader';
import { commonStrings } from '@/utils/strings';
import AddressFields from "../../components/addresFields";
import { getTalentMasterData } from '../../services/talentService';
import { profileImgReset, uploadedFileReset } from '../../redux/slices/commonSlice';
import RangeSlider from '../../components/rangeSlider';
//import { userDetailsInfo } from '../../services/communityService';
//import { getCommunityDetails } from '../../services/communityService';
import SimpleRadioNew from '../../components/simpleRadioNew';
import SimpleRadio from '../../components/simpleRadio';
import AutoSearch from '../../components/autoSearch';
function EditProfile({ showProfilePopup, isUserUpdate, title, closeProfilePopup, props,backdropProp = '', keyBoardProp=true }) {
  //const { getCountryData, getStateList, getCityList } = useSelector(state => state.user);
  const { data } = useSelector(state => state.auth)
  const { ProfileImg } = useSelector(state => state.common)
  const { communityDetails, userDetails } = useSelector(state => state.community);
  const { employerDetailsResponse } = useSelector(state => state.employer);
  const { recreationalList, companySize, healthList, leaderShipList, communityTagList } = useSelector(state => state.talent)
  const { handleSubmit, formState: { errors }, register, control, reset, clearErrors, setValue,getValues } = useForm();
  const dispatch = useDispatch();
  const [updateDetails, setUpdateDetails] = useState({
    logo: "",
    name: "",
    line1: '',
    zipcode: "",
    country: "",
    state: "",
    city: "",
    communityType: "",
    // paymentRequired: "",
    questionaire: {
      companySize: "",
      recreationalActive: [],
      ranking: {
        competiSalaries: "",
        workBalance: "",
        healthCovrge: "",
        retireBenifits: "",
        childCare: "",
        stockOptions: ""
      },
      healthActivities: [],
      professionalDevlopment: [],
    },
    tags: []
  });
  const [updateUserDetails, setUpdateUserDetails] = useState({
    profilePicture: "",
    firstName: "",
    lastName: '',
    email: "",
    phone: "",
  });
  const [image, setImage] = useState("")
  useEffect(() => {
    dispatch(props.getMasterData({ category: "countries", countryId: 0 }));
    //dispatch(userDetailsInfo({"detailsId":data?.userId?data?.userId:""}))
    //dispatch(getCommunityDetails({"communityId":data&&data.communityList && data.communityList.length > 0 ? data.communityList[0]?.communityId : ""}))
    dispatch(getTalentMasterData({ category: "health_wellness_activities" }))
    dispatch(getTalentMasterData({ category: "leadership_development" }))
    dispatch(getTalentMasterData({ category: "recreational_activities" }))
    dispatch(getTalentMasterData({ category: "preferred_company_size" }))
    dispatch(getTalentMasterData({ category: "community_tags" }));
  }, []);
  useEffect(() => {
    if (!!communityDetails) {
      let updatedDetails = {
        // logo: communityDetails?.logo ? [{ preview: { url: communityDetails.logo } }] : [],
        logo: communityDetails?.logo,
        name: communityDetails?.name,
        line1: communityDetails?.address?.line1,
        zipcode: communityDetails?.address?.zipcode,
        country: communityDetails?.address?.countryDetails,
        state: communityDetails?.address?.stateDetails,
        city: communityDetails?.address?.locationDetails,
        communityType: (communityDetails?.communityType == 0) ? "Open" : (communityDetails?.communityType == 1) ? "Close" : "",
        // paymentRequired: communityDetails?.paymentRequired,
      }
      setUpdateDetails((prev) => ({
        ...prev,
        ...updatedDetails
      }))
      setValue("communityType", (communityDetails?.communityType == 0) ? "Open" : (communityDetails?.communityType == 1) ? "Close" : "")
      reset()
      // clearErrors()
    }
  }, [communityDetails]);
  useEffect(() => {
    if (!!userDetails) {
      let updatedDetails = {
        profilePicture: userDetails?.profilePicture,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        email: userDetails?.email,
        phone: userDetails?.phone,
      }
      setValue("firstName", userDetails?.firstName ? userDetails.firstName : '')
      setValue("lastName", userDetails?.lastName ? userDetails.lastName : '')
      setUpdateUserDetails(((prev) => ({ ...prev, ...updatedDetails })))
    }

  }, [userDetails]);
  useEffect(() => {
    if (!!employerDetailsResponse) {
      let updatedDetails = {
        logo: employerDetailsResponse?.logo,
        name: employerDetailsResponse?.name,
        line1: employerDetailsResponse?.address?.line1,
        zipcode: employerDetailsResponse?.address?.zipcode,
        country: employerDetailsResponse?.address?.countryDetails,
        state: employerDetailsResponse?.address?.stateDetails,
        city: employerDetailsResponse?.address?.locationDetails,
        questionaire: {
          ...employerDetailsResponse.questionnaire,
          ranking: employerDetailsResponse?.questionnaire?.ranking ? employerDetailsResponse?.questionnaire?.ranking : updateDetails?.questionaire?.ranking
        },
        tags: employerDetailsResponse?.tagsDetails ? employerDetailsResponse.tagsDetails : [],
      }
      setUpdateDetails((prev) => ({ ...prev, ...updatedDetails }))
      reset()
      setValue("name", employerDetailsResponse?.name ? employerDetailsResponse.name : "")
      setValue("line1", employerDetailsResponse?.address?.line1 ? employerDetailsResponse.address.line1 : "")
      setValue("zipcode", employerDetailsResponse?.address?.zipcode ? employerDetailsResponse.address.zipcode : "")
      setValue("logo", employerDetailsResponse?.logo ? employerDetailsResponse.logo : "")
      setValue("companySize", employerDetailsResponse?.questionnaire?.companySizeDetails ? employerDetailsResponse.questionnaire.companySizeDetails : "")
      setValue("recreationalActive", employerDetailsResponse?.questionnaire?.recreationalActiveDetails ? employerDetailsResponse.questionnaire.recreationalActiveDetails : "")
      setValue("healthActivities", employerDetailsResponse?.questionnaire?.healthActivitiesDetails ? employerDetailsResponse.questionnaire.healthActivitiesDetails : "")
      setValue("professionalDevlopment", employerDetailsResponse?.questionnaire?.professionalDevlopmentDetails ? employerDetailsResponse.questionnaire.professionalDevlopmentDetails : "")
      setValue("competiSalaries", employerDetailsResponse?.questionnaire?.ranking?.competiSalaries ? employerDetailsResponse.questionnaire.ranking.competiSalaries : "")
      setValue("workBalance", employerDetailsResponse?.questionnaire?.ranking?.workBalance ? employerDetailsResponse.questionnaire.ranking.workBalance : "")
      setValue("healthCovrge", employerDetailsResponse?.questionnaire?.ranking?.healthCovrge ? employerDetailsResponse.questionnaire.ranking.healthCovrge : "")
      setValue("retireBenifits", employerDetailsResponse?.questionnaire?.ranking?.retireBenifits ? employerDetailsResponse.questionnaire.ranking.retireBenifits : "")
      setValue("childCare", employerDetailsResponse?.questionnaire?.ranking?.childCare ? employerDetailsResponse.questionnaire.ranking.childCare : "")
      setValue("stockOptions", employerDetailsResponse?.questionnaire?.ranking?.stockOptions ? employerDetailsResponse.questionnaire.ranking.stockOptions : "")
      setValue("tags", employerDetailsResponse?.tagsDetails ? employerDetailsResponse.tagsDetails : "")

      //clearErrors()
    }
  }, [employerDetailsResponse]);
  useEffect(() => {
    if (ProfileImg) {
      setImage(ProfileImg)
      setTimeout(() => {
        dispatch(profileImgReset())
      }, 100)
    }
  }, [ProfileImg])
  const handleInputChange = (e) => {
    const { name, value } = e.target
    //     if (name.startsWith("address.")) {
    //       const [, nestedName] = name.split(".");
    //       setUpdateDetails((prevState) => ({
    //           ...prevState,
    //           address: {
    //               ...prevState.address,
    //               [nestedName]: value,
    //           },
    //       }));
    //   } else {
    setUpdateDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    //}
  }
  const onEditSubmit = (formData) => {
    const payLoad = isUserUpdate
      ? { ...formData, "userId": data?.userId, "profilePicture": image, }
      : {
        "communityId": data?.communityId ? data.communityId : "",
        "userId": data?.roleId === 1 && data?.userId,
        "logo": image,
        "name": formData.name,
        "address": {
          "line1": formData.line1,
          "zipcode": formData.zipcode,
          "stateId": formData.state?.id,
          "countryId": formData.country?.id,
          "locationId": formData.city?.id
        }
      };
    if (isUserUpdate) {
      dispatch(props.updateUserInfo(payLoad));
    } else {
      if (data?.roleId === 5) {
        let employerPayLoad = {
          "employerId": data?.employerId,
          "logo": image,
          "name": formData.name,
          "address": {
            "line1": formData.line1,
            "zipcode": formData.zipcode,
            "stateId": formData.state?.id,
            "countryId": formData.country?.id,
            "locationId": formData.city?.id
          },
          "questionnaire": {
            "companySize": updateDetails?.questionaire.companySize,
            "recreationalActive": updateDetails?.questionaire.recreationalActive,
            "ranking": {
              "competiSalaries": updateDetails?.questionaire.ranking.competiSalaries,
              "workBalance": updateDetails?.questionaire.ranking.workBalance,
              "healthCovrge": updateDetails?.questionaire.ranking.healthCovrge,
              "retireBenifits": updateDetails?.questionaire.ranking.retireBenifits,
              "childCare": updateDetails?.questionaire.ranking.childCare,
              "stockOptions": updateDetails?.questionaire.ranking.stockOptions
            },
            "healthActivities": updateDetails?.questionaire.healthActivities,
            "professionalDevlopment": updateDetails?.questionaire.professionalDevlopment
          },
          tags: formData.tags?.map((i) => i.id),
          tagDetails: formData.tags


        }
        dispatch(props.updateEmployerInfo(employerPayLoad));
      } else if (data?.roleId === 1) {
        dispatch(props.updateUserInfo(payLoad))
      }
      else {
        // if( updateDetails?.communityType == "Close" ){
        //   payLoad.paymentRequired = updateDetails?.paymentRequired == "true" ? true : false
        // }
        payLoad.communityType = updateDetails.communityType == "Open" ? 0 : updateDetails?.communityType == "Close" ? 1 : "";
        dispatch(props.updateCommunityInfo(payLoad))
      }
    }
    dispatch(uploadedFileReset())

  };
  const onErrors = (errors) => {
    console.log("errors are ", errors)
  }
  const handleSelectChange = (selectedOptions, event) => {
    const { name, option } = event
    setUpdateDetails((prev) => ({
      ...prev,
      ["questionaire"]: {
        ...prev.questionaire,
        [name]: selectedOptions?.length > 0 ? selectedOptions.map((opt) => opt.id) : selectedOptions.id
      }

    }))
  }

  const formatOptionLabel = (option) => {
    return option.name;
  }

  const handleSliderRange = (value, name) => {
    setUpdateDetails((prev) => ({
      ...prev,
      ["questionaire"]: {
        ...prev.questionaire,
        ["ranking"]: {
          ...prev.questionaire.ranking,
          [name]: value
        }
      }

    }))
  }
  const handleRadioChange = (e) => {
    // console.log(" radio -- name",e.target.name, " e.target.value -",typeof e.target.value )
    setUpdateDetails((previous) => ({
      ...previous,
      [e.target.name]: e.target.value
    }))
  }
  const handleSelectedFile = () => {
    console.log("logo selected ")
  }
  const handleFileRemove = () => {
    dispatch(profileImgReset())
  }
  const handleOptionAdded = (category, addedData) => {
    if (category == 'community_tags') {
      let formData = getValues()
      let addedValue = [addedData]
      let communityTags = formData?.tags ? formData.tags : []
      let resultTags = [...communityTags, ...addedValue]
      dispatch(getTalentMasterData({ category: "community_tags" }));
      setTimeout(() => {
        setUpdateDetails((prevState) => ({
          ...prevState,
          ['tags']: resultTags
        }));
        setValue("tags", resultTags)
      })

    }
  }
  const handleCitySelect = (selectedCity, fieldName) => {
    setUpdateDetails((prev) => ({
        ...prev,
        [fieldName]: [ ...selectedCity]
    }))
};
  return (
    <>
      <React.Fragment>
        {showProfilePopup &&
          <Modal
            centered
            show={showProfilePopup}
            onHide={closeProfilePopup}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            backdrop={backdropProp}
            keyboard={keyBoardProp}
          >
            {backdropProp ? <Modal.Header >
              <Modal.Title id="example-custom-modal-styling-title">
                {title}
              </Modal.Title>
            </Modal.Header> :<Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {title}
              </Modal.Title>
            </Modal.Header>}
            <Modal.Body>
              <div className='form-container'>
                <Form >
                  {isUserUpdate && (<React.Fragment>
                    <Row>
                      <Col>
                        <div className='profile_img'>
                          <FileUploader
                            type='profileImg'
                            fileUploader={props.fileUploader}
                            fieldName={'profilePicture'}
                            accepts={['image/*']}
                            isRequired={false}
                            control={control}
                            register={register}
                            errors={errors}
                            errorText="File"
                            defaultImg={userDetails?.profilePicture}
                            localupload={true}
                          //handleSelectedFile={handleSelectedFile}
                          />
                          <label className='form-label'>Profile</label>
                          {/* <img src={userDetails?.profilePicture?userDetails?.profilePicture:""} alt="" /> */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SimpleInput
                          label='First Name'
                          type='text'
                          inputValue={updateUserDetails.firstName}
                          onInputChange={handleInputChange}
                          errors={errors}
                          register={register}
                          isRequired={true}
                          errorText={"First Name"}
                          fieldName={'firstName'}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SimpleInput
                          label='Last Name'
                          type='text'
                          inputValue={updateUserDetails.lastName}
                          onInputChange={handleInputChange}
                          errors={errors}
                          register={register}
                          isRequired={true}
                          errorText={"Last Name"}
                          fieldName={'lastName'}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SimpleInput
                          label='Email'
                          readOnly={true}
                          id="email"
                          placeholder="Email Address"
                          inputValue={userDetails?.email ? userDetails?.email : ""}
                          errors={errors}
                          register={register}
                          isRequired={false}
                          errorText={"Email"}
                          fieldName={'email'}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SimpleInput
                          label='Phone Number'
                          type='number'
                          inputValue={updateUserDetails.phone}
                          //onInputChange={handleInputChange}
                          errors={errors}
                          className="phone"
                          register={register}
                          isRequired={false}
                          errorText={"Phone Number"}
                          fieldName={'phone'}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>)}
                  {!isUserUpdate && (<><Row>
                    <Col>
                      <div className='logo'>
                      <label className='form-label'>Logo</label>
                        <FileUploader
                          type='profileImg'
                          fileUploader={props.fileUploader}
                          fieldName={'profilePicture'}
                          accepts={['image/*']}
                          isRequired={false}
                          control={control}
                          register={register}
                          errors={errors}
                          errorText="File"
                          shape={'rect'}
                          defaultImg={updateDetails?.logo}
                          //handleSelectedFile={handleSelectedFile}
                          onFileRemoved={handleFileRemove}
                        />
                        
                        {/* </div>  <div className=''>
                        <FileUploader
                          cropRequired={false}
                          type='profileImg'
                          fileUploader={props.fileUploader}
                          fieldName={'profilePicture'}
                          accepts={['image/*']}
                          isRequired={false}
                          control={control}
                          register={register}
                          errors={errors}
                          errorText="Community Logo"
                          defaultValue={updateDetails?.logo}
                          localupload={false}
                          onFileRemoved={handleFileRemove}
                        />
                        <label className='form-label'>Logo</label> */}
                      </div>

                      <SimpleInput
                        label={data?.roleId === 1 ? "Platform Name" : data?.roleId === 5 ? "Company Name" : commonStrings.Community + " Name"}
                        type='text'
                        inputValue={updateDetails?.name}
                        onInputChange={handleInputChange}
                        errors={errors}
                        register={register}
                        isRequired={true}
                        errorText={data?.roleId === 1 ? "Platform Name" : commonStrings.Community + " Name"}
                        fieldName={'name'}
                      />
                    </Col>

                    {/* <h5>Address:</h5>
                    <Row>
                      <Col>
                        <SimpleInput
                          label='Street Address'
                          type='text'
                          inputValue={updateDetails.line1}
                          onInputChange={handleInputChange}
                          errors={errors}
                          register={register}
                          isRequired={true}
                          errorText={"Street Address"}
                          fieldName={'line1'}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SimpleSelect
                          label='Country'
                          options={getCountryData}
                          isMulti={false}
                          placeholder={'Select'}
                          defaultValue={updateDetails.country}
                          onInputChange={handleCountrySelect}
                          formatOptionLabel={formatOptionLabel}
                          errors={errors}
                          register={register}
                          isRequired={true}
                          errorText={"Country"}
                          fieldName={'country'}
                          control={control}
                        />
                      </Col>
                      <Col>
                        <SimpleSelect
                          label='State'
                          options={getStateList}
                          isMulti={false}
                          placeholder={'Select'}
                          defaultValue={updateDetails.state}
                          onInputChange={handleStateSelect}
                          formatOptionLabel={formatOptionLabel}
                          errors={errors}
                          register={register}
                          isRequired={true}
                          errorText={"State"}
                          fieldName={'state'}
                          control={control}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SimpleSelect
                          label='City'
                          options={getCityList}
                          isMulti={false}
                          placeholder={'Select'}
                          defaultValue={updateDetails.city}
                          onInputChange={handleCitySelect}
                          formatOptionLabel={formatOptionLabel}
                          errors={errors}
                          register={register}
                          isRequired={false}
                          errorText={"City"}
                          fieldName={'city'}
                          control={control}
                        />
                      </Col>
                      <Col>
                        <SimpleInput
                          label='Zip Code'
                          type="zipcode"
                          inputValue={updateDetails.zipcode}
                          onInputChange={handleInputChange}
                          maxLength={5}
                          errors={errors}
                          register={register}
                          isRequired={true}
                          errorText={"Zip Code"}
                          fieldName={'zipcode'}
                          control={control}
                        />
                      </Col>
                    </Row> */}

                    <AddressFields
                      errors={errors}
                      register={register}
                      control={control}
                      streetAddressErrorText={"Street Address"}
                      streetAddressFieldName={"line1"}
                      streetDefaultValue={updateDetails?.line1}
                      countryFieldName={"country"}
                      countryErrorText={"Country"}
                      countryDefaultValue={updateDetails?.country}
                      stateFieldName={"state"}
                      stateErrorText={"State"}
                      stateDefaultValue={updateDetails?.state}
                      cityFieldName={"city"}
                      cityErrorText={"City"}
                      cityDefaultValue={updateDetails?.city}
                      zipFieldName={"zipcode"}
                      zipErrorText={"Zipcode"}
                      zipcodeDefaultValue={updateDetails?.zipcode}
                    />
                  </Row>
                    {data.roleId === 5 && 
                    <>
                      <Row>
                      <Col>
                        <SimpleSelect
                          label={'Company Size'}
                          options={companySize}
                          isMulti={false}
                          placeholder={'Select'}
                          onInputChange={handleSelectChange}
                          formatOptionLabel={formatOptionLabel}
                          defaultValue={updateDetails?.questionaire?.companySizeDetails}
                          errors={errors}
                          register={register}
                          isRequired={false}
                          errorText={'Company Size'}
                          fieldName={'companySize'}
                          control={control}
                        />
                      </Col>
                      <Col>
                        <SimpleSelect
                          label={'Recreational Activity'}
                          options={recreationalList}
                          isMulti={true}
                          placeholder={'Select'}
                          onInputChange={handleSelectChange}
                          formatOptionLabel={formatOptionLabel}
                          defaultValue={updateDetails?.questionaire?.recreationalActiveDetails}
                          errors={errors}
                          register={register}
                          isRequired={false}
                          errorText={'Recreational Activity'}
                          fieldName={'recreationalActive'}
                          control={control}
                        />
                      </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SimpleSelect
                            label={'Health Activities'}
                            options={healthList}
                            isMulti={true}
                            placeholder={'Select'}
                            onInputChange={handleSelectChange}
                            formatOptionLabel={formatOptionLabel}
                            defaultValue={updateDetails?.questionaire?.healthActivitiesDetails}
                            errors={errors}
                            register={register}
                            isRequired={false}
                            errorText={'Health Activity'}
                            fieldName={'healthActivities'}
                            control={control}
                          />
                        </Col>
                        <Col>
                          <SimpleSelect
                            label={'Professional Devlopment'}
                            options={leaderShipList}
                            isMulti={true}
                            placeholder={'Select'}
                            onInputChange={handleSelectChange}
                            formatOptionLabel={formatOptionLabel}
                            defaultValue={updateDetails?.questionaire?.professionalDevlopmentDetails}
                            errors={errors}
                            register={register}
                            isRequired={false}
                            errorText={'Professional Devlopment'}
                            fieldName={'professionalDevlopment'}
                            control={control}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <h5 className='qs-title'><span className='qs-info'>{commonStrings.profile.Pleaserankimportanceonscaleof}</span></h5>

                        <Row>
                          <Col xs="12" md="6">
                            <RangeSlider
                              label={'Competitive Salaries'}
                              control={control}
                              sliderValue={updateDetails?.questionaire?.ranking?.competiSalaries}
                              fieldName={'competiSalaries'}
                              onSliderChange={handleSliderRange}
                            />

                          </Col>
                          <Col xs="12" md="6">
                            <RangeSlider
                              label={'Work-Life Balance'}
                              control={control}
                              sliderValue={updateDetails?.questionaire?.ranking?.workBalance}
                              fieldName={'workBalance'}
                              onSliderChange={handleSliderRange}
                            />

                          </Col>
                        </Row>

                        <Row>
                          <Col xs="12" md="6">
                            <RangeSlider
                              label={'Health Coverage'}
                              control={control}
                              sliderValue={updateDetails?.questionaire?.ranking?.healthCovrge}
                              fieldName={'healthCovrge'}
                              onSliderChange={handleSliderRange}
                            />

                          </Col>
                          <Col xs="12" md="6">
                            <RangeSlider
                              label={'Retirement Benefits'}
                              control={control}
                              sliderValue={updateDetails?.questionaire?.ranking?.retireBenifits}
                              fieldName={'retireBenifits'}
                              onSliderChange={handleSliderRange}
                            />

                          </Col>
                        </Row>

                        <Row>
                          <Col xs="12" md="6">
                            <RangeSlider
                              label={'Child Care'}
                              control={control}
                              sliderValue={updateDetails?.questionaire?.ranking?.childCare}
                              fieldName={'childCare'}
                              onSliderChange={handleSliderRange}
                            />

                          </Col>
                          <Col xs="12" md="6">
                            <RangeSlider
                              label={'Stock Options'}
                              control={control}
                              sliderValue={updateDetails?.questionaire?.ranking?.stockOptions}
                              fieldName={'stockOptions'}
                              onSliderChange={handleSliderRange}
                            />

                          </Col>
                        </Row>
                        <Row>
                      <Col>
                        {/* <SimpleSelect
                          label={'Select your tags'}
                          options={communityTagList}
                          isMulti={true}
                          placeholder={'Add Tags'}
                          onInputChange={handleSelectChange}
                          formatOptionLabel={formatOptionLabel}
                          errors={errors}
                          register={register}
                          isRequired={false}
                          errorText={"Tags"}
                          fieldName={'tags'}
                          className={'tags'}
                          control={control}
                          onOptionAdded={handleOptionAdded}
                          masterCate={'community_tags'}
                        /> */}
                         <AutoSearch
                            options={communityTagList}
                            onSelectCity={handleCitySelect}
                            label='Search your tags'
                            isMulti={true}
                            placeholder={'Add Tags'}
                            value={updateDetails?.tags}
                            errors={errors}
                            register={register}
                            isRequired={false}
                            errorText={"Tags"}
                            fieldName={'tags'}
                            control={control}
                            type={""}
                            mdCategory='community_tags'
                            onOptionAdded={handleOptionAdded}
                            freeSolo={true}
                            defaultValue={updateDetails?.tags}
                        />
                      </Col>
                    </Row>
                      </Row>
                    </>}
                    {data.roleId === 3 && <>
                      <Row>
                        <Col>
                          <div className='form-group'>
                            <SimpleRadioNew
                              yesValue={"Open"}
                              noValue={"Close"}
                              label={"Community Type"}
                              errors={errors}
                              register={register}
                              onRadioChange={handleRadioChange}
                              defaultCheck={updateDetails?.communityType}
                              isRequired={true}
                              errorText={"Community Type"}
                              fieldName={'communityType'}
                              control={control}
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* {updateDetails?.communityType == "Close" &&  <Row>
                    <Col xs="12" md="6">
                          <SimpleRadio
                            label={"Payment Required"}
                            errors={errors}
                            register={register}
                            onRadioChange={handleRadioChange}
                            defaultCheck={updateDetails?.paymentRequired ? updateDetails.paymentRequired : false}
                            isRequired={true}
                            errorText={"Payment Required"}
                            fieldName={'paymentRequired'}
                            control={control}
                          />
                        </Col>
                    </Row>} */}
                    </>}
                    



                  </>
                  )}
                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type='submit' onClick={handleSubmit(onEditSubmit, onErrors)}
              >
                Update
              </Button>
              <Button variant="secondary" onClick={closeProfilePopup}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>}
      </React.Fragment>
    </>
  )

}
export default EditProfile;