import React, { useState, useEffect, useCallback } from "react";
import Files from "react-files";
import "@/assets/styles/fileUpload.scss";
import Cloud from "@/assets/images/icons/cloud.svg";
import { useDispatch, useSelector } from "react-redux";
//import {uploadFile} from "../services/authService"
import DefaultProfile from "@/assets/images/icons/profile-icon.svg";
import { resumeReset } from "../redux/slices/talentSlice";
import closeIcon from "@/assets/images/icons/close-btn-img.svg";
import { uploadedFileReset } from '../redux/slices/commonSlice';



function FileUploaderMulti({
    fileUploader,
    accepts,
    register,
    defaultImg,
    fieldName,
    isRequired,
    id,
    control,
    errorText,
    errors,
    handleSelectedFile,
    type,
    localupload,
    onFilesUploaded,
    defaultValue,
    onFileRemoved,
    onInputChange,
    key,
    cropRequired = true,
    shape,
    multiple = false,
    isEvent = false
}) {
    const { ProfileImg,uploadedfiles } = useSelector(state => state.common)
    const [files, setFiles] = useState([]);
    const [rawFiles, setRawFiles] = useState([]);
    const [profileImg, setProfileImg] = useState("");
    const [fileLoader, setFileLoader] = useState(false);
    const [defaultChange, setDefaultChange] = useState(false);

    const dispatch = useDispatch();
    const handleChange = (file) => {
        console.log('file',file)
        if (type === "profileImg") {
            setFileLoader(true)
            if (multiple) {
                setRawFiles(prevFiles => [...prevFiles, ...file]);
            } else {
                setDefaultChange(false)
                setFiles(file);
            }
            setProfileImg(file)
        } else {
            setFileLoader(true)
            if (multiple) {
                setRawFiles(prevFiles => [...prevFiles, ...file]);
            } else {
                setDefaultChange(false)
                setFiles(file);
            }
        }
        dispatch(resumeReset())
        const formData = new FormData();
        file.forEach((fileItem) => {
            formData.append("files", fileItem);
        });
        formData.append("secureType", type === "profileImg" ? "public" : "private");
        formData.append("getDetails", true); // Send "true" when required ext, mimetype and name as well
        if (localupload === true) {
            setFileLoader(false)
        } else {
            dispatch(fileUploader({ formData: formData, type: type,isMultiple:multiple }));
           // setFileLoader(false)
        }
    };

    const handleFileRemove = (fileId, ind) => {
        if(type !== "profileImg"){
            setDefaultChange(false)
            setFiles((prevFiles) =>  prevFiles.filter((prevFile) => prevFile?.id !== fileId));
        }
        else{
            if(multiple){
               let updatedFiles = files.filter((prevFile, index) => ind !== index)
            setDefaultChange(false)
            setFiles(updatedFiles)
            }else{
                setDefaultChange(false)
                setFiles([])
            }
        }


        if (onFileRemoved) {
            onFileRemoved(files);
        }
        if (files.length === 0) {
            // setValidationRequired(true)
        }
    };
    // console.log("uploader 0---------------- ",JSON.stringify(files))


    useEffect(() => {
        setFileLoader(false)
        if (ProfileImg) {
            let fielObj = {
                preview: {
                    url: ProfileImg
                }
            }
            setDefaultChange(false)
            setFiles([fielObj]);
        }
    }, [ProfileImg]);
    useEffect(() => {
        setFileLoader(false)
        if (uploadedfiles) {
            if (multiple) {
                setDefaultChange(false)
                setFiles(prevFiles => [...prevFiles, ...uploadedfiles]);
                dispatch(uploadedFileReset())
            } else {
                setDefaultChange(false)
                setFiles(uploadedfiles);
            }
        }
    }, [uploadedfiles]);
    



    useEffect(() => {
        if (
            files.length > 0 &&
            onFilesUploaded &&
            typeof onFilesUploaded === "function" && (!defaultChange)
        ) {
            onFilesUploaded(files);
        }
    }, [files]);

    useEffect(() => {
        if (defaultValue && defaultValue.length > 0) {
            setDefaultChange(true)
            setFiles(defaultValue);
        }
    }, [defaultValue]);
    useEffect(() => {
        if (key) {
            setDefaultChange(false)
            setFiles([])
        }
    }, [key]);


   
    return (
        <div className="fileuploader">
            <Files
                className="files-dropzone-list"
                dragActiveClassName="files-dropzone-active"
                style={{ height: "100px" }}
                onChange={handleChange}
                maxFiles={multiple ? 15 : 1}
                accepts={accepts}
                control={control}
                maxFileSize={10000000}
                minFileSize={0}
                multiple={multiple ? multiple : false}
            //  {...register(fieldName, { required: validationRequired, })}
            >
                <img src={Cloud} alt="Files Upload" />
                Drag and Drop or<span>Browse</span>
            </Files>
            {fileLoader && <div className="cnt_loader">
                <span class="b-loader"></span>
            </div>}


            {defaultImg && files.length === 0 && <img className="preview-img" src={defaultImg ? defaultImg : DefaultProfile} alt="" />}
            {files.length > 0 && (
                <div className="files-list multi">
                    <ul style={{padding:"16px 0 0 0"}}>
                        {files?.map((file, ind) => (
                            <React.Fragment>
                                {type !== "profileImg" && (
                                    <li key={ind} className="files-list-item">
                                        <span className="filename">{file.name}</span>
                                        <span
                                            className="file-delete"
                                            onClick={() => handleFileRemove(file.id, ind)}
                                        ></span>
                                    </li>
                                )}
                                {type === "profileImg" && <>
                                    <li className="upload__img files-list-item  grid_type" key={ind}>
                                        <button type="button" className="remove_btn" onClick={() => handleFileRemove(file,ind)}>
                                            <img src={closeIcon} alt="close" />
                                        </button>
                                        <figure>
                                            {multiple && <img className="preview-img" src={file?.path ? file?.path : DefaultProfile} alt="" />}
                                            {!multiple && <img className="preview-img" src={file?.preview?.url ? file?.preview?.url : DefaultProfile} alt="" />}
                                        </figure>
                                    </li>
                                </>}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            )}

        </div>
    );
}
export default FileUploaderMulti;
