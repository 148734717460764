import React from 'react';
import '@/assets/styles/nodata.scss';

function NoData({loading, noDataMsg=''}) {
    return (
        <>
        { loading ?  <div class="nodataTemplate">
            <div class="accordian-table">
                <table class="tg">
                    <tr>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                    </tr>
                    <tr>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                        <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                    </tr>
                </table>
            </div>
        </div> : <div className={noDataMsg ? 'nodatafoundtext custom-msg' : 'nodatafoundtext'}>{noDataMsg ? noDataMsg :'No data found.'}</div>}
        </>
    )
}

export default NoData;