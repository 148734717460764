import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import '@/assets/styles/header.scss';
import Logo from "@/assets/images/logo.png";
import MatchplicityLogo from "@/assets/images/matchplicity_logo.png";


//import Notifications from "@/assets/images/icons/notifications.svg";
import ProfileLogo from "@/assets/images/icons/profile-icon.svg";
import ProfileIcon from "@/assets/images/main/profile-user.png";
import Form from 'react-bootstrap/Form';

import { Nav } from 'react-bootstrap';
import { logout, setChatMessages } from '../redux/slices/userSlice';
import Offcanvas from 'react-bootstrap/Offcanvas';

import suitcaseIcon from "@/assets/images/main/suitcase.svg";
import profilePicIcon from "@/assets/images/main/profile_pic.png";
import profilePicIcon2 from "@/assets/images/main/profile_pic_2.png";
import messageIcon from "@/assets/images/main/message.svg";
import scheduleIcon from "@/assets/images/main/schedule.svg";
import locationIconWhite from "@/assets/images/main/location_white.svg";
import ExpIcon from "@/assets/images/main/clock.svg";
import JobtypeIcon from "@/assets/images/main/house-laptop.svg";
import salaryRangeIcon from "@/assets/images/main/coins.svg";
import mailIcon from "@/assets/images/main/mail.svg";
import smartphoneIcon from "@/assets/images/main/smartphone.svg";
import attachIcon from "@/assets/images/main/attachments.svg";
import sentIcon from "@/assets/images/main/sent.svg";

import appleLogo from "@/assets/images/apple_logo.png";
import amazonLogo from "@/assets/images/amazon_logo.png";
import facebookLogo from "@/assets/images/facebook_logo.png";
import filtersIcon from "@/assets/images/icons/filters.png";

import SimpleInput from '../components/SimpleInput';
import SimpleSelect from '../components/SimpleSelect';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap';
import ProfileModal from '../views/Profile/profile';
import { showToast } from '../views/Utils';
import apiService from '../services/apiService';
import ProfileDetails from '../views/Profile/profileDetails';
import { getUserRole, checkProfileCompleted, getUserId, getLoginDetailsId, getEmpCanAccess, canAccess } from '../utils/helpers';
import { commonStrings } from '@/utils/strings';
// import { userDetailsInfo } from '../services/communityService';
import { updateCommunity, updateCommunityList } from '../redux/slices/commonSlice';
import { resetSubscriberList } from '../redux/slices/employerSlice';
import { talentDetails } from '../services/talentService';
import { userDetailsInfo } from '../services/communityService';
// import { changePassword } from '../services/authService';
function Header(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data, isAuthenticated } = useSelector(state => state.auth)
    const [dashboard, setDashboard] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const ProfileModalClose = () => setShowProfileModal(false);
    const userInfo = JSON.parse(localStorage.getItem("userinfo"))
    const handleShow = () => setShow(true);
    const { handleSubmit, formState: { errors }, register, clearErrors, control, reset } = useForm();
    // const [isAuthenticated, setAuthenticated] = useState(localStorage.getItem('isLoggedIn') || false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [changePasswordValue, setChangePasswordValue] = useState({
        currentPassword: "",
        newPassword: ""
    });
    const [showProfileModal, setShowProfileModal] = useState(false);
    const { updateUserSuccess, userDetails } = useSelector(state => state.community)
    const { talentQuestinnareSuccess, talentDetailsData } = useSelector(state => state.talent)
    const [profData, setProfData] = useState(null)
    const onLogoutSubmit = () => {
        // Clear stored user details and tokens.
        localStorage.clear()
        dispatch(resetSubscriberList())
        dispatch(updateCommunityList([]))
        dispatch(updateCommunity(""))
        dispatch(setChatMessages([]))
        dispatch(logout());
        navigate('/login');
    };
    useEffect(() => {
        setDashboard(() => {
            if ([1, 2].indexOf(data?.roleId) > -1) {
                return 'platform'
            }
            else if ([3, 4].indexOf(data?.roleId) > -1) {
                return 'community'
            }
            else if ([5, 6].indexOf(data?.roleId) > -1) {
                return 'employer'
            }
            else {
                return 'talent'
            }
        })
    }, [data])
    const nagigateToProfilePage = () => {
        if (([1,2, 3, 4, 5, 6].indexOf(data?.roleId) > -1)) {
            setShowProfileModal(true)
        } else if ([7].indexOf(data?.roleId) > -1) {
            setShow(true)
        } else {

        }
    }
    const nagigateToPaymentsPage = () => {
        navigate('/employer/payments');
    }
    const [isActive, setIsActive] = useState(false);

    const handleClick = event => {

        setIsActive(current => !current);
    };
    const clearInputValues = () => {
        setShowChangePassword(false)
        setChangePasswordValue((prevState) => ({
            ...prevState,
            currentPassword: "",
            newPassword: ""
        }));
        clearErrors()
        reset()
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChangePasswordValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value) {
            clearErrors(name);
        }
    };
    // const editProfile=()=>{
    //     handleClose()
    //     navigate('/profile')
    // }
    const onPasswordChange = async (formData) => {
        let payLoad = {
            ...formData,
            "userId": data?.userId
        }
        // dispatch(changePassword(payLoad));
        // try {
        //     const response = await apiService.post('auth/change-password', payLoad);
        //     const returnData = response.data?.result.message|| response.message;
        //     showToast(returnData,"success")
        //     setShowChangePassword(false)
        //     clearInputValues()

        // } catch (error) {
        //     const errorMessage = error.response?.data?.message || error.message;
        //     console.log("errorMessage",errorMessage)
        //     showToast(errorMessage,"error")
        // }
        let apiUrl = '';

        if (process.env.NODE_ENV === 'production') {
            apiUrl = 'https://enterprise.saas.matchplicity.com/api';
        } else {
            apiUrl = 'https://enterprise.saas.matchplicity.com/api';
        }
        try {
            const token = localStorage.getItem('token'); // Replace with your actual token

            const response = await fetch(apiUrl + "/auth/change-password", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payLoad),
            });

            if (response.ok) {
                const resultData = await response.json();
                showToast(resultData?.result?.message || resultData.message, "success")
                // const returnData = response.data?.result.message || response.message;
                // showToast(returnData, "success")
                setShowChangePassword(false)
                clearInputValues()
            } else {
                const resultData = await response.json();
                showToast(resultData?.result?.message || resultData.message, "error")
                //console.log("errorMessage", resultData?.result?.message || resultData.message)
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message;
            console.log("errorMessage", errorMessage)
            showToast(errorMessage, "error")
        }


    }
    useEffect(() => {
        if(data?.detailsId){

            let pload = {
                detailsId: data?.detailsId
            }
            if(data?.roleId == 7){
                pload.includeQues = true
                dispatch(talentDetails(pload))
            }else{
                dispatch(userDetailsInfo(pload))
            }
            // dispatch(userDetailsInfo({detailsId : data.detailsId}))
        }
    },[data,updateUserSuccess, talentQuestinnareSuccess])
    return (
        <>
            {/* {([7].indexOf(getUserRole()) > -1 && !checkProfileCompleted()) ?
                <></>
                : */}
                <React.Fragment>
                    <div className='header'>
                        <div className='logo'>
                        {([7].indexOf(getUserRole()) > -1 && !checkProfileCompleted()) ? <></> :
                            <Link to={isAuthenticated ? (((data?.roleId == 5 && data?.superAdmin) || (data?.roleId == 7)) ?  `/` : `/dashboard/${dashboard}/`) : '/' }><img src={MatchplicityLogo} alt='Matchplicity' /></Link>
            }
                            </div>
                        {([7].indexOf(getUserRole()) > -1 && !checkProfileCompleted()) ? <></>:
                        
                         <Nav className='nav-menu' >
                            <span onClick={handleClick} className={isActive ? 'mobile-menu menu_open' : 'mobile-menu'}></span>
                            <ul className={isActive ? 'menu-open' : ''} onClick={handleClick}>
                            {['/','/for-community', '/for-talent', '/for-employer','/communities'].includes(location.pathname) ? (
            <>
            {!isAuthenticated  && <>
                <li className={location.pathname === '/for-community' ? 'active' : ''}>
                <Link to={`/for-community`}>For Community</Link>
                </li>
                <li className={location.pathname === '/for-talent' ? 'active' : ''}>
                    <Link to={`/for-talent`}>For Talent</Link>
                </li>
                <li className={location.pathname === '/for-employer' ? 'active' : ''}>
                    <Link to={`/for-employer`}>For Employers</Link>
                </li>
                 <li className='pad0'><Link className='login_btn' to={`/login`}>Login</Link></li>
                </>}
            
            </>
        ) : (
            <>
                                {([1,2,5,6,7].indexOf(data?.roleId) > -1) && <li className={['/dashboard/community', '/dashboard/platform', '/dashboard/employer', '/dashboard/talent'].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                    <Link to={`/dashboard/${dashboard}`}>Home</Link>
                                </li>}
                                {[5,6].indexOf(data?.roleId) > -1 && <li className={['/employer/add-job', '/employer/jobs', '/bulk-upload'].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                    <Link to="/employer/jobs" className={'sublist'}>Jobs</Link>
                                    <ol className='submenu'>
                                        <li className={["/employer/add-job"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                            <Link to="/employer/add-job">Post a Job</Link>
                                        </li>
                                        <li className={["/employer/jobs"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                            <Link to="/employer/jobs">Job List</Link>
                                        </li>
                                        <li className={["/bulk-upload"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                            <Link to="/bulk-upload">Bulk Upload</Link>
                                        </li>
										<li className={["/employer/jobs"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                            <Link to="/employer/matched-jobs">Matched Jobs</Link>
                                        </li>

                                    </ol>
                                </li>}
                                {([7].indexOf(data?.roleId) > -1) && <li className={['/matched/jobs'].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                <Link to="/matched/jobs">Matches</Link>
                                </li>}
                                {/* {([1, 2].indexOf(data?.roleId) > -1) && <li className={["/communitylist-dashboard"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                    <Link to="/communitylist-dashboard">{commonStrings.Communities}</Link>
                                </li>} */}

                                {([1, 2].indexOf(data?.roleId) > -1) && <li className={["/communitylist-dashboard", "/community/featured"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                    <Link to="/communitylist-dashboard" className={'sublist'}>{commonStrings.Communities}</Link>
                                    <ol className='submenu'>
                                    {([1, 2].indexOf(data?.roleId) > -1) && <li className={["/communitylist-dashboard"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                            <Link to="/communitylist-dashboard">{commonStrings.Communities}</Link>
                                        </li>}
                                        <li className={["/community/featured"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                            <Link to="/community/featured">Featured {commonStrings.Communities}</Link>
                                        </li>
                                    </ol>
                                </li>}

                                {/* {(([5,6,7].indexOf(data?.roleId) > -1) && (data?.parentId == null)) &&  <li className={["/community/featured"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                            <Link to="/community/featured">Featured {commonStrings.Communities}</Link>
                                </li>} */}

                                {/* {(([3, 4].indexOf(data?.roleId) > -1) && (data?.parentId == null)) && <li className={["/branches"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                    <Link to="/branches">Branches</Link>
                                </li>} */}
                                {([5,6,7].indexOf(data?.roleId) > -1) && <li className={["/forums"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                    <Link to="/forums">Forums</Link>
                                </li>}
                                {([5,6,7].indexOf(data?.roleId) > -1) && <li className={["/events"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                    <Link to="/events">Events</Link>
                                </li>}
                                {/* {([3, 4].indexOf(data?.roleId) > -1) && <li className={["/employerlist-dashboard"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                    <Link to="/employerlist-dashboard">Employers</Link>
                                </li>}
                                {([3, 4].indexOf(data?.roleId) > -1) && <li className={["/talentlist-dashboard"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                    <Link to="/talentlist-dashboard">{commonStrings.Students}</Link>
                                </li>} */}
                                {([1,5].indexOf(data?.roleId) > -1 ) && <li className={["/userslist-dashboard"].findIndex((element) => element === location.pathname) > -1 ? 'active' : ''}>
                                    <Link to="/userslist-dashboard">My Team</Link>
                                </li>}
                                </>
        )}
                            </ul>
                        </Nav>}

        {/* {['/','/for-community', '/for-talent', '/for-employer','/communities'].includes(location.pathname) ? (
            <>  </>
          
            ) : ( */}
               {isAuthenticated && <>
                   <div className='header-actions'>
                            <ul>
                                {/* <li>
                            <img src={Notifications} alt='Notifications' />
                        </li> */}
                         { ((location.pathname ==='/student/edit-profile') &&  !checkProfileCompleted()) ? <li ><Button onClick={onLogoutSubmit} className="logout">Logout</Button></li>
                            : 
                            ['/','/for-community', '/for-talent', '/for-employer','/communities'].includes(location.pathname) ? <li>
                            <Link to={`/dashboard/${dashboard}`}>
                                <Button  className="logout">Dashboard</Button>
                            </Link> 
                            </li> :
                                <li>
                                    <div className='hover-menu'>
                                        <figure className='profile hover-icon'>
                                        {data?.roleId == 7 ? <img src={ talentDetailsData?.profilePicture ? talentDetailsData.profilePicture : ProfileLogo} alt='Profile' />
                                        : <img src={ userDetails?.profilePicture ? userDetails.profilePicture : ProfileLogo} alt='Profile' />}

                                            {/* <img src={ userDetails?.profilePicture ? userDetails.profilePicture : data?.profilePicture ? data.profilePicture : ProfileLogo} alt='Profile' /> */}
                                        </figure>
                                        <ol className='menu-list'>
                                            {/* <li onClick={() => setShowProfileModal(true)}>Community Profile</li>
                                        <li onClick={() => setShow(true)}>Profile</li> */}
                                            {/* {(canAccess() && ['/','/for-community', '/for-talent', '/for-employer','/communities'].includes(location.pathname)) && <Link to={`/dashboard/${dashboard}`}>
                                                <li>dashboard</li>
                                                </Link>} */}
                                            <li onClick={() => nagigateToProfilePage()}>Profile</li>
                                            {( data?.superAdmin && getEmpCanAccess()) &&<li onClick={() => nagigateToPaymentsPage()}>Payments</li>}
                                            <li onClick={() => setShowChangePassword(true)}>Change Password</li>
                                            <li onClick={onLogoutSubmit}>Logout</li>
                                        </ol>
                                    </div>
                                </li>}
                            </ul>
                        </div>

                        </>}
        {/* )} */}

                        {show && <ProfileModal show={show} onHide={handleClose} detailsId={getLoginDetailsId()}></ProfileModal>}
                    </div>
                    {showChangePassword && <Modal
                        centered
                        show={showChangePassword}
                        // onHide={() => setShowChangePassword(false)}
                        onHide={clearInputValues}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Change Password
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='form-container'>
                                <Form>
                                    <Row>
                                        <Col>
                                            <SimpleInput

                                                label={'Old Password'}

                                                type='Password'

                                                errors={errors}

                                                register={register}

                                                isRequired={true}
                                                inputValue={changePasswordValue.currentPassword}
                                                onInputChange={handleInputChange}
                                                errorText={"Old Password"}
                                                fieldName={'currentPassword'}

                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SimpleInput

                                                label={'New Password'}

                                                type='Password'

                                                errors={errors}
                                                inputValue={changePasswordValue.newPassword}
                                                onInputChange={handleInputChange}
                                                register={register}

                                                isRequired={true}

                                                errorText={"New Password"}

                                                fieldName={'newPassword'}

                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" type='submit' onClick={handleSubmit(onPasswordChange)}>
                                Update
                            </Button>
                            <Button variant="secondary" onClick={clearInputValues}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>}

                    {showProfileModal && <ProfileDetails showProfileModal={showProfileModal} onHide={ProfileModalClose} props={props} ></ProfileDetails>}

                </React.Fragment>
                {/* } */}
        </>
    )

}
export default Header;