
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    registerSuccess: '',
    registerFailure: '' ,
    talentDetailsData: "",
    loading: false,
    talentSuccess: '',
    talentFailure: '',
    workTypesList:[],
    benefitsList:[],
    ethniCityList:[],
    fieldStudyList:[],
    genderList:[],
    salutationList: [],
    healthList:[],
    idealWorkList:[],
    leaderShipList:[],
    jobSearchList:[],
    recreationalList:[],
    workEnvironmentList:[],
    talentList: [],
    talentQuestinnareSuccess: '',
    talentQuestinnareFailure: '',
    talentResume:[],
    loginUserData:JSON.parse(localStorage.getItem('userinfo')),
    ProfileImg:[],
    jobTitlesData: [],
    preferredCertificatesData: [],
    salaryRangeData: [],
    skillsData: [],
    desiredFunctionsList: [],
    desiredPolicyList: [],
    desiredDepartmentsList: [],
    sexualOrientationList: [],
    politicalPartyList: [],
    geographicAffiliationList: [],
    managedPeopleList: [],
    managedBudgetList:[],
    jobLocationsData: [],
    tradsList:[],
    certificationsList:[],
    primaryCertifications:[],
    secondaryCertifications:[],
    wagesList:[],
    jobTypeGoalsList:[],
    certificatesList: [],
    isLoading:false,
    profileJobStatus: [],
    jobsList: [],
    companySize: [],
    jobAppliedSuccess: '',
    jobApplyFailed: '',
    topicStatus: [],
    discussionStatus: [],
    eventStatus: [],
    invitedUserData: "",
    industries: [],
    careers: [],
    inviteLoading: false,
    major: [],
    universityMajor: [],
    communityTagList: [],
    userInvitePopUpData: "",
    agreedPrivacySuccess: "",
    agreedPrivacyFailed: "",
    talentListLoading: false,
    talentJobsLoading: false,
    talentQuestUpdateLoading: false,
    studentTypes: [],

};
const   talentSlice = createSlice({
    name: 'talent',
    initialState,
    reducers: {
         talentRegisterRequest: (state,action) => {
           // state.loading = true;
            state.isLoading=true
        },
          talentRegisterSuccess: (state,action) => {
            state.registerSuccess = action.payload;
            state.registerFailure = '';
            state.loading = false;            
        },
          talentRegisterFailure: (state,action) => {
            state.registerSuccess = '';
            state.registerFailure = action.payload;
            state.loading = false;
        },
          talentDetailsSuccess: (state,action) => {
           // state.talentDetails = action.payload;
        },
          talentDetailsFailure: (state,action) => {
           // state.talentDetails = {};
        },
      inviteTalentFailure: (state, action) => {
        state.talentFailure = action.payload;
        state.loading = false
        state.inviteLoading = false
      },
      inviteTalentSuccess: (state, action) => {
        state.talentSuccess = action.payload;
        state.loading = false
        state.inviteLoading = false
      },
      workTypesData: (state, action) => {
        state.workTypesList = action.payload;
      },
      benefitsData: (state, action) => {
        state.benefitsList = action.payload;
      },
      ethniCityData: (state, action) => {
        state.ethniCityList = action.payload;
      },
      fieldStudyData: (state, action) => {
        state.fieldStudyList = action.payload;
      },
      genderData: (state, action) => {
        state.genderList = action.payload;
      },
      salutationData: (state, action) => {
        state.salutationList = action.payload;
      },

      healthData: (state, action) => {
        state.healthList = action.payload;
      },
      idealWorkData: (state, action) => {
        state.idealWorkList = action.payload;
      },
      leaderShipData: (state, action) => {
        state.leaderShipList = action.payload;
      },
      jodSearchData: (state, action) => {
        state.jobSearchList = action.payload;
      },
      recreationalData: (state, action) => {
        state.recreationalList = action.payload;
      },
      workEnvironmentData: (state, action) => {
        state.workEnvironmentList = action.payload;
      },
      talentListRequested: (state, action) => {
        state.talentListLoading = true
        state.talentList = []
      },
      talentListSuccess: (state, action) => {
        state.talentList = action.payload;
        state.talentListLoading = false
      },talentListFailure: (state, action) => {
        state.talentList =[];
        state.talentListLoading = false
      },
      talentQuestionnareSuccess: (state, action) => {
        state.talentQuestinnareSuccess = action.payload;
        //state.loading = false;
        state.talentQuestUpdateLoading = false
      },talentQuestionnareFailure: (state, action) => {
        state.talentQuestinnareFailure = action.payload;
       // state.loading = false;
       state.talentQuestUpdateLoading = false
      },talentToastReset:(state)=>{
        state.talentSuccess=''
        state.talentFailure = ''
        state.registerSuccess= ''
        state.registerFailure= '' 
        state.jobAppliedSuccess= ''
        state.jobApplyFailed = ''
      },uploadedFileData:(state, action)=>{
        state.talentResume=action.payload
      },
      resumeReset:(state)=>{
        state.talentResume=[]
      },
      // uploadedImage:(state, action)=>{
      //   state.ProfileImg=action.payload
        
      // },
      talentDetailsResponse:(state, action)=>{
        state.talentDetailsData = action.payload;
      },
      talentQuestionnareSuccessRest: (state) => {
        state.talentQuestinnareSuccess = "";
        // state.isLoading = false;
        state.talentQuestUpdateLoading = false
      },talentQuestionnareFailureRest: (state) => {
        state.talentQuestinnareFailure = "";
        // state.loading = false;
        // state.isLoading = false;
        state.talentQuestUpdateLoading = false

      },
      jobTitlesData: (state, action) => {
        state.jobTitlesData =  action.payload;;
        state.loading = false;
      },
      preferredCertificatesData: (state, action) => {
        state.preferredCertificatesData =  action.payload;
        state.loading = false;
      },
      salaryRangeData: (state, action) => {
        state.salaryRangeData =  action.payload;
        state.loading = false;
      },
      skillsData: (state, action) => {
        state.skillsData =  action.payload;
        state.loading = false;
      },
      desiredFunctionsList: (state, action) => {
        state.desiredFunctionsList =  action.payload;
        state.loading = false;
      },

      desiredPolicyList: (state, action) => {
        state.desiredPolicyList =  action.payload;
        state.loading = false;
      },

      desiredDepartmentsList: (state, action) => {
        state.desiredDepartmentsList =  action.payload;
        state.loading = false;
      },

      sexualOrientationList: (state, action) => {
        state.sexualOrientationList =  action.payload;
        state.loading = false;
      }, 

      politicalPartyList: (state, action) => {
        state.politicalPartyList =  action.payload;
        state.loading = false;
      },

      geographicAffiliationList: (state, action) => {
        state.geographicAffiliationList =  action.payload;
        state.loading = false;
      },
      
      managedPeopleList: (state, action) => {
        state.managedPeopleList =  action.payload;
        state.loading = false;
      }, 

      managedBudgetList: (state, action) => {
        state.managedBudgetList =  action.payload;
        state.loading = false;
      },

      jobLocations: (state, action) => {
        state.jobLocationsData = action.payload
        state.loading = false
      },
      tradsData: (state, action) => {
        state.tradsList = action.payload;
      },
      certificationsData: (state, action) => {
        state.secondaryCertifications= action.payload.filter(item => item.category === "Secondary");
        state.primaryCertifications = action.payload.filter(item => item.category === "Primary");
        state.certificatesList = action.payload
      },
      primaryCertificationsData: (state, action) => {
        state.secondaryCertifications= action.payload.filter(item => item.category === "Secondary");
        state.primaryCertifications = action.payload.filter(item => item.category === "Primary");
      },
      wagesData: (state, action) => {
        state.wagesList = action.payload;
      },
      jobTypeGoalsData: (state, action) => {
        state.jobTypeGoalsList = action.payload;
      },
      setJobProfileStatus: (state, action) => {
        state.profileJobStatus = action.payload
      },
      setJobsList: (state, action) => {
        state.jobsList = action.payload;
        state.talentJobsLoading = false;
      },
      setCompanySize: (state, action) => {
        state.companySize = action.payload;
        state.loading = false;
      },
      setApplyJobSuccess: (state, action) => {
        state.jobAppliedSuccess = action.payload;
        state.jobApplyFailed = "";
        state.loading = false;
      },
      setApplyJobFailure: (state, action) => {
        state.jobAppliedSuccess = "";
        state.jobApplyFailed = action.payload;
        state.loading = false;
      },
      setTopicStatus: (state, action) => {
        state.topicStatus = action.payload
      },
      setDiscussionStatus: (state, action) => {
        state.discussionStatus = action.payload
      },
      eventStatusData: (state, action) => {
        state.eventStatus = action.payload
      },
      setInvitedUserData: (state , action) => {
        state.invitedUserData = action.payload
      },
      resetInvitedUserData: state => {
        state.invitedUserData = ""
      },
      setCareers: (state, action) => {
        state.careers = action.payload
      },
      setIndustries: (state, action) => {
        state.industries = action.payload
      },
      inviteRequest: (state) => {
        state.inviteLoading = true
      },
      setMajor: (state, action) => {
        state.major = action.payload
      },
      setUniversityMajor: (state, action) => {
        state.universityMajor = action.payload
      },
      setCommunityTags: (state, action) => {
        state.communityTagList = action.payload
      },
      setInvitePopupData: (state, action) => {
        state.userInvitePopUpData = action.payload
        state.loading = false
      },
      resetInvitePopupData: (state, action) => {
        state.userInvitePopUpData = ""
      },
      setAgreedPrivacySuccess: (state, action) => {
        state.agreedPrivacySuccess = action.payload
        state.loading = false
      },
      setAgreedPrivacyFailed: (state, action) => {
        state.agreedPrivacyFailed = action.payload
        state.loading = false
      },
      resetAgreedMsg: (state) => {
        state.agreedPrivacySuccess = ""
        state.agreedPrivacyFailed = ""
      },
      talentJobsRequest : (state) => {
        state.jobsList = []
        state.talentJobsLoading = true
      },
      talentQSubmitRequest: (state) => {
        state.talentQuestUpdateLoading = true
      },
      setStudentTypes: (state, action) => {
        state.studentTypes = action.payload
      },

    }
});

export const {   talentRegisterSuccess,talentRegisterFailure,  talentRegisterRequest, inviteTalentFailure, inviteTalentSuccess,talentQuestionnareFailureRest,talentQuestionnareSuccessRest,
  workTypesData, benefitsData,  ethniCityData, fieldStudyData,genderData,healthData,idealWorkData,leaderShipData,jodSearchData,uploadedFileData,uploadedImage,
  recreationalData,workEnvironmentData,talentListSuccess,talentListFailure,talentQuestionnareSuccess,talentQuestionnareFailure,talentToastReset,talentDetailsResponse,
  jobTitlesData,preferredCertificatesData,salaryRangeData,skillsData, jobLocations,jobTypeGoalsData,wagesData,primaryCertificationsData,
  certificationsData,tradsData,talentResume,resumeReset,setJobProfileStatus,setJobsList,setApplyJobSuccess,
  setApplyJobFailure,setCompanySize, setTopicStatus, setDiscussionStatus, eventStatusData,
  setInvitedUserData, resetInvitedUserData, setCareers, setIndustries, inviteRequest,
  setMajor, setUniversityMajor, setCommunityTags,
  setInvitePopupData, resetInvitePopupData,sexualOrientationList,geographicAffiliationList,politicalPartyList,
  setAgreedPrivacySuccess, setAgreedPrivacyFailed, resetAgreedMsg,
  talentListRequested, talentJobsRequest,desiredFunctionsList,desiredPolicyList,desiredDepartmentsList,managedPeopleList,managedBudgetList,
  talentQSubmitRequest, setStudentTypes,salutationData} =   talentSlice.actions;
export default   talentSlice.reducer;
