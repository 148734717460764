import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import '@/assets/styles/sampleInput.scss';
import eyeClose from "@/assets/images/icons/eye_close.png";
import eyeOpen from "@/assets/images/icons/eye_open.png";
import { Controller } from 'react-hook-form';
import {checkProperty} from "../utils/helpers"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Information from './icons/information';
//import { useForm } from "react-hook-form";
import moment from 'moment';

function SimpleInput({ id, label, fieldName, errors, inputValue, maxLength, readOnly,onInputChange, type, register, isRequired, errorText, showIcon, className, placeholder, control, numberValue = 5, min="",max="" ,toolTipInfo="",disable=false,
handleKeyDown,
inLogin=false, doValidate=true}) {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  //const { reset, setValue } = useForm();
  // Validation function based on the input type
  const validateField = (value, type) => {
    if (type === 'email') {
      return validateEmail(value);
    }
    else if (type === 'password') {
      if(inLogin){
        return true;
      }else{
        return validatePassword(value);
      }
    }
    else if (type === 'phone number') {
      return validatePhoneNumber(value);
    }
    else if (type === 'url'){
      return validateUrl(value)
    }
    else {
      return !isRequired?true:value.length > 0;
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
      return 'Email is not in a valid format.';

    }
    return true;
  };

  const validatePassword = (value) => {
    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasSpecialCharacter = /[!@#$%^&*()_+]/.test(value);
    const hasNumber = /\d/.test(value);
    if (!(hasUppercase)) {
      return errorText + ' must contain at least one uppercase letter.'
    }
    if (!(hasLowercase)) {
      return errorText + ' must contain at least one lowercase letter.';
    }
    if (!(hasSpecialCharacter)) {
      return errorText + ' must contain at least one special character.';
    }
    if (!(hasNumber)) {
      return errorText + ' must contain at least one number.';
    }
    return true;
  };
  const validatePhoneNumber = (value) => {
    const phoneRegex = /^[0-9]{10}$/;

    if (!phoneRegex.test(value)) {
      return errorText + ' must be a 10-digit number.';
    }

    return true;
  };
  const validateUrl = (value) => {
    // const urlRegexp =  new RegExp('/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i');
    // if (!urlRegexp.test(value) && value) {
    //   console.log("error in url ", errorText)
    //   return errorText + " is invalid"
    // }

       const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;   
       if(!urlRegex.test(value) && value){
        return errorText + " is invalid"
       }
       return true;

      // return urlRegex.test(value) && value?true:errorText + " is invalid"; 
   // return true;
  };
  // const validateDate = (value) => {
  //   console.log("min date ",min, value)
  //   if (!(value <= max && value >= min)) {
  //     return errorText + " is not Valid"
  //   }
  //   return true;
  // };
//console.log('zipcode',inputValue)
  return (
    <React.Fragment>
      <div className='form-group'>
        {label && <Form.Label>
          {isRequired ? label + "*" : label}
          {toolTipInfo && <OverlayTrigger            
            placement="top"
            overlay={
              <Tooltip className="tool_tip">
                {toolTipInfo} 
              </Tooltip>
            }
          >
          <span variant="secondary">
            <Information></Information>
          </span>
        </OverlayTrigger> } 
        </Form.Label>}
        {type !== "textarea" && type !== "zipcode" &&type!=="threashold"&&type!=="date"&&type!=="datetime"&&
          
            <Form.Control
              disabled={disable}
              id={fieldName}
              className={className}
              placeholder={placeholder}
              name={fieldName}
              readOnly={readOnly}
              type={type === 'password' ? (isPasswordVisible ? 'text' : 'password') : type}
              aria-label="Disabled input example"
              defaultValue={inputValue}
              //onChange={onInputChange}
              {...register(fieldName, { required: isRequired, validate: (value) => validateField(value, type), onChange: onInputChange, })}
              onKeyDown={handleKeyDown}
            />
         }
        {( type === "date") &&
          <Form.Group controlId={fieldName}>
            <Controller
              name={fieldName}
              control={control}
              placeholder={placeholder}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  className={className +`${disable ? 'disabled' : ''}`}
                  name={fieldName}
                  // id={id}
                  type={"date"}
                  aria-label="Disabled input example"
                  defaultValue={inputValue}
                  min={min}
                  max={max}
                  disabled={disable}
                  //onChange={onInputChange}
                  {...register(fieldName,{ required: isRequired, validate: (value) => validateField(value, type), onChange: onInputChange, })}
                />
              )}
            />
          </Form.Group>}
          {( type === "datetime") &&
          <Form.Group controlId={fieldName}>
            <Controller
              name={fieldName}
              control={control}
              placeholder={placeholder}
              rules={ doValidate ? {
                max: {value: moment(max).format("YYYY-MM-DDTHH:mm"), message: errorText + " is not valid"},
                min: {value: min? moment(min).format("YYYY-MM-DDTHH:mm"): moment().format("YYYY-MM-DDTHH:mm"), message: errorText + " is not valid"}
              } : {}}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  className={`${className || ''} ${disable ? 'disabled' : ''}`}
                  name={fieldName}
                  // id={id}
                  type={"datetime-local"}
                  aria-label="Disabled input example"
                  defaultValue={inputValue}
                  min={ doValidate ? min? moment(min).format("YYYY-MM-DDTHH:mm"): moment().format("YYYY-MM-DDTHH:mm") : ''}
                  max={doValidate ? moment(max).format("YYYY-MM-DDTHH:mm") : ''}
                  disabled={disable}
                  //onChange={onInputChange}
                  {...register(fieldName,{ required: isRequired, validate: (value) => validateField(value, type), onChange: onInputChange, })}
                />
              )}
            />
          </Form.Group>}
          {( type==="threashold") &&
          <Form.Group controlId={fieldName}>
            <Controller
              name={fieldName}
              control={control}
              placeholder={placeholder}
              rules={{
                max: {value: max, message: errorText + " is not valid"},
                min: {value: min?min:0, message: errorText + " is not valid"}
              }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  className={className}
                  name={fieldName}
                  // id={id}
                  type={"number"}
                  aria-label="Disabled input example"
                  defaultValue={inputValue}
                  //onChange={onInputChange}
                  {...register(fieldName,{ required: isRequired, validate: (value) => validateField(value, type), onChange: onInputChange, })}
                />
              )}
            />
          </Form.Group>}
          {( type === "zipcode" ) &&
            <Form.Control
              id={fieldName}
              className={className}
              placeholder={placeholder}
              name={fieldName}
              type={"number"}
              aria-label="Disabled input example"
              defaultValue={inputValue}
              {...register(fieldName, { required: isRequired, maxLength: {value: maxLength,},validate: (value) => validateField(value, type),  onChange: onInputChange,})}
            />}
        {type === "textarea" && <Form.Control
          className={className}
          placeholder={placeholder}
          name={fieldName}
          aria-label="Disabled input example"
          as="textarea"
          defaultValue={inputValue}
          //onChange={onInputChange}
          {...register(fieldName, { required: isRequired ,onChange: onInputChange,})}
        />}
        {showIcon &&
          <span className='password_view' onClick={() => { setPasswordVisibility(!isPasswordVisible) }}>
            <img src={isPasswordVisible ? eyeOpen : eyeClose} alt={isPasswordVisible ? 'Hide' : 'Show'} />
          </span>
        }
        {errors &&
        fieldName &&
        checkProperty(fieldName.split("."), "length") > 2 &&
        checkProperty(
          errors,
          fieldName.split(".")[0],
          fieldName.split(".")[1]
        ) &&
        checkProperty(
          errors[fieldName.split(".")[0]][fieldName.split(".")[1]],
          fieldName.split(".")[2],
          "type"
        ) === "required" && (
          <span className="form-error">{"* " + errorText + " is reqired"}</span>
        )}
        {type === "zipcode"&&errors &&
        fieldName &&
        checkProperty(fieldName.split("."), "length") > 2 &&
        checkProperty(
          errors,
          fieldName.split(".")[0],
          fieldName.split(".")[1]
        ) &&
        checkProperty(
          errors[fieldName.split(".")[0]][fieldName.split(".")[1]],
          fieldName.split(".")[2],
          "type"
        ) === "maxLength" && (
          <span className="form-error">{"* " + errorText + ' is not valid'}</span>
        )}
        {errors && fieldName && checkProperty(fieldName.split('.'), 'length') > 1 &&
                checkProperty(errors, fieldName.split('.')[0], fieldName.split('.')[1]) &&
                checkProperty(errors[fieldName.split('.')[0]], fieldName.split('.')[1], 'type') === "required" &&
                <span className='form-error'>{"* " +errorText + ' is reqired'}</span>}
        {errors?.[fieldName] && errors?.[fieldName].type && errors?.[fieldName].type === "required" &&
          <span className='form-error'>{"* " + errorText + ' is required'}</span>}
          
        {type === 'password' && errors[fieldName] && (
          <span className='form-error'>{"* " + errors[fieldName].message}</span>
        )}
        {type === 'email' && errors[fieldName] && (
          <span className='form-error'>{"* " + errors[fieldName].message}</span>
        )}
        {type === 'phone number' && errors[fieldName] && (
          <span className='form-error'>{"* " + errors[fieldName].message}</span>
        )}
        {type === 'threashold' && errors[fieldName] && (
          <span className='form-error'>{"* " + errors[fieldName].message}</span>
        )}
        {type === 'datetime' && errors[fieldName] && (
          <span className='form-error'>{"* " + errors[fieldName].message}</span>
        )}
        {type === 'url' && errors[fieldName] && (
          <span className='form-error'>{"* " + errors[fieldName].message}</span>
        )}
        {type === "zipcode"&&errors?.[fieldName] && errors?.[fieldName].type && errors?.[fieldName].type === "maxLength" &&
          <span className="form-error">{"* " + errorText + ' is not valid'}</span>
        }
        {type !== "zipcode"&&errors?.[fieldName] && errors?.[fieldName].type && errors?.[fieldName].type === "maxLength" &&
          <span className='form-error'>{errors?.[fieldName].message}</span>
        }
      </div>
    </React.Fragment>
  );
}

export default SimpleInput;
