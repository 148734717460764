// Redux action imports for community registration.
import {
  communityRegisterRequest,
  communityRegisterSuccess,
  communityRegisterFailure,
  communityListRequest,
  communityListSuccess,
  communityListFailure,
  updateCommunitySuccess,
  updateCommunityFailure,
  updateUserSuccessMessage,
  updateUserFailureMessage,
  communityDetailsData,
  userDetailsData,
  inviteBranchSuccess,
  inviteBranchFailure,
  topicCreateSuccess,
  topicCreateFailure,
  topicListSuccess,
  topicListFailure,
  updateTopicSuccess,
  updateTopicFailure,
  updateDiscussionSuccess,
  updateDiscussionFailure,
  eventsListSuccess,
  eventsListFailure,eventDetailsSuccess,
  eventDetailsFailure,
  eventCreateSuccess,eventCreateFailure,
  eventUpdateSuccess, eventUpdateFailure,
  eventDeleteSuccess, eventDeleteFailure,
  followersListSuccess, followersListFailure,
  topLocationsListSuccess, topLocationsListFailure,
  experienceFailure, experienceSuccess,
  topSkillsStatSuccess, topSkillsStatFailure,
  setHiredStats, setInterviewdStats, setRejectedStats,
  setFavoriteCompanies, setFavoriteJobs,
  setJobsPostedByCmpny, setUsersAppliedMost,
  setJobSeekers,
  deleteTopicSuccess, deleteTopicFailure,
  saveEventInterestSuccess,
  saveEventInterestFailure,
  setEventInterestList,
  setEventAttendeesList,
  setTalentsCount,
  setJobsCount,
  setMatchesCount,
  resetTalentsCount,
  resetJobsCount,
  resetMatchesCount,
  followerslistLoading,
  locationsDashboardLoading,
  dashboardExperienceLoading,
  dashboardTopicsListLoading,
  upcomingEventsLoading,
  upcomingEventsSuccess,
  upcomingEventsFailure,
  countsLoading,
  resetCommunityList,
  favCompaniesListRequest,
  hireStatsRequest,
  rejectedStatsRequest,
  interviewStatsRequest,
  jobPostByCompRequest,
  topSkillsCountRequest,
  favJobStatsRequest,
  jobSeekerListRequest,
  mostAppliedUsersRequest,
  eventDetailsRequest,
  setResendInviteLinkSuccess,
  setResendInviteLinkFailed,
  communityDetailsRequest,
  communityListCountReq,
  setCommunityListCount,
  setGenderCount,
  genderwiseStatsRequest,
  setRaceStats,
  raceWiseStatsRequest,
} from "../redux/slices/communitySlice";

import { topicDetailsData } from '../redux/slices/commonSlice';

// Abstracted service for making API requests.
import {apiService} from "./apiService";

// Utility function to display toast notifications.
import { showToast } from "../views/Utils";
const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * Initiates the registration process for a community using an invitation.
 *
 * @param {Object} payLoad - Contains user registration details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const communityRegister = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the registration request has started.
  dispatch(communityRegisterRequest());

  try {
    // Makes an API call to register the invited user.
    const response = await apiService.post(
      "users/invited-user-register",
      payLoad
    );

    const returnData = response.data?.result;

    // Dispatches an action to store the registered user details in the redux store.
    dispatch(communityRegisterSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to indicate the registration request failed.
    dispatch(communityRegisterFailure(errorMessage));

    // Displays a toast notification with the error message.
    showToast(errorMessage, "error");
  }
};

/**
 * To get the latest community List
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getCommunityList = (payLoad,cancelTokenSource) => async (dispatch) => {
  // Dispatches an action to indicate the registration request has started.
  dispatch(resetCommunityList());
  try {
    // Makes an API call to register the invited user.
    const response = await apiService.post("community/list", payLoad,{ signal: cancelTokenSource?.signal});
    // console.log("community  list --- ", response.data?.result)
    const returnData = response.data?.result;

    // Dispatches an action to store the get community list in the redux store.
    dispatch(communityListSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    if (error?.message == 'canceled') {
      dispatch(resetCommunityList())
    } else {
      const errorMessage = error?.response?.data?.message || error?.message;
      console.log("community  list --- failed ", error);

      // Dispatches an action to show get community list request failed.
      dispatch(communityListFailure(errorMessage));
    }
  }
};
export const updateCommunityInfo = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post("community/update", payLoad);
    const returnData = response.data?.result.message;
    dispatch(updateCommunitySuccess(returnData));
    // console.log("returnData",returnData)
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(updateCommunityFailure(errorMessage));
    //console.log("errorMessage",errorMessage)
  }
};
export const updateUserInfo = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post("users/update", payLoad);
    const returnData = response.data?.result.message;
    dispatch(updateUserSuccessMessage(returnData));
    // console.log("returnData",returnData)
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(updateUserFailureMessage(errorMessage));
    // console.log("errorMessage",errorMessage)
  }
};
export const userDetailsInfo = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post("users/details", payLoad);
    const returnData = response.data?.result;
    dispatch(userDetailsData(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
  }
};
export const getCommunityDetails = (payLoad) => async (dispatch) => {
  dispatch(communityDetailsRequest())
  try {
    const response = await apiService.post("community/details", payLoad);
    const returnData = response.data?.result;
    dispatch(communityDetailsData(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
  }
};
export const userDetails = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post("users/details", payLoad);
    const returnData = response.data?.result;
    dispatch(userDetailsData(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
  }
};
/**
 * Sends an invitation to a community branch.
 * @param {Object} payLoad - Invitation details.
 * @returns {Promise} - Returns server response.
 */
export const branchInvite = (payLoad) => async (dispatch) => {
  dispatch(communityListRequest())

  try {
    const response = await apiService.post('community/branch-invite', payLoad);
    const returnData = response.data?.result.message;
    dispatch(inviteBranchSuccess(returnData));
  } catch (error) {
    // Detailed error handling can be incorporated based on requirements.
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(inviteBranchFailure(errorMessage));
  }
};

/**
 * Sends an invitation to a community branch.
 * @param {Object} payLoad - Invitation details.
 * @returns {Promise} - Returns server response.
 */
export const postCreatedTopic = (payLoad) => async (dispatch) => {
  dispatch(communityListRequest())

  try {
    const response = await apiService.post('topic/create', payLoad);
    const returnData = response.data?.result.message;
    dispatch(topicCreateSuccess(returnData));
  } catch (error) {
    // Detailed error handling can be incorporated based on requirements.
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(topicCreateFailure(errorMessage));
  }
};
/**
 * To get the latest community List
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getTopicsList = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the registration request has started.
  dispatch(dashboardTopicsListLoading());

  try {
    // Makes an API call to register the invited user.
    const response = await apiService.post("topic/topics-list", payLoad);
    // console.log("  list --- ", response.data?.result)
    const returnData = response.data?.result;

    // Dispatches an action to store the get community list in the redux store.
    dispatch(topicListSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;
    // console.log("  list --- failed ", errorMessage);

    // Dispatches an action to show get community list request failed.
    dispatch(topicListFailure([]));
  }
};
export const getTopicDetails = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post("topic/details", payLoad);
    const returnData = response.data?.result;
    dispatch(topicDetailsData(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(topicDetailsData(errorMessage));
  }
};

export const updateTopic = (payLoad) => async (dispatch) => {
  dispatch(communityListRequest());
  try {
    const response = await apiService.post("topic/update", payLoad);
    const returnData = response.data?.result?.message;
    dispatch(updateTopicSuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(updateTopicFailure(errorMessage));
  }
};

export const updateDiscussion= (payLoad) => async (dispatch) => {
  dispatch(communityListRequest());
  try {
    const response = await apiService.post("topic/update-discussion", payLoad);
    const returnData = response.data?.result?.message;
    dispatch(updateDiscussionSuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(updateDiscussionFailure(errorMessage));
  }
};

/**
 * To get the events List
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getEventsList = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the events list request has started.
  dispatch(communityListRequest());

  try {
    // Makes an API call to register the invited user.
    const response = await apiService.post("events/list", payLoad);
    const returnData = response.data?.result;

    // Dispatches an action to store the get events list in the redux store.
    dispatch(eventsListSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get events list request failed.
    dispatch(eventsListFailure([]));
  }
};

/**
 * To get the event details
 *
 * @param {Object} payLoad - Contains event id.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getEventDetails = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the event details request has started.
  dispatch(eventDetailsRequest());

  try {
    // Makes an API call to get event details.
    const response = await apiService.post("events/details", payLoad);
    const returnData = response.data?.result;

    // Dispatches an action to store the get details of an event in the redux store.
    dispatch(eventDetailsSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get details of an event request failed.
    dispatch(eventDetailsFailure([]));
  }
};
/**
 * To create new event
 *
 * @param {Object} payLoad - Contains event creation details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const createEvent = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate new event create request has started.
  dispatch(communityListRequest());

  try {
    // Makes an API call to create new event.
    const response = await apiService.post("events/create", payLoad);
    const returnData = response.data?.result?.message;

    // Dispatches an action to store the details of an event creation in the redux store.
    dispatch(eventCreateSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get details of an event request failed.
    dispatch(eventCreateFailure(errorMessage));
  }
};
/**
 * To update an event details
 *
 * @param {Object} payLoad - Contains an event details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const updateEvent = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate update event request has started.
  dispatch(communityListRequest());

  try {
    // Makes an API call to  update event.
    const response = await apiService.post("events/update", payLoad);
    const returnData = response.data?.result?.message;

    // Dispatches an action to store the details of an event update in the redux store.
    dispatch(eventUpdateSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show update event request failed.
    dispatch(eventUpdateFailure(errorMessage));
  }
};
/**
 * To delete an event details
 *
 * @param {Object} payLoad - Contains an event id.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const deleteEvent = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate delete event request has started.
  dispatch(communityListRequest());

  try {
    // Makes an API call to  delete event.
    const response = await apiService.post("events/delete", payLoad);
    const returnData = response.data?.result?.message;

    // Dispatches an action to store the details of an event delete in the redux store.
    dispatch(eventDeleteSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show delete event request failed.
    dispatch(eventDeleteFailure(errorMessage));
  }
};

/**
 * To get the followers List for a community
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getFollowersList = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the followers list request has started.
  dispatch(followerslistLoading());

  try {
    // Makes an API call to register the invited user.
    const response = await apiService.post("community/followers-list", payLoad);
    const returnData = response.data?.result;

    // Dispatches an action to store the get followers list in the redux store.
    dispatch(followersListSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get followers list request failed.
    dispatch(followersListFailure([]));
  }
};


/**
 * To get the top locations List for a community
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getTopLocationsList = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the  top locations list request has started.
  dispatch(locationsDashboardLoading());

  try {
    // Makes an API call to register the invited user.
    const response = await apiService.post("jobs/top-locations-list", payLoad);
    const returnData = response.data?.result;

    // Dispatches an action to store the get  top locations list in the redux store.
    dispatch(topLocationsListSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get  top locations list request failed.
    dispatch(topLocationsListFailure([]));
  }
};

/**
 * To get the experience counts for a community
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getExperienceStats = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the experience counts request has started.
  dispatch(dashboardExperienceLoading());

  try {
    // Makes an API call to register the invited user.
    const response = await apiService.post("dashboard/exp-stats-count", payLoad);
    const returnData = response.data?.result;

    // Dispatches an action to store the get experience counts in the redux store.
    dispatch(experienceSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get experience counts request failed.
    dispatch(experienceFailure([]));
  }
};

/**
 * To get the top skills count for a community
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getTopSkillsStats = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the top skills counts request has started.
  dispatch(topSkillsCountRequest());

  try {
    // Makes an API call to get top skills count.
    const response = await apiService.post("dashboard/top-skills-list", payLoad);
    const returnData = response.data?.result;

    // Dispatches an action to store the get top skills count in the redux store.
    dispatch(topSkillsStatSuccess(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get top skills count request failed.
    dispatch(topSkillsStatFailure([]));
  }
};



/**
 * To get the most interviewed stats for a community
 *
 * @param {Object} payLoad - Contains most interviewed stats details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getInterviewerStats = ({type}) => async (dispatch) => {
  // Dispatches an action to indicate the most interviewed stats request has started.
  let payLoad = {
    "page":1,
    "perpage":5, 
    "interviewType": type // 1 for Interviewed, 2 for Rejected, 3 for Hired
}
switch (type){
  case 1:
    dispatch(interviewStatsRequest())
    break;
  case 2:
    dispatch(rejectedStatsRequest())
    break;
  case 3:
    dispatch(hireStatsRequest())
    break;
  default:
    break;
}

  try {
    // Makes an API call to get most interviewed stats.
    const response = await apiService.post("dashboard/interviewer-details", payLoad);
    const returnData = response.data?.result;

    // Dispatches an action to store the get most interviewed stats in the redux store.
    switch (type) {
      case 1:
        dispatch(setInterviewdStats(returnData?.[0]));
        break;
      case 2:
        dispatch(setRejectedStats(returnData?.[0]));
        break;
      case 3:
        dispatch(setHiredStats(returnData?.[0]));
        break;
      default:
        return null
        break;
    }
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get most interviewed stats request failed.
    switch (type) {
      case 1:
        dispatch(setInterviewdStats([]));
        break;
      case 2:
        dispatch(setRejectedStats([]));
        break;
      case 3:
        dispatch(setHiredStats([]));
        break;
      default:
        return null
      break;
    }
  }
};

/**
 * To get the favorite companies list for a community
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getFavoriteCompanies = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the favorite companies list request has started.
  dispatch(favCompaniesListRequest());

  try {
    // Makes an API call to get favorite companies list.
    const response = await apiService.post("dashboard/fav-companies-list", payLoad);
    const returnData = response.data?.result;

    // Dispatches an action to store the get favorite companies list in the redux store.
    dispatch(setFavoriteCompanies(returnData?.[0]));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get favorite companies list request failed.
    dispatch(setFavoriteCompanies([]));
  }
};

/**
 * To get the favorite jobs list for a community
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getFavoriteJobs = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the favorite jobs list request has started.
  dispatch(favJobStatsRequest());

  try {
    // Makes an API call to get favorite jobs list.
    const response = await apiService.post("dashboard/fav-jobs-list", payLoad);
    const returnData = response.data?.result;

    // Dispatches an action to store the get favorite jobs list in the redux store.
    dispatch(setFavoriteJobs(returnData?.[0]));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get favorite jobs list request failed.
    dispatch(setFavoriteJobs([]));
  }
};

/**
 * To get the company posted jobs for a community
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getJobsKPostedByCompany = (payLoad) => async (dispatch) => {
  // Dispatches an action to indicate the favorite jobs list request has started.
  dispatch(jobPostByCompRequest());

  try {
    // Makes an API call to get favorite jobs list.
    const response = await apiService.post("dashboard/job-posted-companies", payLoad);
    const returnData = response.data?.result;

    // Dispatches an action to store the get favorite jobs list in the redux store.
    dispatch(setJobsPostedByCmpny(returnData?.[0]));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    // Dispatches an action to show get favorite jobs list request failed.
    dispatch(setJobsPostedByCmpny([]));
  }
};

/**
 * To get the users who applied for jobs the most in a community
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getMostAppliedUsers = (payLoad) => async (dispatch) => {
  dispatch(mostAppliedUsersRequest());

  try {
    const response = await apiService.post("dashboard/most-applied-users", payLoad);
    const returnData = response.data?.result;

    dispatch(setUsersAppliedMost(returnData?.[0]));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setUsersAppliedMost([]));
  }
};

/**
 * To get the users who applied for jobs in a community
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getJobSeekers = (payLoad) => async (dispatch) => {
  dispatch(jobSeekerListRequest());

  try {
    const response = await apiService.post("dashboard/job-seekers-list", payLoad);
    const returnData = response.data?.result;

    dispatch(setJobSeekers(returnData?.[0]));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setJobSeekers([]));
  }
};

export const deleteTopic = (payLoad) => async (dispatch) => {
  dispatch(communityListRequest());
  try {
    const response = await apiService.post("topic/topic-status-update", payLoad);
    const returnData = response.data?.result?.message;
    dispatch(deleteTopicSuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(deleteTopicFailure(errorMessage));
  }
};

export const saveEventInterest = (payLoad) => async (dispatch) => {
  dispatch(communityListRequest());
  try {
    const response = await apiService.post("events/save-event-analytics", payLoad);
    const returnData = response.data?.result?.message;
    dispatch(saveEventInterestSuccess(returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(saveEventInterestFailure(errorMessage));
  }
};

export const getEventInterestedList = (payLoad) => async (dispatch) => {
  dispatch(eventDetailsRequest());
  try {
    const response = await apiService.post("events/events-analytics-list", payLoad);
    const returnData = response.data?.result;
    if(payLoad.type == 1){
      dispatch(setEventInterestList(returnData));
    }
    if(payLoad.type == 2){
      dispatch(setEventAttendeesList(returnData));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(setEventInterestList([]));
  }
};


export const getTalentsCount = (payLoad) => async (dispatch) => {
  dispatch(countsLoading());
  try {
    const response = await apiService.post("community/talent-count", payLoad);
    const returnData = response.data?.result?.talentCount;
   // dispatch(setTalentsCount(returnData));
    dispatch(setTalentsCount((!returnData||isObjectEmpty(returnData))?"0":returnData));
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(setTalentsCount("0"));
  }
};

export const getJobsCount = (payLoad) => async (dispatch) => {
   dispatch(countsLoading());
   try {
     const response = await apiService.post("community/jobs-count", payLoad);
     const returnData = response.data?.result?.jobsCount;
     dispatch(setJobsCount((!returnData||isObjectEmpty(returnData))?"0":returnData));
     //dispatch(setJobsCount(returnData));
   } catch (error) {
     const errorMessage = error.response?.data?.message || error.message;
     dispatch(setJobsCount("0"));
   }
 };
 export const getMatchesCount = (payLoad) => async (dispatch) => {
   dispatch(countsLoading());
   try {
     const response = await apiService.post("community/matched-job-count", payLoad);
     const returnData = response.data?.result?.matchedJobcount;
     dispatch(setMatchesCount((!returnData||isObjectEmpty(returnData))?"0":returnData));
   } catch (error) {
     const errorMessage = error.response?.data?.message || error.message;
     dispatch(setMatchesCount("0"));
   }
 };

 export const getUpcomingEvents = (payLoad) => async (dispatch) => {
   dispatch(upcomingEventsLoading());
   try {
     const response = await apiService.post("/community/upcoming-events", payLoad);
     const returnData = response.data?.result;
     dispatch(upcomingEventsSuccess(returnData));
   } catch (error) {
     const errorMessage = error.response?.data?.message || error.message;
     dispatch(upcomingEventsFailure());
   }
 };

// resend invite link
export const resendActivationLink = (payLoad) => async (dispatch) => {

    try {
        // Makes an API call to re-send the invite link to super admin.
        const response = await apiService.post('community/resend-invite-link', payLoad);
        const returnData = response.data?.result?.message;

        // Dispatches an action to store the re-send invitation link response in the redux store.
        dispatch(setResendInviteLinkSuccess(returnData));
    } catch (error) {
        // Handles any errors that occur during the API request.
        const errorMessage = error.response?.data?.message || error.message;

        // Dispatches an action to show the resend invitation link request failed.
        dispatch(setResendInviteLinkFailed(errorMessage));
        
    }
};

/**
 * To get the latest community List count 
 *
 * @param {Object} payLoad - Contains list filtering details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getComListCount = (payLoad,cancelTokenSource) => async (dispatch) => {
  // Dispatches an action to indicate the community List count  request has started.
  dispatch(communityListCountReq());
  try {
    // Makes an API call to register the invited user.
    const response = await apiService.post("community/list", payLoad,{ signal: cancelTokenSource?.signal});
    // console.log("community  list --- count - ", response.data?.result)
    const returnData = response.data?.result;

    // Dispatches an action to store the get community list count in the redux store.
    dispatch(setCommunityListCount(returnData?.totalCount));
  } catch (error) {
    // Handles any errors that occur during the API request.
    if (error?.message == 'canceled') {
      dispatch(communityListCountReq())
    } else {
    const errorMessage = error?.response?.data?.message || error?.message;
    // console.log("community  list count --- failed ", error);

    // Dispatches an action to show get community list count request failed.
    dispatch(setCommunityListCount(0));
    }
  }
};

/**
 * To get the gender wise talent count in a community
 *
 * @param {Object} payLoad - Contains communityId
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getGenderWiseStats = (payLoad) => async (dispatch) => {
  dispatch(genderwiseStatsRequest());

  try {
    const response = await apiService.post("community/gender-comparision", payLoad);
    const returnData = response.data?.result;

    dispatch(setGenderCount(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setGenderCount([]));
  }
};

/**
 * To get the race wise talent count in a community
 *
 * @param {Object} payLoad - Contains commmunity id.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getRaceWiseStats = (payLoad) => async (dispatch) => {
  dispatch(raceWiseStatsRequest());

  try {
    const response = await apiService.post("community/raise-comparision", payLoad);
    const returnData = response.data?.result;

    dispatch(setRaceStats(returnData));
  } catch (error) {
    // Handles any errors that occur during the API request.
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setRaceStats([]));
  }
};