// Redux action imports for user management.
import {
  loginFailure,
  resetSuccess,
  resetFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  registerSuccess,
  registerFailure,
  userDetailsFailure,
  userDetailsSuccess,
  countryData,
  stateData,
  cityData,
  changePasswordSuccess,
  changePasswordFailure
} from '../redux/slices/userSlice';
import {
  commonUploads,
  uploadedImage
} from '../redux/slices/commonSlice';
import {
  authRequest,
  authSuccess
} from '../redux/slices/authSlice';

import {
  inviteCommunitySuccess,
  inviteCommunityFailure,
} from '../redux/slices/communitySlice';
import {
  inviteEmployerSuccess,
  inviteEmployerFailure
} from '../redux/slices/employerSlice';
import {
  talentRegisterRequest,
  inviteRequest,
  inviteTalentSuccess,
  setInvitedUserData,
  inviteTalentFailure,
  uploadedFileData,
} from '../redux/slices/talentSlice';
// Abstracted service for API requests.
import {apiService} from './apiService';
import { getPreferedCountries } from '../utils/helpers';

/**
 * Initiates the user login process.
 * @param {Object} payLoad - Contains user credentials (username & password).
 * @returns {Promise} - Returns server response.
 */
export const signIn = (payLoad) =>
  //   {

  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const response = await apiService.post('auth/login', payLoad);
  //       const userData = response.data?.result?.data;

  //       // Additional logic here

  //       resolve(userData); // Resolve the Promise with the user data
  //     } catch (error) {
  //       reject(error); // Reject the Promise with the error
  //     }
  //   });
  // };

  async (dispatch) => {
    dispatch(authRequest());
    try {
      const response = await apiService.post('auth/login', payLoad);
      const userData = response.data?.result?.data;
      // Securely storing essential user details and tokens.
      localStorage.setItem("userinfo", JSON.stringify(userData));
      localStorage.setItem("token", userData.accessToken);
      localStorage.setItem('isLoggedIn', true);
      dispatch(authSuccess(userData));
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      dispatch(loginFailure(errorMessage));
    }
  };

/**
 * Registers a new user in the system.
 * @param {Object} payLoad - User's registration data.
 * @returns {Promise} - Returns server response.
 */
export const userRegister = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post('auth/signup', payLoad);
    const returnData = response.data?.result?.data;

    dispatch(registerSuccess(returnData));
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(registerFailure(errorMessage));
  }
};

/**
 * Initiates the password reset process for a user in the system.
 * @param {Object} payLoad - Data required for initiating password reset.
 * @returns {Promise} - Returns server response.
 */
export const forgotPassword = (payLoad) => async (dispatch) => {
  dispatch(forgotPasswordRequest());
  try {
    const response = await apiService.post('auth/forgot-password', payLoad);
    const returnData = response.data?.result;

    dispatch(forgotPasswordSuccess(returnData));
    // return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(forgotPasswordFailure(errorMessage));
  }
};

/**
 * Resets the password for a user in the system.
 * @param {Object} payLoad - Data required for password reset.
 * @returns {Promise} - Returns server response.
 */
export const resetPassword = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post('auth/update-password', payLoad);
    const returnData = response.data?.result;
    dispatch(resetSuccess(returnData));
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(resetFailure(errorMessage));
  }
};

/**
 * Fetches detailed information of a user based on criteria.
 * @param {Object} payLoad - Filter criteria.
 * @returns {Promise} - Returns server response.
 */
export const getUserDetails = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post('users/invite-user-details', payLoad);
    const returnData = response.data?.result;

    dispatch(userDetailsSuccess(returnData));
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(userDetailsFailure(errorMessage));
  }
};

/**
 * Retrieves master data based on category (e.g., locations, states).
 * Ensures that the request payload is built dynamically based on category.
 * @param {Object} params - Contains data category and related ID.
 * @returns {Promise} - Returns server response.
 */
export const getMasterData = ({ category, id }) => async (dispatch) => {
  const payLoad = category === "locations"
    ? { "matcher": { "stateId": id }, "category": category, "page": 1, "perpage": 6000 }
    : { "matcher": { "countryId": id }, "category": category, "page": 1, "perpage": 6000 };

  try {
    const response = await apiService.post('masterdata/list', payLoad);
    const returnData = response.data?.result?.list;

    // Determine the type of data retrieved and dispatch the appropriate action.
    switch (category) {
      case 'states':
        dispatch(stateData(returnData));
        break;
      case 'locations':
        dispatch(cityData(returnData));
        break;
      case 'all_locations':
        dispatch(cityData(returnData))
        break;
      default:
        dispatch(countryData(getPreferedCountries(returnData)));
    }

    return response;
  } catch (error) {
    // Handling errors for all categories via a general action.
    // In a more advanced setup, different actions could be dispatched for different error scenarios.
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(countryData(errorMessage));
  }
};

/**
 * Sends an invitation to an employer.
 * @param {Object} payLoad - Invitation details.
 * @returns {Promise} - Returns server response.
 */
export const employerInvite = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post('employer/invite', payLoad);
    const returnData = response.data?.result.message;
    dispatch(inviteEmployerSuccess(returnData));
    // return returnData;
  } catch (error) {
    // Detailed error handling can be incorporated based on requirements.
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(inviteEmployerFailure(errorMessage));
    // throw errorMessage;
  }
};

/**
 * Sends an invitation to a community group.
 * @param {Object} payLoad - Invitation details.
 * @returns {Promise} - Returns server response.
 */
export const communityInvite = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post('community/invite', payLoad);
    const returnData = response.data?.result.message;
    dispatch(inviteCommunitySuccess(returnData));
  } catch (error) {
    // Detailed error handling can be incorporated based on requirements.
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(inviteCommunityFailure(errorMessage));
  }
};
/**
 * Sends an invitation to a talent group.
 * @param {Object} payLoad - Invitation details.
 * @returns {Promise} - Returns server response.
 */
export const talentInvite = (payLoad) => async (dispatch) => {
  dispatch(talentRegisterRequest())
  dispatch(inviteRequest())

  try {
    const response = await apiService.post('users/invite', payLoad);
    const returnData = response.data?.result.message;
    dispatch(inviteTalentSuccess(returnData));
    dispatch(setInvitedUserData(response.data?.result))
  } catch (error) {
    // Detailed error handling can be incorporated based on requirements.
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(inviteTalentFailure(errorMessage));

  }
};
export const uploadFile = ({ formData, type,isMultiple=false }) => async (dispatch) => {
  try {
    const response = await apiService.post('s3/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    const returnData = response.data?.result;
    switch (type) {
      case 'profileImg':
        if(isMultiple){
          dispatch(commonUploads(returnData && returnData.length > 0 ? returnData :[]));
        }else{
          dispatch(uploadedImage(returnData && returnData.length > 0 ? returnData[0].path : ""));
        }
        break;
      default:
        dispatch(uploadedFileData(returnData));
    }

    dispatch(commonUploads(returnData));

  } catch (error) {
    // Detailed error handling can be incorporated based on requirements.
    const errorMessage = error.response?.data?.message || error.message;


  }
};
export const changePassword = (payLoad) => async (dispatch) => {
  try {
    const response = await apiService.post('auth/change-password', payLoad);
    const returnData = response.data?.result.message;
    dispatch(changePasswordSuccess(returnData));
    // return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(changePasswordFailure(errorMessage));
  }
};