
import { lazy } from 'react';
// import JobsList from '../views/Employer/jobs';
const CommunityDashboard = lazy(() => import('../views/Community/Dashboard'));
const CommunityRegister = lazy(() => import('../views/Community/Register'));
const CommunityListing = lazy(() => import('../views/Community/BranchList'));
const Forums = lazy(() => import('../views/Community/forums'));
const Events = lazy(() => import('../views/Community/events'));
const FeaturedCommunity = lazy(() => import('../views/Community/featuredCommunities'));
const  Communitydashboard  = lazy(() => import('../views/Dashboard/Community/Home'));
const  CommunityCompanies  = lazy(() => import('../views/Dashboard/Community/Companies'));
const  CommunityEvents  = lazy(() => import('../views/Dashboard/Community/Events'));
const  CommunityForum  = lazy(() => import('../views/Dashboard/Community/Forum'));
const  CommunityProfile  = lazy(() => import('../views/Dashboard/Community/Profile'));
const  CommunityJobSeekersList  = lazy(() => import('../views/Dashboard/Community/JobSeekersList'));
const  CommunityMembers  = lazy(() => import('../views/Dashboard/Community/Members'));
const  Analysis  = lazy(() => import('../views/Dashboard/CompanyPartners'));
const  JobAnalysis  = lazy(() => import('../views/Dashboard/JobAnalysis'));
const  TalentAnalysis  = lazy(() => import('../views/Dashboard/TalentAnalysis'));
const Branches = lazy(() => import('../views/Dashboard/Community/Branches'))
const Payments = lazy(() => import('../views/Dashboard/Community/Payments'))
const EditCommunity = lazy(() => import('../views/Community/editCommunity'));
const CommunityJobsList = lazy(() => import('../views/Dashboard/Community/CommunityJobsList'));



const communityRoutes = [
  // { path: '/community/dashboard', component: CommunityDashboard, exact: true },
  { path: '/community/register', component: CommunityRegister, exact: true },
  { path: '/invitation/community', component: CommunityRegister, exact: true },
  { path: '/branches', component:  CommunityListing, exact : true},
  { path: '/forums', component:  Forums, exact : true},
  { path: '/events', component:  Events, exact : true},
  { path: '/community/featured', component: FeaturedCommunity , exact: true},
  { path: '/dashboard/community/', component: Communitydashboard, exact: true },
  { path: '/dashboard/community/companies', component: CommunityCompanies, exact: true },
  { path: '/dashboard/community/events', component: CommunityEvents, exact: true },
  { path: '/dashboard/community/forum', component: CommunityForum, exact: true },
  { path: '/dashboard/community/profile', component: CommunityProfile, exact: true },
  { path: '/dashboard/community/jobseekers', component: CommunityJobSeekersList, exact: true },
  { path: '/dashboard/community/members', component: CommunityMembers, exact: true },
  { path: '/dashboard/analysis', component: Analysis, exact: true },
  { path: '/dashboard/jobanalysis', component: JobAnalysis, exact: true },
  { path: '/dashboard/talentanalysis', component: TalentAnalysis, exact: true }, 
  { path: '/dashboard/community/branches', component: Branches, exact: true },
  { path: '/dashboard/community/payments', component: Payments, exact: true },
  { path: '/edit-community', component: EditCommunity, exact: true },
  { path: '/dashboard/community/jobs', component: CommunityJobsList, exact: true },


];

export default communityRoutes;
