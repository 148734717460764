
import { lazy } from 'react';
const Login = lazy(() => import('../views/Login'));
const Register = lazy(() => import('../views/Register'));
const ForgotPassword = lazy(() => import('../views/forgotPassword'));
const IncompleteProfile = lazy(() => import('../views/incompleteProfile'));
const EventPage = lazy(() => import('../views/Community/eventPage'));

const userRoutes = [
  { path: '/login', component: Login, exact: true },
  { path: '/register', component: Register, exact: true },
  { path: '/forgot-password', component: ForgotPassword, exact: true },
  { path: '/complete-profile', component: IncompleteProfile, exact: true},
  { path: '/events/:eventName/:eventId', component: EventPage, exact: false},
];

export default userRoutes;
