
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Cropper from 'react-easy-crop';
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
//import { uploadFile } from '../services/authService';
const CropperModel = ({ showCrop, hidecrop, onConfirmSubmit, files, getCropImg, onCropSubmitt, fileUploader, shape, isEvent }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedImage, setCroppedImage] = useState([]);
  const [zoom, setZoom] = useState(1);
  const dispatch = useDispatch();
  const onCropChange = useCallback((crop) => {
    setCrop(crop);

  }, []);

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    try {
      setCroppedImage(croppedAreaPixels);
    } catch (e) {
      console.error("Error while cropping the image", e);
    }
  };

  const getCroppedImg = async (image, croppedAreaPixels) => {
    // Create a new Blob with the cropped area from the original image
    const blob = await createBlobFromCroppedArea(image, croppedAreaPixels);
    // Convert the Blob to a data URL
    if (blob instanceof Blob) {
      return blob;
    } else {
      throw new Error('Invalid Blob');
    }
  };

  const createBlobFromCroppedArea = (image, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          img,
          croppedAreaPixels.x * scaleX,
          croppedAreaPixels.y * scaleY,
          croppedAreaPixels.width * scaleX,
          croppedAreaPixels.height * scaleY,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          files[0].type, // Adjust this MIME type as needed
          1
        );
      };

      img.onerror = () => {
        reject(new Error('Image loading failed'));
      };

      img.src = URL.createObjectURL(image);
    });
  };
  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };
  const onSubmitCrop = async () => {
    const croppedImg = await getCroppedImg(files[0], croppedImage);
    if (croppedImg) {
      if (onCropSubmitt) {
        onCropSubmitt()
      }
      const convertedFile = new File([croppedImg], `${files[0].name}`, { type: files[0].type, });
      const formData = new FormData();
      formData.append("files", convertedFile);
      formData.append("secureType", "public");
      formData.append("getDetails", true);
      dispatch(fileUploader({ formData: formData, type: "profileImg" }));
      hidecrop()
    } else {

    }
  }

  return (
    <Modal
      centered
      show={showCrop}
      onHide={hidecrop}
      getCropImg={croppedImage}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Confirm Crop
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: '300px', height: '200px' }}>
          <Cropper
            image={URL.createObjectURL(files && files.length > 0 ? files[0] : "")}
            crop={crop}
            zoom={zoom}
            aspect={shape == "rect" ? isEvent ? 22 / 9 : 4 / 3 : 4 / 4}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            cropShape={shape ? shape : "round"}
            onZoomChange={onZoomChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" onClick={onSubmitCrop}
        >
          Submit
        </Button>
        <Button variant="secondary" onClick={hidecrop}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CropperModel