import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { employerDatails } from "../services/employerService.js";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getRankingClass } from '../utils/helpers.js';

function EmployerInfo({ employerId }) {
    const dispatch = useDispatch();
    const { employerDetailsResponse } = useSelector(state => state.employer);


    useEffect(() => {
        dispatch(employerDatails({ "employerId": employerId }))
    }, [employerId])

    return (
        <React.Fragment>
            {employerDetailsResponse && <div className="description">

                <div className="data ">
                    <h5>Company Size</h5>
                    <label>{employerDetailsResponse?.questionnaire?.companySizeDetails?.name ? employerDetailsResponse.questionnaire.companySizeDetails.name : ""}</label>
                </div>
                {employerDetailsResponse?.questionnaire?.recreationalActiveDetails?.length > 0 && <div className="data ">
                    <h5>Recreational Activities</h5>
                    <ul className="filters">
                        {employerDetailsResponse?.questionnaire?.recreationalActiveDetails.map((tag) =>
                            <li>
                                <label>{tag.name}</label>
                            </li>
                        )}
                    </ul>
                </div>}
                {employerDetailsResponse?.questionnaire?.healthActivitiesDetails?.length > 0 && <div className="data ">
                    <h5>Health Activities</h5>
                    <ul className="filters">
                        {employerDetailsResponse?.questionnaire?.healthActivitiesDetails.map((tag) =>
                            <li>
                                <label>{tag.name}</label>
                            </li>
                        )}
                    </ul>
                </div>}
                {employerDetailsResponse?.questionnaire?.professionalDevlopmentDetails?.length > 0 && <div className="data ">
                    <h5>Professional Devlopment</h5>
                    <ul className="filters">
                        {employerDetailsResponse?.questionnaire?.professionalDevlopmentDetails.map((tag) =>
                            <li>
                                <label>{tag.name}</label>
                            </li>
                        )}
                    </ul>
                </div>}

                <h4 className="sub_title">Ranking</h4>
                <div className='skill_sec'>
                    <div className="skill_body">
                        <ul>
                            <li>
                                <div className="skill_body_left">
                                    <span>Competitive Salaries</span>
                                </div>
                                <div className=" ratings-view">
                                    <p>
                                        <span
                                            className={getRankingClass(
                                                employerDetailsResponse?.questionnaire?.ranking?.competiSalaries
                                            )}
                                        ></span>
                                    </p>
                                    <label>
                                        <small>1</small>
                                        <small>2</small>
                                        <small>3</small>
                                        <small>4</small>
                                        <small>5</small>
                                    </label>
                                </div>
                            </li>

                            <li>
                                <div className="skill_body_left">
                                    <span>Work-Life Balance</span>
                                </div>
                                <div className="ratings-view">
                                    <p>
                                        <span
                                            className={getRankingClass(
                                                employerDetailsResponse?.questionnaire?.ranking?.workBalance
                                            )}
                                        ></span>
                                    </p>
                                    <label>
                                        <small>1</small>
                                        <small>2</small>
                                        <small>3</small>
                                        <small>4</small>
                                        <small>5</small>
                                    </label>
                                </div>
                            </li>

                            <li>
                                <div className="skill_body_left">
                                    <span>Health Coverage</span>
                                </div>
                                <div className="ratings-view">
                                    <p>
                                        <span
                                            className={getRankingClass(
                                                employerDetailsResponse?.questionnaire?.ranking?.healthCovrge
                                            )}
                                        ></span>
                                    </p>
                                    <label>
                                        <small>1</small>
                                        <small>2</small>
                                        <small>3</small>
                                        <small>4</small>
                                        <small>5</small>
                                    </label>
                                </div>
                            </li>

                            <li>
                                <div className="skill_body_left">
                                    <span>Retirement Benefits</span>
                                </div>
                                <div className="ratings-view">
                                    <p>
                                        <span
                                            className={getRankingClass(
                                                employerDetailsResponse?.questionnaire?.ranking?.retireBenifits
                                            )}
                                        ></span>
                                    </p>
                                    <label>
                                        <small>1</small>
                                        <small>2</small>
                                        <small>3</small>
                                        <small>4</small>
                                        <small>5</small>
                                    </label>
                                </div>
                            </li>

                            <li>
                                <div className="skill_body_left">
                                    <span>Child Care</span>
                                </div>
                                <div className="ratings-view">
                                    <p>
                                        <span
                                            className={getRankingClass(
                                                employerDetailsResponse?.questionnaire?.ranking?.childCare
                                            )}
                                        ></span>
                                    </p>
                                    <label>
                                        <small>1</small>
                                        <small>2</small>
                                        <small>3</small>
                                        <small>4</small>
                                        <small>5</small>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <div className="skill_body_left">
                                    <span>Retirement Benefits</span>
                                </div>
                                <div className="ratings-view">
                                    <p>
                                        <span
                                            className={getRankingClass(
                                                employerDetailsResponse?.questionnaire?.ranking?.retireBenifits
                                            )}
                                        ></span>
                                    </p>
                                    <label>
                                        <small>1</small>
                                        <small>2</small>
                                        <small>3</small>
                                        <small>4</small>
                                        <small>5</small>
                                    </label>
                                </div>
                            </li>

                            <li>
                                <div className="skill_body_left">
                                    <span>Stock Options</span>
                                </div>
                                <div className="ratings-view">
                                    <p>
                                        <span
                                            className={getRankingClass(
                                                employerDetailsResponse?.questionnaire?.ranking?.stockOptions
                                            )}
                                        ></span>
                                    </p>
                                    <label>
                                        <small>1</small>
                                        <small>2</small>
                                        <small>3</small>
                                        <small>4</small>
                                        <small>5</small>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {employerDetailsResponse?.tagsDetails?.length > 0 && <div className="data ">
                    <h5>Tags</h5>
                    <ul className="filters">
                        {employerDetailsResponse?.tagsDetails.map((tag) =>
                            <li>
                                <label>{tag.name}</label>
                            </li>
                        )}
                    </ul>
                </div>}
            </div>}
        </React.Fragment>

    )
}
export default EmployerInfo;