import React, { Suspense, useEffect, lazy, useState } from 'react';
import { Route, Routes as ReactRouterRoutes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userRoutes from './userRoutes';
import communityRoutes from './communityRoutes';
import talentRoutes from './talentRoutes';
import employerRoutes from './employerRoutes';
import commonRoutes from './commonRoutes';
import platformRoutes from './platformRoutes';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/slices/userSlice';
import { canAccess, getEmpCanAccess, getUserRole } from '../utils/helpers';
import Bizzell from "@/assets/images/bizzell.png";
import { showToast } from '../views/Utils';

const Login = lazy(() => import('../views/Login'));
const allRoutes = [
  ...userRoutes,
  ...communityRoutes,
  ...talentRoutes,
  ...employerRoutes,
  ...commonRoutes,
  ...platformRoutes,
];



const AppRoutes = () => {
  const [isAuthenticated, setAuthenticated] = useState(JSON.parse(localStorage.getItem('isLoggedIn')) || false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useSelector(state => state.auth)
  const { community } = useSelector(state => state.common)
  function isAuthRoutes(path) {
    return ['/login', '/public/reset-password', '/public/set-password', '/register', '/forgot-password', '/for-community' , '/for-talent', '/for-employer','/landing','/communities',
  '/privacy-policy','/terms','/california-privacy-policy','/events/:eventName/:eventId'].findIndex((item) => item == path) > -1
   
  }

  useEffect(() => {
    if (window.location.pathname.includes('invitation/') || window.location.pathname == '/public/reset-password') {
      localStorage.clear()
      dispatch(logout());
    }
    if (!isAuthenticated && !isAuthRoutes(window.location.pathname)&&!window.location.pathname.includes('invitation/')&&!window.location.pathname.includes('/events/')) {
      navigate('/');
    }

    if (isAuthenticated && window.location.pathname == "/") {
      let loginRoleId=getUserRole()
      if(loginRoleId){
        if ([1, 2].indexOf(loginRoleId) > -1) {
          navigate('/dashboard/platform');
        } else if ([3, 4].indexOf(loginRoleId) > -1) {
          // if(canAccess()){
            navigate('/dashboard/community');
          // }else{
          //   navigate('/subscribe')
          // }
        } else if ([5, 6].indexOf(loginRoleId) > -1) {
          if(data?.superAdmin){
            navigate('/');
          }
          else{
            navigate('/dashboard/employer');
          }
        }else {
          if(data?.isProfileCompleted){
            navigate('/');
          }else{
            navigate('/student/edit-profile')
          }
        }

      }

      console.log('userInfoStr',getUserRole())
    }

  }, [isAuthenticated]);
  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem('isLoggedIn')) || false)
    if(window.location.pathname.includes('/payments') && !(data?.superAdmin) ){
      navigate("*")
    }
    if(window.location.pathname.includes('/bulk-employerinvite') && (!canAccess())){
      navigate(`/subscribe/${data?.communityId}`)
    }
    if(window.location.pathname.includes('dashboard/community/members')&&(data?.roleId != 3)){
      navigate('*')
    }
    if(window.location.pathname.includes('userslist-dashboard')&&(!([1,5].indexOf(data?.roleId) > -1 ))){
      navigate('*')
    }
    if(window.location.pathname.includes('/employer/add-job') && (!canAccess())){
      if(community || data?.communityId){
        navigate(`/subscribe/${ community ? community : data?.communityId}`)
      }
      else {
        showToast("Please subscribe to a community", "error")
        navigate('/dashboard/employer')
      }
    }
    if(window.location.pathname.includes('/employer/analysis')&&(!([5].indexOf(data?.roleId) > -1 ) || !data?.superAdmin)){
      navigate('*')
    }
  }, [location]);

  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem('isLoggedIn')) || false)
  }, []);
  return (
    <Suspense fallback={<div className='page-loader'>
      <div className='loader-cnt'>
          <span class="b-loader"></span>
          <img className='loader-icon' src={Bizzell} />
      </div>                    
  </div>}>
      <ReactRouterRoutes>
        {allRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={React.createElement(route.component)} />
        )
        )}

        {/* Conditionally render the login route as the default route if the user is not authenticated */}
        {/* {!isAuthenticated && <Route path="/login" element={<Navigate to="/login" replace />} />} */}

        {!isAuthenticated&& <Route path="/login" element={<Login />} />}

        {/* Add other route groups as needed */}
        <Route path="*" element={<div>404 Not Found</div>} />
      </ReactRouterRoutes>
    </Suspense>
  );
};

export default AppRoutes;
