import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import '@/assets/styles/header.scss';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { Row, Col, Form } from 'react-bootstrap';
import FileUploader from '../../components/FileUploaderMulti';
// import {  getMasterData } from '../../services/authService';
import { profileImgReset, uploadedFileReset } from '../../redux/slices/commonSlice';
import { commonStrings } from '@/utils/strings';
import AddressFields from "../../components/addresFields";
import { sucessMessageRest } from '../../redux/slices/communitySlice';
import { getTalentMasterData } from '../../services/talentService';
// import { getCommunityDetails, updateCommunityInfo } from '../../services/communityService';
import Logo from '@/assets/images/logo.png';
import { showToast } from '../Utils';
import SimpleInput from '../../components/SimpleInput';
import SimpleSelect from '../../components/SimpleSelect';
import SimpleRadioNew from '../../components/simpleRadioNew';
import AutoSearch from '../../components/autoSearch';

function EditCommunity({ showEdit = true, handleClose, communityId = "", fileUploader,
    getMasterData, updateCommunityInfo, getCommunityDetails,backdropProp='',keyBoardProp=true }) {
    const { data } = useSelector(state => state.auth)
    const { industries, careers,communityTagList } = useSelector(state => state.talent);
    const { communityDetails, updateCommunitySuccess, updateCommunityFailure } = useSelector(state => state.community);
    const { getCityList } = useSelector(state => state.user);

    const [formData, setFormData] = useState({
        name: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        department: '',
        emailAddress: '',
        phoneNumber: '',
        line1: '',
        zipcode: "",
        country: "",
        state: "",
        city: "",
        communityType: '',
        nationalOrganization: '',
        chapterAffiliate: '',
        sourcingCommunity: '',
        promote: '',
        tags: [],
        industries: [],
        locations: [],
        careerLevels: [],
        orgImages: [],
        description: []
    })
    const { handleSubmit, formState: { errors }, register, control, reset, clearErrors, setValue,getValues } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState("step1")
    const [tag, setTag] = useState("")
    const [tags, setTags] = useState([])
    const navigateToLogin = useCallback(() => {
        navigate('/login');
    }, [navigate]);

    useEffect(() => {
        dispatch(getCommunityDetails({
            communityId: communityId ? communityId : data?.communityId
        }))
        dispatch(getTalentMasterData({
            category: 'career_levels'
        }))
        dispatch(getTalentMasterData({
            category: 'industries'
        }))
        // dispatch(getMasterData({ category: "all_locations" }));
        // dispatch(getTalentMasterData({ category: "community_tags" }));

    }, [communityId])
    useEffect(() => {
        let details = {
            logo: communityDetails?.logo ? communityDetails.logo : "",
            name: communityDetails?.name ? communityDetails.name : "",
            line1: communityDetails?.address?.line1 ? communityDetails.address.line1 : "",
            zipcode: communityDetails?.address?.zipcode ? communityDetails.address.zipcode : "",
            country: communityDetails?.address?.countryDetails ? communityDetails.address.countryDetails : "",
            state: communityDetails?.address?.stateDetails ? communityDetails.address.stateDetails : "",
            city: communityDetails?.address?.locationDetails ? communityDetails.address.locationDetails : "",
            communityType: (communityDetails?.communityType == 0) ? "Open" : (communityDetails?.communityType == 1) ? "Hidden" : "",
            firstName: communityDetails?.adminFirstName ? communityDetails.adminFirstName : "",
            lastName: communityDetails?.adminLastName ? communityDetails.adminLastName : "",
            jobTitle: communityDetails?.jobTitle ? communityDetails.jobTitle : "",
            emailAddress: communityDetails?.adminEmail ? communityDetails.adminEmail : "",
            phoneNumber: communityDetails?.phone ? communityDetails.phone : "",
            nationalOrganization: communityDetails?.nationalOrganization == true ? "Yes" : "No",
            chapterAffiliate: communityDetails?.chapterAffiliate == true ? "Yes" : "No",
            sourcingCommunity: communityDetails?.sourcingCommunity == true ? "Yes" : "No",
            promote: communityDetails?.promote == true ? "Yes" : "No",
            tags: communityDetails?.tagsDetails ? communityDetails.tagsDetails : [],
            industries: communityDetails?.industriesDetails ? communityDetails.industriesDetails : [],
            locations: communityDetails?.orgLocationDetails ? communityDetails.orgLocationDetails : [],
            careerLevels: communityDetails?.careerLevelDetails ? communityDetails.careerLevelDetails : [],
            description: communityDetails?.description ? communityDetails.description : "",
            orgImages: communityDetails?.orgImages ? communityDetails.orgImages : ""

        }
        setValue('logo', communityDetails?.logo ? communityDetails.logo : "")
        setValue('name', communityDetails?.name ? communityDetails.name : "")
        setValue('line1', communityDetails?.address?.line1 ? communityDetails.address.line1 : "")
        setValue('zipcode', communityDetails?.address?.zipcode ? communityDetails.address.zipcode : "")
        setValue('country', communityDetails?.address?.countryDetails ? communityDetails.address.countryDetails : "")
        setValue('state', communityDetails?.address?.stateDetails ? communityDetails.address.stateDetails : "")
        setValue('city', communityDetails?.address?.locationDetails ? communityDetails.address.locationDetails : "")
        setValue('communityType', (communityDetails?.communityType == 0) ? "Open" : (communityDetails?.communityType == 1) ? "Hidden" : "")
        setValue('firstName', communityDetails?.adminFirstName ? communityDetails.adminFirstName : "")
        setValue('lastName', communityDetails?.adminLastName ? communityDetails.adminLastName : "")
        setValue('jobTitle', communityDetails?.jobTitle ? communityDetails.jobTitle : "")
        setValue('emailAddress', communityDetails?.adminEmail ? communityDetails.adminEmail : "")
        setValue('phoneNumber', communityDetails?.phone ? communityDetails.phone : "")
        setValue('nationalOrganization', communityDetails?.nationalOrganization == true ? "Yes" : "No",)
        setValue('chapterAffiliate', communityDetails?.chapterAffiliate == true ? "Yes" : "No",)
        setValue('sourcingCommunity', communityDetails?.sourcingCommunity == true ? "Yes" : "No",)
        setValue('promote', communityDetails?.promote == true ? "Yes" : "No",)
        setValue('tags', communityDetails?.tagsDetails ? communityDetails.tagsDetails : [])
        setValue('industries', communityDetails?.industriesDetails ? communityDetails.industriesDetails : [])
        setValue('locations', communityDetails?.orgLocationDetails ? communityDetails.orgLocationDetails : [])
        setValue('careerLevels', communityDetails?.careerLevelDetails ? communityDetails.careerLevelDetails : [])
        setValue('description', communityDetails?.description ? communityDetails.description : "")
        setValue("orgImages", communityDetails?.orgImages ? communityDetails.orgImages : "")
       // setTags(communityDetails?.tags ? communityDetails?.tags : [])
        setFormData((prev) => ({
            ...prev,
            ...details
        }))
        setValue("communityType", (communityDetails?.communityType == 0) ? "Open" : (communityDetails?.communityType == 1) ? "Hidden" : "")
    }, [communityDetails])

    const handleFileChange = (files) => {
        // alert("parent")
        setFormData((p) => ({...p,
            ["orgImages"]: files
        }))
    }
//   console.log("files ------------ ",JSON.stringify(formData?.orgImages))
    function onFinalSubmit(data) {
        let payLoad = {
            "name": data.name,
            "email": data.emailAddress,
            "phone": data.phoneNumber,
            "roleId": 3,
            "jobtitle": data.jobTitle,
            "address": {
                "line1": data.line1,
                "zipcode": data.zipcode,
                "stateId": data.state?.id,
                "countryId": data.country?.id,
                "locationId": data.city?.id,
                countryDetails:  data?.country,
                stateDetails: data?.state,
                locationDetails:  data?.city,
            },
            "communityType": data.communityType == "Open" ? 0 : data?.communityType == "Hidden" ? 1 : "",
            nationalOrganization: data.nationalOrganization == "Yes" ? true : false,
            chapterAffiliate: data.chapterAffiliate == "Yes" ? true : false,
            sourcingCommunity: data.sourcingCommunity == "Yes" ? true : false,
            promote: data.promote == "Yes" ? true : false,
            tags: data.tags?.map((i) => i.id),
            industries: data?.industries?.map((i) => i.id),
            locations: data?.locations?.map((i) => i.id),
            careerLevels: data?.careerLevels?.map((i) => i.id),
            orgImages: formData?.orgImages ? formData.orgImages : [],
            description: data?.description,
            industriesDetails: data?.industries,
            orgLocationDetails: data?.locations,
            careerLevelDetails: data?.careerLevels,
            tagsDetails: data.tags,
        }
        payLoad["adminFirstName"] = data.firstName
        payLoad["adminLastName"] = data.lastName
        payLoad["communityId"] = communityDetails?._id
        payLoad["password"] = data?.password
        dispatch((updateCommunityInfo(payLoad)))
    }

    const onErrors = (errors) => {
        console.log("errors are -----------", errors)
    }
    const handleRadioChange = (e) => {
        setFormData((previous) => ({
            ...previous,
            [e.target.name]: e.target.value
        }))
    }

    const handleTagChange = (e) => {
        e.preventDefault()
        if (e.target.value) {
            setTag(e.target.value)
        }
    }
    // console.log("----parent parentparentparentparentparentparent ",formData)

    // useEffect(() => {
    
    //    },[formData])
    const handleKeyDown = (e) => {
        if (e.key == 'Enter' && e.target.value && e.target.name == "tag") {
            setTags((p) => [...p, e.target.value])
            setTimeout(() => {
                setTag("")
                setValue('tag', "")
            }, 100)
            e.preventDefault()
        } else {
            if (e.target.value) {
                setTag(e.target.value)
            }
        }
    }

    const onRemoveTag = (t) => {
        setTags((p) => p.filter((tag) => tag != t))
    }
    const formatOptionLabel = (option) => {
        return option.name;
    }
    const handleInputChange = (event) => {
        //  setFormData({ ...formData, [fieldName]: event.target.value });
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value) {
            clearErrors(name);
        }
    };
    const onhandleSelect = () => {
        console.log("selected --")
    }
    const handleOptionAdded = (category, addedData, fieldName) => {
        if (category) {
            let formData = getValues()
            let addedValue = [addedData]
            let existingOptions = formData?.[fieldName] ? formData[fieldName] : []
            let resultTags = [...existingOptions, ...addedValue]
          
            setTimeout(() => {
              dispatch(getTalentMasterData({ category: category }));
              setFormData((prevState) => ({
                ...prevState,
                [fieldName]:resultTags
              }));
              setValue(fieldName, resultTags)
            })
      
          }
        // if (category == 'community_tags') {
        //   let formData = getValues()
        //   let addedValue = [addedData]
        //   let communityTags = formData?.tags ? formData.tags : []
        //   let resultTags = [...communityTags, ...addedValue]
        //   dispatch(getTalentMasterData({ category: "community_tags" }));
        //   setTimeout(() => {
        //     setFormData((prevState) => ({
        //       ...prevState,
        //       ['tags']:resultTags
        //     }));
        //     setValue("tags", resultTags)
        //   })
    
        // }
      }

      const handleCitySelect = (selectedCity, fieldName) => {
        // let prevOptions = formData?.[fieldName]?.length > 0 ? formData[fieldName] : []
        // console.log("prevOptions ", prevOptions)
        setFormData((prev) => ({
            ...prev,
            [fieldName]: [ ...selectedCity]
        }))
    };
    return (
        showEdit && <Offcanvas className="community_modal" show={showEdit} onHide={handleClose} placement='end'
        backdrop={backdropProp}
        keyboard={keyBoardProp}>
            {backdropProp ? 
            <Offcanvas.Header className='offcanvas-header-req'>
            <Offcanvas.Title>Community Info</Offcanvas.Title>
              </Offcanvas.Header> : <Offcanvas.Header closeButton className='offcanvas-header-req'>
          <Offcanvas.Title>Community Info</Offcanvas.Title>
            </Offcanvas.Header>}
            <Offcanvas.Body>
                <Form className='pad20'>
                    <Row>
                        <Col>
                            <SimpleInput
                                label={commonStrings.Community + " Name"}
                                disabled={false}
                                type='text'
                                inputValue={formData?.name ? formData.name : ""}
                                onInputChange={handleInputChange}
                                errors={errors}
                                register={register}
                                isRequired={true}
                                errorText={commonStrings.Community + " Name"}
                                fieldName={'name'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SimpleInput
                                label='First Name'
                                type='text'
                                disabled={false}
                                inputValue={formData?.firstName ? formData.firstName : ""}
                                onInputChange={handleInputChange}
                                errors={errors}
                                register={register}
                                isRequired={true}
                                errorText={"First Name"}
                                fieldName={'firstName'}
                            />
                        </Col>
                        <Col>
                            <SimpleInput
                                label='Last Name'
                                type='text'
                                disabled={false}

                                inputValue={formData?.lastName ? formData.lastName : ""}
                                onInputChange={handleInputChange}
                                errors={errors}
                                register={register}
                                isRequired={true}
                                errorText={"Last Name"}
                                fieldName={'lastName'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SimpleInput
                                className="email"
                                label='Email Address'
                                type='email'
                                disable={true}
                                inputValue={formData?.emailAddress ? formData.emailAddress : ""}
                                onInputChange={handleInputChange}
                                errors={errors}
                                register={register}
                                isRequired={true}
                                errorText={"Email"}
                                fieldName={'emailAddress'}
                            />
                        </Col>
                        <Col>
                            <SimpleInput
                                className="phone"
                                label='Phone Number'
                                type='number'
                                inputValue={formData.phoneNumber}
                                onInputChange={handleInputChange}
                                errors={errors}
                                register={register}
                                isRequired={false}
                                errorText={"Phone Number"}
                                fieldName={'phoneNumber'}
                            />
                        </Col>
                    </Row>
                    <AddressFields errors={errors}
                        register={register}
                        control={control}
                        streetAddressErrorText={"Street Address"}
                        streetAddressFieldName={"line1"}
                        streetDefaultValue={formData?.line1}
                        countryFieldName={"country"}
                        countryErrorText={"Country"}
                        countryDefaultValue={formData?.country}
                        stateFieldName={"state"}
                        stateErrorText={"State"}
                        stateDefaultValue={formData?.state}
                        cityFieldName={"city"}
                        cityErrorText={"City"}
                        cityDefaultValue={formData?.city}
                        zipFieldName={"zipcode"}
                        zipErrorText={"Zipcode"}
                        zipcodeDefaultValue={formData?.zipcode}
                    />
                    <Row>
                        <Col>
                            <div className='form-group radio_group'>
                                <SimpleRadioNew
                                    yesValue={"Yes"}
                                    noValue={"No"}
                                    label={"Do you represent the national organization? "}
                                    errors={errors}
                                    register={register}
                                    onRadioChange={handleRadioChange}
                                    defaultCheck={formData?.nationalOrganization}
                                    isRequired={false}
                                    errorText={"National Organization or not"}
                                    fieldName={'nationalOrganization'}
                                    control={control}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='form-group radio_group'>
                                <SimpleRadioNew
                                    yesValue={"Yes"}
                                    noValue={"No"}
                                    label={"Are you a chapter affiliate?"}
                                    errors={errors}
                                    register={register}
                                    onRadioChange={handleRadioChange}
                                    defaultCheck={formData?.chapterAffiliate}
                                    isRequired={false}
                                    errorText={"Chapter Affiliate or not"}
                                    fieldName={'chapterAffiliate'}
                                    control={control}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='form-group radio_group'>
                                <SimpleRadioNew
                                    yesValue={"Yes"}
                                    noValue={"No"}
                                    label={"Do you want to create a matchplicity sourcing community? "}
                                    errors={errors}
                                    register={register}
                                    onRadioChange={handleRadioChange}
                                    defaultCheck={formData?.sourcingCommunity}
                                    isRequired={false}
                                    errorText={"Sourcing Community or not"}
                                    fieldName={'sourcingCommunity'}
                                    control={control}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='form-group radio_group'>
                                <SimpleRadioNew
                                    yesValue={"Hidden"}
                                    noValue={"Open"}
                                    label={"Do you want your professional community hidden or open to receive new members?"}
                                    errors={errors}
                                    register={register}
                                    onRadioChange={handleRadioChange}
                                    defaultCheck={formData?.communityType}
                                    isRequired={false}
                                    errorText={"Community Type"}
                                    fieldName={'communityType'}
                                    control={control}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='form-group radio_group'>
                                <SimpleRadioNew
                                    yesValue={"Yes"}
                                    noValue={"No"}
                                    label={"Do you have events to promote on matchplicity?"}
                                    errors={errors}
                                    register={register}
                                    onRadioChange={handleRadioChange}
                                    defaultCheck={formData?.promote}
                                    isRequired={false}
                                    errorText={"Event Promotion status"}
                                    fieldName={'promote'}
                                    control={control}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <SimpleInput
                                label={'Select your tags'}
                                // disabled={true}
                                type='text'
                                handleKeyDown={handleKeyDown}
                                inputValue={tag}
                                onInputChange={handleTagChange}
                                errors={errors}
                                register={register}
                                isRequired={false}
                                errorText={"Tags"}
                                fieldName={'tag'}
                                placeholder={'Add Tags'}
                                toolTipInfo={`select the tags are searchable by - employers + talent`}
                            />
                            {tags?.length > 0 && <ul className='tags__list'>
                                {tags?.map((tag) => <li>{tag}<em onClick={() => onRemoveTag(tag)}></em></li>)}
                            </ul>} */}
                            {/* <SimpleSelect
                                label={'Select your tags'}
                                options={communityTagList}
                                isMulti={true}
                                placeholder={'Add Tags'}
                                onInputChange={onhandleSelect}
                                formatOptionLabel={formatOptionLabel}
                                errors={errors}
                                register={register}
                                isRequired={false}
                                errorText={"Tags"}
                                fieldName={'tags'}
                                className={'tags'}
                                control={control}
                                onOptionAdded={handleOptionAdded}
                                masterCate={'community_tags'}
                            /> */}
                             <AutoSearch
                                options={communityTagList}
                                onSelectCity={handleCitySelect}
                                label='Search your tags'
                                isMulti={true}
                                placeholder={'Add Tags'}
                                value={formData?.tags}
                                errors={errors}
                                register={register}
                                isRequired={false}
                                errorText={"Tags"}
                                fieldName={'tags'}
                                control={control}
                                type={""}
                                mdCategory='community_tags'
                                onOptionAdded={handleOptionAdded}
                                freeSolo={true}
                                defaultValue={formData?.tags}
                            />
                        </Col>
                    </Row>
                    <span className='sub_title'>Enter your membership demographics</span>
                    <Row>
                        <Col>
                            <SimpleSelect
                                id={'industries'}
                                label='Industries represented by your membership'
                                options={industries || []}
                                isMulti={true}
                                placeholder={'Search Industries'}
                                onInputChange={onhandleSelect}
                                formatOptionLabel={formatOptionLabel}
                                errors={errors}
                                register={register}
                                isRequired={false}
                                errorText={"Industries"}
                                fieldName={'industries'}
                                control={control}
                                toolTipInfo={''}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AutoSearch
                                options={getCityList}
                                onSelectCity={handleCitySelect}
                                label='Location(s) of your organization'
                                isMulti={true}
                                placeholder={'Search Locations'}
                                value={formData?.locations}
                                errors={errors}
                                register={register}
                                isRequired={false}
                                errorText={"Locations"}
                                fieldName={'locations'}
                                control={control}
                                type={"cities"}
                                mdCategory='all_locations'
                                // onOptionAdded={handleOptionAdded}
                                freeSolo={false}
                                defaultValue={formData?.locations}
                            />
                            {/* <SimpleSelect
                                id={'locations'}
                                label='Location(s) of your organization'
                                options={getCityList || []}
                                isMulti={true}
                                placeholder={'Search Locations'}
                                onInputChange={onhandleSelect}
                                formatOptionLabel={formatOptionLabel}
                                errors={errors}
                                register={register}
                                isRequired={false}
                                errorText={"Locations"}
                                fieldName={'locations'}
                                control={control}
                                toolTipInfo={''}
                                type={"state_name"}
                            /> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SimpleSelect
                                id={'careerLevels'}
                                label='Career levels of your members'
                                options={careers}
                                isMulti={true}
                                placeholder={'Select'}
                                onInputChange={onhandleSelect}
                                formatOptionLabel={formatOptionLabel}
                                errors={errors}
                                register={register}
                                isRequired={false}
                                errorText={"Career Levels"}
                                fieldName={'careerLevels'}
                                control={control}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SimpleInput
                                label={"Description"}
                                disabled={false}
                                type='textarea'
                                inputValue={formData?.description}
                                onInputChange={handleInputChange}
                                errors={errors}
                                register={register}
                                isRequired={true}
                                errorText={"Description"}
                                fieldName={'description'}
                            />
                        </Col>
                    </Row>
                    <span className='sub_title'>Upload Photos</span>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label className='form-labels'>File Upload</label>
                                <FileUploader
                                    type='profileImg'
                                    fileUploader={fileUploader}
                                    fieldName={'orgImages'}
                                    accepts={['image/*']}
                                    isRequired={false}
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    errorText="Organization Images"
                                    localupload={false}
                                    defaultValue={formData?.orgImages ? formData.orgImages : []}
                                    onFilesUploaded={(data)=>handleFileChange(data)}
                                    //  onFileRemoved={handleFileRemove}
                                    shape={'rect'}
                                    multiple={true}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant='primary'  onClick={handleSubmit(onFinalSubmit, onErrors)} className='w-auto ms-auto'>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    )

}
export default EditCommunity;