import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Controller } from 'react-hook-form';
import { checkProperty } from "../utils/helpers"
import { commonStrings } from '../utils/strings';
function SimpleRadioNew({ fieldName, isRequired, errors, errorText, control, id, defaultCheck, onRadioChange, label, yesValue, noValue }) {
    return (
        <React.Fragment>
            {label && <Form.Label className="form-labels">{isRequired ? label + "*" : label}</Form.Label>}
            <div className="custom-radio">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={defaultCheck}
                    rules={{ required: isRequired }}
                    render={({ field }) => (
                        <>
                            <Form.Check
                                label={yesValue == "Student" ? "Student Jobseeker" : yesValue}
                                name={field.name}
                                type="radio"
                                id={fieldName + "yes"}
                                value={yesValue}
                                checked={defaultCheck === yesValue ? true : false}
                                onChange={(event) => {
                                    console.log(event)
                                    field.onChange(event);
                                    onRadioChange(event);
                                }}
                            />
                            <Form.Check
                                label={noValue == "Professional" ? "Professional Jobseeker" : noValue}
                                name={field.name}
                                type="radio"
                                id={fieldName + "no"}
                                value={noValue}
                                checked={defaultCheck === noValue ? true : false}
                                onChange={(event) => {
                                    field.onChange(event);
                                    onRadioChange(event);
                                }}
                            />
                        </>
                    )}
                />
                   
                {errors && fieldName && checkProperty(fieldName.split('.'), 'length') > 2 &&
                    checkProperty(errors, fieldName.split('.')[0], fieldName.split('.')[1]) &&
                    checkProperty(errors[fieldName.split('.')[0]][fieldName.split('.')[1]], fieldName.split('.')[2], 'type') === "required" &&
                    <span className='form-error'>{"* " + errorText + ' is reqired'}</span>}

{errors && fieldName && checkProperty(fieldName.split('.'), 'length') > 1 &&
                    checkProperty(errors, fieldName.split('.')[0], fieldName.split('.')[1]) &&
                    checkProperty(errors[fieldName.split('.')[0]], fieldName.split('.')[1], 'type') === "required" &&
                    <span className='form-error'>{"* " + errorText + ' is reqired'}</span>}
           
{errors[fieldName] && (
                    <span className="form-error">{"* " + errorText} is required</span>
                )}
            </div>
        </React.Fragment>
    );
}

export default SimpleRadioNew;
