import React, { useState, useEffect, useCallback } from "react";
import Files from "react-files";
import "@/assets/styles/fileUpload.scss";
import Cloud from "@/assets/images/icons/cloud.svg";
import { useDispatch,useSelector } from "react-redux";
//import {uploadFile} from "../services/authService"
import DefaultProfile from "@/assets/images/icons/profile-icon.svg";
import { resumeReset } from "../redux/slices/talentSlice";
import Cropper from 'react-easy-crop';
import CropperModel from "../views/CropperModel";
import closeIcon from "@/assets/images/icons/delete.svg";



function FileUploader({
  fileUploader,
  accepts,
  register,
  defaultImg,
  fieldName,
  isRequired,
  id,
  control,
  errorText,
  errors,
  handleSelectedFile,
  type,
  localupload,
  onFilesUploaded,
  defaultValue,
  onFileRemoved,
  onInputChange,
  key,
  cropRequired=true,
  shape,
  multiple=false,
  isEvent=false,
  isPublic=false
}) {
  const { ProfileImg } = useSelector(state => state.common)
  const [files, setFiles] = useState([]);
  const [profileImg, setProfileImg] = useState("");
  //const [validationRequired, setValidationRequired] = useState(isRequired);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCrop, setShowCrop] = useState(true);
  const [fileLoader, setFileLoader] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (file) => {
    setShowCrop(true)
    console.log("file???", file)
   
    if (type === "profileImg") {
      setProfileImg(file)
    } else {
      setFileLoader(true)
      setFiles(file);
    }

    dispatch(resumeReset())
    const formData = new FormData();
    file.forEach((fileItem) => {
      formData.append("files", fileItem);
    });
    formData.append("secureType", ((type === "profileImg")||isPublic )? "public": "private");
    formData.append("getDetails", true); // Send "true" when required ext, mimetype and name as well
    if (localupload === true) {
      setFileLoader(false)
    } else {
      dispatch(fileUploader({ formData: formData, type: type }));
      setFileLoader(false)
    }
  };
 
  const handleFileRemove = (fileId) => {
    setFiles((prevFiles) => type === "profileImg" ? [] : prevFiles.filter((prevFile) => prevFile?.id !== fileId));

    
    if (onFileRemoved) {
      onFileRemoved(files);
    }
    if (files.length === 0) {
      // setValidationRequired(true)
    }
  };

  const handleCropChange = (file) => {
    setFileLoader(true)
  };

  useEffect(() => {
    setFileLoader(false)
    if (ProfileImg) {
      let fielObj = {
        preview: {
          url: ProfileImg
        }
      }
      setFiles([fielObj]);
    }
  }, [ProfileImg]);

  useEffect(() => {
    if (
      files.length > 0 &&
      onFilesUploaded &&
      typeof onFilesUploaded === "function"
    ) {
      onFilesUploaded(files);
    }
  }, [files]);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      setFiles(defaultValue);
    }
  }, [defaultValue]);
  useEffect(() => {
    if (key) {
      setFiles([])
    }
  }, [key]);


  const onHideCrop = () => {
    setShowCrop(false)
  }
  return (
    <div className="fileuploader">
      <Files
        className="files-dropzone-list"
        dragActiveClassName="files-dropzone-active"
        style={{ height: "100px" }}
        onChange={handleChange}
        maxFiles={multiple?15:1}
        accepts={accepts}
        control={control}
        maxFileSize={10000000}
        minFileSize={0}
        multiple={multiple?multiple:false}
      //  {...register(fieldName, { required: validationRequired, })}
      >
        <img src={Cloud} alt="Files Upload" />
        Drag and Drop or<span>Browse</span>
      </Files>
      {fileLoader && <div className="cnt_loader">
        <span class="b-loader"></span>
      </div>}
      {cropRequired && type === "profileImg" && profileImg.length > 0 && (
        <div>
          <CropperModel
            showCrop={showCrop}
            hidecrop={onHideCrop}
            files={profileImg}
            onCropSubmitt={handleCropChange}
            fileUploader={fileUploader}
            shape={shape}
            isEvent={isEvent}
          />
        </div>
      )}

      {defaultImg && files.length === 0 && <img className="preview-img" src={defaultImg ? defaultImg : DefaultProfile} alt="" />}
      {files.length > 0 && (
        <div className="files-list">
          <ul>
            {files?.map((file) => (
              <React.Fragment>
                {type !== "profileImg" && (
                  file?.name && <li key={file.id} className="files-list-item">
                    <span className="filename">{file.name}</span>
                    <span
                      className="file-delete"
                      onClick={() => handleFileRemove(file.id)}
                    ></span>
                  </li>
                )}
                {type === "profileImg" && <>
                <li className="upload__img">
                  <button type="button" className="remove_btn"  onClick={() => handleFileRemove(file)}>
                    <img src={closeIcon} alt="close"/>
                  </button>
                  <figure>
                    <img className="preview-img" src={file?.preview?.url ? file?.preview?.url : DefaultProfile} alt="" />
                  </figure>
                </li>
                </> }
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
      {/* {files.length===0&&isRequired&&errors?.[fieldName] && errors?.[fieldName].type && errors?.[fieldName].type === "required" &&
        <span>{errorText + ' is reqired'}</span>} */}
      {/* {errors[fieldName] &&isRequired&& (
        <span className='form_error'>{errors[fieldName].message}</span>
        )}   */}
    </div>
  );
}
export default FileUploader;
