
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    data: null,
    isAuthenticated: false,
    loading: false,
    error: null,
    success: null,
    userDetails:"",
    registerSuccess: "",
    rgisterFailure: "",
    getCountryData:[],
    getStateList:[],
    getCityList:[],
    usersList: [],
    bulkInvSuccess: '',
    bulkInviteFailure: '',
    statusUpdateSuccess: '',
    statusUpdateFailure: '',
    activationLinkResent: '',
    activationLinkNotsent: '',
    changePwdSuccess: '',
    changePwdFailure: '',
    bulkInviteList: [],
    dashboardStats: '',
    botMessages: []
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginFailure: (state, action) => {
            state.data = null;
            state.isAuthenticated = false;
            state.loading = false;
            state.error = action.payload;
        },
        forgotPasswordRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        forgotPasswordSuccess: (state, action) => {
            //alert(action)
            state.success = action.payload;
            state.isAuthenticated = false;
            state.loading = false;
            state.error = null;
        },
        forgotPasswordFailure: (state, action) => {
            state.data = null;
            state.isAuthenticated = false;
            state.loading = false;
            state.error = action.payload;
        },
        resetSuccess: (state, action) => {
            state.success = action.payload;
            state.isAuthenticated = true;
            state.loading = false;
            state.error = null;
        },
        resetFailure: (state, action) => {
            console.log(action.payload)
            state.data = null;
            state.isAuthenticated = false;
            state.loading = false;
            state.error = action.payload;
        },
        logout: (state) => {
            state.data = null;
            state.isAuthenticated = false;
            state.loading = false;
            state.error = null;
        },
        registerSuccess: (state,action) => {
            state.registerSuccess = action.payload;
            state.loading = false;
            state.registerFailure = null;
        },
        registerFailure: (state,action) => {
            state.registerSuccess = null;
            state.loading = false;
            state.registerFailure = action.payload
            state.getCityList=[]
            state.getStateList=[]
        },
        userDetailsSuccess: (state,action) => {
            state.userDetails =  action.payload;
        },
        userDetailsFailure: (state,action) => {
            state.userDetails =  "";
        },
        countryData: (state,action) => {
            state.getCountryData =  action.payload;
        },
        stateData: (state,action) => {
            state.getStateList =  action.payload;
        },
        cityData: (state,action) => {
            state.getCityList =  action.payload;
        },
        bulkInviteRequest: (state) => {
            state.loading = true;
        },
        bulkInviteSuccess: (state,action) => {
            state.bulkInvSuccess = action.payload;
            state.loading = false;
        },
        bulkInviteFailure: (state,action) => {
            state.bulkInviteFailure = action.payload;
            state.loading = false;
        },
        resetError: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success=null
        },
        userStatusUpdateSuccess: (state, action) => {
            state.statusUpdateSuccess = action.payload
            state.statusUpdateFailure = ""
        },
        userStatusUpdateFailure: (state, action) => {
            state.statusUpdateSuccess = ""
            state.statusUpdateFailure = action.payload
        },
        resendActivationLinkSuccess: (state, action) => {
            state.activationLinkResent = action.payload
            state.activationLinkNotsent = ""
        },
        resendActivationLinkFailure: (state, action) => {
            state.activationLinkResent = ""
            state.activationLinkNotsent = action.payload
        },
        changePasswordSuccess: (state, action) => {
            state.changePwdSuccess =  action.payload
        },
        changePasswordFailure: (state, action) => {
            state.changePwdFailure =  action.payload
        },
        userToastReset: (state) => {
            state.changePwdFailure = ""
            state.changePwdSuccess =  ""
            state.activationLinkNotsent = ""
            state.activationLinkResent = ""
            state.statusUpdateSuccess = ""
            state.statusUpdateFailure = ""
            state.bulkInvSuccess=''
            state.bulkInviteFailure=''
        },//resetStateList
        setBulkInviteList: (state,action) => {
            state.bulkInviteList = action.payload
            state.loading = false
        },
        resetStateList: (state,action) => {
            state.getStateList = []
           // state.loading = false
        },
        setDashboardStats: (state, action) => {
            state.dashboardStats = action.payload
        },
        setChatMessages: (state, action) => {
            state.botMessages = action.payload
        }

    }
});

export const { resetStateList,  loginFailure, forgotPasswordRequest,forgotPasswordSuccess,forgotPasswordFailure, logout,resetSuccess,resetFailure, registerSuccess, registerFailure,userDetailsSuccess,userDetailsFailure,countryData ,stateData,cityData,resetError,
    bulkInviteSuccess, bulkInviteFailure, bulkInviteRequest,userStatusUpdateSuccess,userStatusUpdateFailure, resendActivationLinkSuccess,
    resendActivationLinkFailure, changePasswordSuccess, changePasswordFailure, userToastReset, setBulkInviteList,setDashboardStats,
    setChatMessages} = userSlice.actions;
export default userSlice.reducer;
