
import { createSlice } from '@reduxjs/toolkit';
import { logoutAction } from './../userActions';

export const initialState = {
    data: null,
    isAuthenticated: false,
    getCountryData:[],
    isProfileCompleted:false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        authSuccess: (state, action) => {
            state.data = action.payload;
            state.isAuthenticated = true;
            state.loading = false;
            state.error = null;
        },
        updatePaymentDone: (state, action) => {
            state.data = action.payload
        },
        studentProfileCompleted: (state) => {
            state.isProfileCompleted=true
        },
        resetIsAuthenticated: (state, action) => {
            state.isAuthenticated = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(logoutAction, (state) => {
                state.data = null;
                state.isAuthenticated = false;
                state.loading = false;
                state.error = null;
            });
    },
    // studentProfileCompleted: (state) => {
    //     state.isProfileCompleted=true
    // }
});

export const { authRequest, authSuccess,studentProfileCompleted, updatePaymentDone,
resetIsAuthenticated} = authSlice.actions;
export default authSlice.reducer;
