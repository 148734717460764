import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import '@/assets/styles/header.scss';
import Logo from "@/assets/images/logo.png";

import Form from 'react-bootstrap/Form';



import profilePicIcon from "@/assets/images/main/profile_pic.png";
import SimpleInput from '../../components/SimpleInput';
import SimpleSelect from '../../components/SimpleSelect';
import appleLogo from "@/assets/images/apple_logo.png";

import DefaultProfile from "@/assets/images/icons/profile-icon.svg";
//import SimpleInput from '../components/SimpleInput'; 

import { useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap';
import { showToast } from "../Utils"
import { resetCommunityDetails, sucessMessageRest, userMessageRest } from "../../redux/slices/communitySlice"
import { profileImgReset } from "../../redux/slices/commonSlice"
import { employerSucessMessageRest } from "../../redux/slices/employerSlice"
import EditProfile from '../editProfile/editProfile';
import { getUsAddress, getUserId, getUserRole } from '../../utils/helpers';
import { commonStrings } from '../../utils/strings';
import EditCommunity from '../Community/editCommunity';
import MoreDetails from '../communityProfile';
import EmployerDetails from '../employerProfile';
import CommunityDisplay from '@/assets/images/landing/community_image.png';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

function ProfileDetails({ showProfileModal, onHide, props }) {
    const { data } = useSelector(state => state.auth)
    const { updateCommunitySuccess, updateCommunityFailure, updateUserFailure, updateUserSuccess, communityDetails, userDetails } = useSelector(state => state.community);
    const { employerUpdateFailure, employerUpdateSuccess, employerDetailsResponse } = useSelector(state => state.employer);
    const dispatch = useDispatch();
    const { clearErrors } = useForm();
    const [showProfilePopup, setShowProfilePopup] = useState(false);
    const [isUserUpdate, setIsUserUpdate] = useState(false);
    const [showEditCommunity, setShowCommunityEdit] = useState(false)
    let isInitialRender = false

    useEffect(() => {
        if (updateCommunitySuccess) {
            showToast(updateCommunitySuccess, 'success');
            handleCommunityClose()
            closeProfilePopup()
            clearErrors()
            dispatch(sucessMessageRest())
            dispatch(profileImgReset())
            dispatch(props.getCommunityDetails({ communityId: data?.communityId ? data.communityId : "" }))

        }
        if (updateCommunityFailure) {
            showToast(updateCommunityFailure, 'error');
            dispatch(sucessMessageRest())

        }
    }, [updateCommunitySuccess, updateCommunityFailure]);
    useEffect(() => {
        if (employerUpdateSuccess) {
            // showToast(employerUpdateSuccess, 'success');
            closeProfilePopup()
            clearErrors()
            dispatch(employerSucessMessageRest())
            dispatch(profileImgReset())
            dispatch(props.employerDatails({ "employerId": data?.employerId }))
        }
        if (employerUpdateFailure) {
            showToast(updateCommunityFailure, 'error');
            dispatch(employerSucessMessageRest())

        }
    }, [employerUpdateFailure, employerUpdateSuccess]);
    useEffect(() => {
        if (updateUserSuccess) {
            showToast(updateUserSuccess, 'success');
            closeProfilePopup()
            clearErrors()
            dispatch(userMessageRest())
            dispatch(profileImgReset())
            dispatch(props.userDetails({ "detailsId": data?.detailsId }));
        }
        if (updateUserFailure) {
            showToast(updateUserFailure, 'error');
            dispatch(userMessageRest())

        }
    }, [updateUserSuccess, updateUserFailure]);
    useEffect(() => {
        if (!isInitialRender) {
            isInitialRender = true
            dispatch(props.userDetails({ "detailsId": data?.detailsId }));
            if ([3, 4].indexOf(getUserRole()) > -1) {
                dispatch(props.getCommunityDetails({ communityId: data?.communityId ? data.communityId : "" }))
            }
            if ([5, 6].indexOf(getUserRole()) > -1) {
                dispatch(props.employerDatails({ "employerId": data?.employerId }))
            }
        }

    }, [data]);
    const showEditProfile = (type) => {
        if (type === "user") {
            setIsUserUpdate(true)
            setShowProfilePopup(true)
            setShowCommunityEdit(false)

        } else {
            if(type == "community"){
                dispatch(resetCommunityDetails())
                setIsUserUpdate(false)
                setShowProfilePopup(false)
                setShowCommunityEdit(true)
            }
            else{
            setShowCommunityEdit(false)
            setIsUserUpdate(false)
            setShowProfilePopup(true)
            }
        }
    }
    const closeProfilePopup = () => {
        setShowProfilePopup(false)
        setIsUserUpdate(prevValue => !prevValue)
    }

    const handleCommunityClose = () => {
        setShowCommunityEdit(false)
    }
    return (
        <>
            <React.Fragment>
                {showProfilePopup && (!showEditCommunity)  &&
                    <EditProfile
                        showProfilePopup={showProfilePopup}
                        isUserUpdate={isUserUpdate}
                        title={"Update Details"}
                        closeProfilePopup={closeProfilePopup}
                        props={props}
                    />}
                    {
                        showEditCommunity && <EditCommunity
                         showEdit={showEditCommunity} 
                         handleClose={handleCommunityClose} 
                         fileUploader={props.fileUploader}
                         getMasterData={props.getMasterData}
                         updateCommunityInfo={props.updateCommunityInfo}
                         getCommunityDetails={props.getCommunityDetails}
                         />
                    }
                {showProfileModal &&
                    <Modal
                        centered
                        show={showProfileModal}
                        onHide={onHide}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        backdrop={'static'}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Profile Details
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='profile-info-sec'>
                                <h4>Personal Info
                                    <span className='edit_btn' onClick={() => showEditProfile("user")} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.997" height="13.001" viewBox="0 0 14.997 15.001">
                                            <g id="Layer_92" data-name="Layer 92" transform="translate(-2.013 -2.008)">
                                                <path id="Path_25" data-name="Path 25" d="M19.554,2.677a2.251,2.251,0,0,0-3.2,0L14.91,4.14l3.181,3.181,1.441-1.441a2.251,2.251,0,0,0,.023-3.2Z" transform="translate(-3.22)" fill="#f15b28" />
                                                <path id="Path_26" data-name="Path 26" d="M10.624,6.27,4.329,12.565a.863.863,0,0,0-.143.2L2.1,16.969A.747.747,0,0,0,3.084,18l4.209-2.086a.863.863,0,0,0,.2-.143l6.295-6.295Z" transform="translate(0 -1.063)" fill="#f15b28" />
                                                <path id="Path_27" data-name="Path 27" d="M18.5,21.5H11.75a.75.75,0,0,1,0-1.5H18.5a.75.75,0,0,1,0,1.5Z" transform="translate(-2.243 -4.492)" fill="#f15b28" />
                                            </g>
                                        </svg>
                                        Edit
                                    </span>
                                </h4>
                                <div className='profile_info'>
                                    <figure>
                                        <img src={userDetails?.profilePicture ? userDetails?.profilePicture : DefaultProfile}
                                            alt='Profile' />
                                    </figure>
                                    <div className='profile_details'>
                                        <label>{(userDetails?.firstName || userDetails?.lastName) ? userDetails?.firstName + " " + userDetails?.lastName : "--"}
                                            {/* <span className='edit_btn'  onClick={() => showEditProfile("user")}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.997" height="15.001" viewBox="0 0 14.997 15.001">
                                            <g id="Layer_92" data-name="Layer 92" transform="translate(-2.013 -2.008)">
                                                <path id="Path_25" data-name="Path 25" d="M19.554,2.677a2.251,2.251,0,0,0-3.2,0L14.91,4.14l3.181,3.181,1.441-1.441a2.251,2.251,0,0,0,.023-3.2Z" transform="translate(-3.22)" fill="#f15b28"/>
                                                <path id="Path_26" data-name="Path 26" d="M10.624,6.27,4.329,12.565a.863.863,0,0,0-.143.2L2.1,16.969A.747.747,0,0,0,3.084,18l4.209-2.086a.863.863,0,0,0,.2-.143l6.295-6.295Z" transform="translate(0 -1.063)" fill="#f15b28"/>
                                                <path id="Path_27" data-name="Path 27" d="M18.5,21.5H11.75a.75.75,0,0,1,0-1.5H18.5a.75.75,0,0,1,0,1.5Z" transform="translate(-2.243 -4.492)" fill="#f15b28"/>
                                            </g>
                                        </svg>
                                        Edit
                                    </span> */}
                                        </label>
                                        <p className='email'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11.25" viewBox="0 0 16 11.25">
                                                <g id="mail" transform="translate(0 -76)" opacity="0.6">
                                                    <g id="Group_5" data-name="Group 5" transform="translate(11 76.805)">
                                                        <g id="Group_4" data-name="Group 4" transform="translate(0 0)">
                                                            <path id="Path_8" data-name="Path 8" d="M357.07,101.721l-4.859,4.82,4.859,4.82a1.388,1.388,0,0,0,.141-.6v-8.436A1.388,1.388,0,0,0,357.07,101.721Z" transform="translate(-352.211 -101.721)" fill="#f15b28" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_7" data-name="Group 7" transform="translate(0.805 76)">
                                                        <g id="Group_6" data-name="Group 6" transform="translate(0 0)">
                                                            <path id="Path_9" data-name="Path 9" d="M39.509,76H26.323a1.388,1.388,0,0,0-.6.141l6.2,6.178a1.406,1.406,0,0,0,1.988,0l6.2-6.178A1.388,1.388,0,0,0,39.509,76Z" transform="translate(-25.721 -76)" fill="#f15b28" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_9" data-name="Group 9" transform="translate(0 76.805)">
                                                        <g id="Group_8" data-name="Group 8" transform="translate(0 0)">
                                                            <path id="Path_10" data-name="Path 10" d="M.141,101.721a1.388,1.388,0,0,0-.141.6v8.436a1.388,1.388,0,0,0,.141.6L5,106.541Z" transform="translate(0 -101.721)" fill="#f15b28" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_11" data-name="Group 11" transform="translate(0.805 82.282)">
                                                        <g id="Group_10" data-name="Group 10" transform="translate(0 0)">
                                                            <path id="Path_11" data-name="Path 11" d="M35.26,277.211l-.687.688a2.344,2.344,0,0,1-3.314,0l-.687-.687-4.852,4.827a1.388,1.388,0,0,0,.6.141H39.509a1.388,1.388,0,0,0,.6-.141Z" transform="translate(-25.721 -277.211)" fill="#f15b28" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            {userDetails?.email ? userDetails?.email : "--"}</p>
                                        <p className='phone'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11.881" height="19.764" viewBox="0 0 11.881 19.764">
                                                <g id="smartphone" transform="translate(-89.856)" opacity="0.6">
                                                    <g id="Group_12" data-name="Group 12" transform="translate(89.856)">
                                                        <path id="Path_12" data-name="Path 12" d="M99.266,0h-6.94a2.463,2.463,0,0,0-2.47,2.47V17.293a2.478,2.478,0,0,0,2.47,2.47h6.94a2.463,2.463,0,0,0,2.47-2.47V2.47A2.463,2.463,0,0,0,99.266,0ZM95.808,18.169a1.123,1.123,0,1,1,1.123-1.123A1.112,1.112,0,0,1,95.808,18.169Zm4.806-3.436H90.979V3.3h9.635Z" transform="translate(-89.856)" fill="#f15b28" />
                                                    </g>
                                                </g>
                                            </svg>
                                            {userDetails?.phone ? userDetails?.phone : "--"}</p>
                                    </div>
                                </div>
                            </div>
                            {data && data.roleId &&  !([1,2,4,6].indexOf(data?.roleId) > -1) &&

                                <div className='profile-info-sec  community_info_sec'>
                                    <h4>{data?.roleId === 1 ? "Platform Admin Details" : (data?.roleId === 5 ) ? "Company Details" : commonStrings.Community+" "+commonStrings.Details}
                                       <span className='edit_btn' onClick={() => showEditProfile((data?.roleId === 5 ) ?"":"community")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.997" height="13.001" viewBox="0 0 14.997 15.001">
                                                <g id="Layer_92" data-name="Layer 92" transform="translate(-2.013 -2.008)">
                                                    <path id="Path_25" data-name="Path 25" d="M19.554,2.677a2.251,2.251,0,0,0-3.2,0L14.91,4.14l3.181,3.181,1.441-1.441a2.251,2.251,0,0,0,.023-3.2Z" transform="translate(-3.22)" fill="#f15b28" />
                                                    <path id="Path_26" data-name="Path 26" d="M10.624,6.27,4.329,12.565a.863.863,0,0,0-.143.2L2.1,16.969A.747.747,0,0,0,3.084,18l4.209-2.086a.863.863,0,0,0,.2-.143l6.295-6.295Z" transform="translate(0 -1.063)" fill="#f15b28" />
                                                    <path id="Path_27" data-name="Path 27" d="M18.5,21.5H11.75a.75.75,0,0,1,0-1.5H18.5a.75.75,0,0,1,0,1.5Z" transform="translate(-2.243 -4.492)" fill="#f15b28" />
                                                </g>
                                            </svg>
                                            Edit
                                        </span>
                                    </h4>
                                    <div className='community_details_cnt pad0'>
                                        <div className='swiper_slider_sec'>
                                        {data?.roleId == 3 ? <>
                                                {communityDetails?.orgImages?.length > 0 && <Swiper
                                                    slidesPerView={'auto'}
                                                    spaceBetween={10}
                                                    className="landing-Swiper"
                                                >
                                                    {communityDetails?.orgImages?.map((image) => <SwiperSlide>
                                                        <figure style={{ "height": "120px" }}>
                                                            <img src={image?.path ? image.path : CommunityDisplay}></img>
                                                        </figure>
                                                    </SwiperSlide>)}
                                                </Swiper>}
                                                 {!communityDetails?.orgImages?.length > 0 && <figure>
                                                    <span className='without_img'>AB</span>
                                                    {/* <img src={communityDetails?.logo && communityDetails.logo ? communityDetails.logo : DefaultProfile} alt='Profile' /> */}
                                                </figure>}
                                            </> :
                                        <figure>
                                            <img src={data?.roleId === 1 && userDetails && userDetails.adminLogo ? userDetails.adminLogo : data?.roleId === 5 && employerDetailsResponse && employerDetailsResponse.logo ? employerDetailsResponse.logo : communityDetails && communityDetails.logo ? communityDetails.logo : DefaultProfile} alt='Profile' />
                                        </figure>
                                        }
                                        </div>
                                    
                                        <div className='profile_info pad20'>
                                            
                                        
                                        <div className='profile_details pl-0'>
                                            {/* <p>{data?.roleId===1?userDetails.name:data?.roleId===5?employerDetailsResponse?.name:communityDetails?.name}</p> */}
                                            {/* <p className='address'>{"Address"}</p> */}
                                            <label>{data?.roleId === 1 && userDetails && userDetails.name ? userDetails.name : data?.roleId === 5 && employerDetailsResponse && employerDetailsResponse.name ? employerDetailsResponse.name : communityDetails && communityDetails.name && communityDetails?.name}</label>
                                            <p className='address'>{getUsAddress(data?.roleId === 1 && userDetails && userDetails.address ? userDetails.address : data?.roleId === 5 && employerDetailsResponse && employerDetailsResponse.address ? employerDetailsResponse.address : communityDetails && communityDetails.address && communityDetails?.address)}</p>
                                            <p className='phone'>
                                                {communityDetails?.phone ? communityDetails.phone : "--"}
                                            </p>
                                        </div>
                                        
                                    </div>
                                    {data?.roleId == 5 && <div className='padlr20'> <EmployerDetails employerId={data?.employerId} /> </div>}
                                    {data?.roleId == 3 && <div className='padlr20'><MoreDetails communityId={data?.communityId}/></div>}
                                    </div>
                                </div>

                            }
                        </Modal.Body>
                    </Modal>}
            </React.Fragment>
        </>
    )

}
export default ProfileDetails;