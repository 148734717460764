import { isMatchplicityDomain } from "./helpers";


export const commonStrings = {
    basicInfo: 'Basic Information',
    Total: "Total",
    Communities: isMatchplicityDomain() ? 'Communities' : 'Centers',
    Community: isMatchplicityDomain() ? 'Community' : "Center",
    Students: isMatchplicityDomain() ? "Talent":'Students' ,
    Student: isMatchplicityDomain() ? "Talent":'Student',
    Jobs: "Jobs",
    MatchedCandidates: "Matched Candidates",
    Recent: "Recent",
    Invite: "Invite",
    CommunityName: isMatchplicityDomain() ?"Community Name":"Center Name",
    PrimaryContactDetails: "Primary Contact Details:",
    BasicInfo: 'Employment Information',
    firstName: isMatchplicityDomain() ? "First Name" : "First Name",
    lastName: "Last Name",
    mailingAddress: "Mailing Address",
    emailAddress: "Email Address",
    cellPhone: "Cell Phone",
    AdminEmail: 'Admin Email',
    UpdatedOn: 'Updated On',
    Actions: 'Actions',
    Email: 'Email',
    Employers: "Employers",
    Employer: 'Employer',
    BulkInvite: 'Bulk Invite',
    BulkEmploerInvite: 'Bulk Employer Invite',
    BulkStudentInvite: isMatchplicityDomain() ?'Bulk Talent Invite' :'Bulk Student Invite',
    CompanyName: 'Company Name',
    Details: 'Details',
    BulkEmployerUpload: 'Bulk Employer Upload',
    BulkStudentUpload: isMatchplicityDomain() ?'Bulk Talent(s) Upload':'Bulk Student(s) Upload',
    InviteEmail: 'Invite Email',
    SocialMediaHandles: "Social Media Handles",
    Trades:"Trades",
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    LinkedIn: "LinkedIn",
    Address: 'Address',
    JobQuestionnaire: 'Job Questionnaire',
    Register:'Register',

    filters: {
        EmploymentType: 'Employment Type',
        WorkPreference: 'Work Preference',
        JobSearchStatus: 'Job Search Status',
    },
    buttons: {
        SendInvite: "Send Invite",
        Cancel: "Cancel",
        Update: 'Update',
        AddNew: "+Add New",
        Continue: 'Continue',
    },
    headers: {
        Home: "Home",
        Users: 'Users',
        Profile: 'Profile',
        ChangePassword: 'Change Password',
        Logout: 'Logout'
    },

    profile: {
        whereInJobSearch: "Where are you in your job search?",
        JobSearch: isMatchplicityDomain() ? "Job Search" : "Job Search",
        SelectMemberType: "How do you identify yourself?",
        MemberType: "Member Type",
        demoGraphInfo: "Demographic Information",
        GenderIdentification: "Gender Identification",
        ethnicity: "Ethnicity",
        AreyouMilitaryVeteran: "Are you a Military Veteran?",
        MilitaryVeteran: "Military Veteran",
        AreYouUSCitizen: "Are you a US Citizen?",
        USCitizen: "US Citizen",
        AreYouPersonWithDisabilities: "Are you a person with disabilities?",
        Personwithdisabilities: "Person with disabilities",
        AreYouWillingToRelocate: "Are you willing to relocate?",
        WillingToRelocate: "Willing to relocate",
        EmploymentTradeInformation: "Employment + Trade Info",
        EmploymentInformation: "Employment Info",
        SelectTrade: "Select Trade",
        Trade: "Trade",
        CertificationStatus: 'Certification Status',
        certifications: isMatchplicityDomain() ? "Certifications (Should these be called Industry Related Certifications?)" : "Credentials (Should these be called Industry Related Credentials?)",
        primaryCertification: isMatchplicityDomain() ? "Primary Certifications" : "Primary Credentials",
        secondaryCertification: isMatchplicityDomain() ? "Secondary Certifications" : "Secondary Credentials",
        Otherifany: "Other (If any)",
        Other: 'Other',
        AreyouauthorizedtoworkinUS: "Are you legally authorized to work in the United States?",
        LegallyAuthorizedToWork: "Legally authorized to work in the United States",
        DoyourequiresponsorshipforUSEmploymentVisa: "Do you or will you require sponsorship for a US Employment Visa?",
        SponsorshipforUSEmploymentVisa: "Sponsorship for a US Employment Visa",
        Whataretoptendesiredworklocations: "What are your top ten desired work locations?",
        DesiredWorkLlocations: "Desired work locations",
        YoumayadduptoTenlocations: "(You may add up to 10 locations.)",
        Whatisyourpreferredcompanysize: "What is your preferred company size?",
        Preferredcompanysize: "Preferred company size",
        Whichtwoopportunitiesdoyoumostvalue: "Which two opportunities do you most value?",
        Opportunitiesdoyoumostvalue: "Most Value Opportunities",
        Whichbestdescribesyouridealworksetting: "Which best describes your ideal work setting?",
        Idealworksetting: "Ideal work setting",
        Whatrecreationalactivitiesdoyouofferforyouremployees: "What recreational activities do you value most?",
        Recreationalactivitiesofferforemployees: "Recreational activities offer for employees",
        Skills: "Skills",
        SoftSkills: "Soft Skills",
        HardSkills: "Techinical Skills",
        Youcanselectmaximumfive: ' (You can select up to a maximum of 5)',
        Resume: "Resume",
        EmploymentGoals: "Employment Goals",
        JobTypeGoals: "Job Type Goals",
        Whatjobtypeareyoulookingfor: "What type of work are you interested in?",
        Jobtypes: "Job Type",
        Wagegoal: "Wage goal",
        hourlyrate: " (hourly rate)",
        Whencanyoustart: "When can you start?",
        Start: "Start",
        WorkPreferences: "Work Preferences",
        Rankthefollowing: "Rank the following",
        Ranking: "Ranking",

        Pleaserankimportanceonscaleof: "Please rank importance on scale of 1-5, 5 most important and 1 least important",
        Competitivesalaries: "Salaries",
        Worklifebalance: "Work-life balance",
        Excellentphysicalandmentalhealthcarecoverage: "Excellent physical and mental healthcare coverage",
        Robustretirementbenefits: "Robust retirement benefits",
        Childcareofferings: "Childcare offerings",
        Companystockoptions: "Company stock options",
        Whathealthandwellnessactivitiesaremostimportanttoyou: "What health and wellness activities are most important to you?",
        Healthandwellnessactivities: "Health and wellness activities",
        Whatkindofleadershipdevelopmentareyouinterestedin: "What kind of leadership development are you interested in?",
        Whatkindofprefessionaldevelopmentareyouinterestedin: "What kind of professional development are you interested in?",
        LeadershipDevelopmentaInterested: "Leadership developments interested",

        Ratewhatsmostimportanttogetvaluedforyouraccomplishments: "Rate what’s most important to get valued for your accomplishments?",
        Raises: "Raises",
        Bonuses: "Bonuses",
        Publicacknowledgments: "Public acknowledgments (awards)",
        ManagerAckn: "Receiving acknowledgment from managers",
        Promotions: "Promotions",
        Privaterecognition: "Private recognition",
        CompanyWideRecog: "Company-wide recognition",
        AreYouInterestedToSitInBoard: "Are you interested in opportunities to sit on a board?",
        Whatbenefitsaremostimportanttoyou: "What benefits are most important to you?",
        Benefits: "Benefits",
        FlexibleWorkSchedules: "Flexible Work Schedules",
        Autonomyintheworkplace: "Autonomy in workplace",
        Whattypeofprofessionaldevelopmentisimportanttoyou: "What type of professional development is important to you?",
        Leadershipdevelopmenttraining: "Leadership development training",
        Professionalmemberships: "Professional memberships",
        Instructionalseminarsandsymposiums: "Instructional seminars and symposiums",
        EducationInformation: "Education",
        SocialMedia:"Social Media",
        HighestLevelofEducation: "What is your highest level of eduaction?",
        University: "University",
        ifapplicabel: " (if applicable)",
        GPA: "GPA",
        GraduationYear: "Graduation Year",
        CommunityTag: "Tag yourself a community",
        Yourcareerlevel: "Your career level",
    }


};


