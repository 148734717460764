import { useState, useEffect, useRef }  from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import { useDispatch, useSelector } from 'react-redux';
import { setChatMessages } from '../redux/slices/userSlice';
import { getCurrentDateTime, getLogoLetters } from '../utils/helpers';
import mitch from "@/assets/images/mitch.jpg"
import mildred from "@/assets/images/mildred.jpg"
import defaultUserIcon from "@/assets/images/icons/profile-user.png"
import {myBotRresponse} from '../services/talentService';

const API_KEY ="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"

const MyBot = () => {
  const { botMessages, } = useSelector(state => state.user)
  const { data, isAuthenticated } = useSelector(state => state.auth)
  const [avatarProps, setAvatarProps] = useState({})
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const dispatch = useDispatch()
  useEffect(() => {
    if(botMessages?.length > 0){
        setMessages([ ...botMessages ])
    }
  },[])

  useEffect(() => {
    if(isAuthenticated){
      setAvatarProps((prev) => ({
        ["bot"]: {
          ["name"]: [5,6,7].indexOf(data?.roleId) > -1 ? 'Mildred' : 'Mitch',
          ["icon"]: [5,6,7].indexOf(data?.roleId) > -1 ? mildred : mitch 
        },
        ["person"]: {
        ["name"]: (data?.firstName && data?.lastName) ? data.firstName + " " + data.lastName : 
                    data?.firstName ? data.firstName :
                    data?.lastName ? data.lastName : data?.name,
        ["icon"]: data?.profilePicture ? data.profilePicture : defaultUserIcon
        }
      }))
      if(!(botMessages?.length > 0)){
        setMessages(() => [
          {
            message: `Hello, I'm ${ [5,6,7].indexOf(data?.roleId) > -1 ? 'Mildred' : 'Mitch'}! Ask me anything!`,
            sentTime: "just now",
            sender:  [5,6,7].indexOf(data?.roleId) > -1 ? 'Mildred' : 'Mitch',
          },
        ])
      }
      
    }else{

      setAvatarProps(() => ({
        bot: {
          name: "chatGPT",
          icon: defaultUserIcon
        },
        person: {
          name: "User",
          icon: defaultUserIcon
        }
      }))
      if(!(botMessages?.length > 0)){
        setMessages(() => [
          {
            message: `Hello, I'm chatGPT! Ask me anything!`,
            sentTime: "just now",
            sender:  "chatGPT",
          },
        ])
      }
      
    }
  }, [data, isAuthenticated])
  const handleSendRequest = async (message) => {
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: "user",
      sentTime: getCurrentDateTime()
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    // dispatch(setChatMessages([...messages, newMessage]))
    setIsTyping(true);

    try {
      const response = await processMessageToChatGPT([...messages, newMessage]);
      const content = response.choices[0]?.message?.content;
      if (content) {
        const chatGPTResponse = {
          message: content,
          sender: avatarProps?.bot?.name,
          sentTime: getCurrentDateTime()
        };
        setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
        dispatch(setChatMessages([...messages, newMessage, chatGPTResponse]))

      }
    } catch (error) {
      console.error("Error processing message:", error);
    } finally {
      setIsTyping(false);
    }
  };

  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = chatMessages.map((messageObject) => {
      const role = messageObject.sender === avatarProps?.bot?.name ? "assistant" : "user";
      return { role, content: messageObject.message };
    });

    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        { role: "system", content: "I'm a Student using ChatGPT for learning" },
        ...apiMessages,
      ],
    };
    try {
      const response = await myBotRresponse({apiRequestBody});
      return response;
    } catch (error) {
      console.error('Error in API request:', error);
    }

    // const response = await fetch("https://api.openai.com/v1/chat/completions", {
    //   method: "POST",
    //   headers: {
    //     "Authorization": "Bearer " + API_KEY,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(apiRequestBody),
    // });

    // return response.json();
  }
  return (
    <div className="App chat_container">
      <div className="chat_content">
        <MainContainer>
          <ChatContainer>       
            <MessageList 
              scrollBehavior="auto" 
              typingIndicator={isTyping ? <TypingIndicator content={`${avatarProps?.bot?.name} is typing...`} /> : null}
            >
              {messages.map((message, i) => {
                console.log(message)
                return <>
                  <Message key={i} model={message}>
                    {message?.sender == avatarProps?.bot?.name ? <Avatar src={avatarProps.bot.icon} name={avatarProps.bot.name} status="available" /> :
                    <Avatar src={avatarProps.person.icon} name={avatarProps.person.name} status="available" />}
                  </Message>
                </>
              })}
            </MessageList>
            <MessageInput placeholder="Send a Message" onSend={handleSendRequest}
            sendOnReturnDisabled={false}
            attachButton={false}
             />        
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  )
}

export default MyBot;