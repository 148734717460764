import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "@/assets/styles/register.scss";
import SimpleInput from "../components/SimpleInput";
import Button from "react-bootstrap/Button";
import SimpleSelect from "./SimpleSelect";
//import { getMasterData } from "../services/authService";
import { getPreferedCountries, isEmpty } from "../utils/helpers";
import { getMasterDataWithoutDispatch } from "../services/talentService";
function AddressFields({
  errors,
  register,
  control,
  streetAddressFieldName,
  streetAddressErrorText,
  onInputChange,
  countryFieldName,
  stateFieldName,
  cityFieldName,
  zipFieldName,
  countryErrorText,
  stateErrorText,
  cityErrorText,
  zipErrorText,
  streetDefaultValue,
  countryDefaultValue,
  stateDefaultValue,
  cityDefaultValue,
  zipcodeDefaultValue,
  title,
  isLocationName = false,
  locationFieldName,
  locationFieldErrorText,
  locationDefaultValue
}) {
  const { reset, setValue } = useForm();
  const dispatch = useDispatch();
  let statesDropdownRef = null;
  let cityDropdownRef = null;
  let countryDropdownRef = null;
  const [addresValue, setAddresValue] = useState({
    address: {
      locationName: '',
      line1: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
    },
  });
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  useEffect(() => {
   // alert()
    setStateList([])
    getMasterData("countries", 0);
  }, []);
  // useEffect(() => {
  //   setAddresValue((prevState) => ({
  //     ...prevState,
  //     address: {
  //       ...prevState.address,
  //       zipcode: zipcodeDefaultValue,
  //     },
  //   }));
  //   reset()
  //  }, [zipcodeDefaultValue]);
  const formatOptionLabel = (option) => {
    return option.name;
  };
  const getMasterData = (category, id) => {
    getMasterDataWithoutDispatch({ category: category, id: id })
      .then((response) => {
        if (category === "countries") {
          setCountryList(getPreferedCountries(response));
          //console.log("CountrymasterData", response);
        } else if (category === "states") {
          setStateList(response); //locations
         // console.log("stateMasterData", response);
        } else {
          setCityList(response);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleSelectChange = (selectedOption, event, fieldName) => {
    const { name } = event;
    if (name === countryFieldName) {
      const [, nestedName] = name.split(".");
      setCityList([]);
      setAddresValue((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          state: null,
          country: selectedOption,
        },
      }));
      if (statesDropdownRef) {
        statesDropdownRef.clearValue();
      }
      getMasterData("states", selectedOption?.id);
    } else if (name === stateFieldName) {
      const [, nestedName] = name.split(".");
      setAddresValue((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          city: "",
          state: selectedOption,
        },
      }));
      if (cityDropdownRef) {
        cityDropdownRef.clearValue();
      }
      if (selectedOption?.id) {
        getMasterData("locations", selectedOption.id);
      }
    } else if (name === cityFieldName) {
      const [, nestedName] = name.split(".");
      setAddresValue((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          city: selectedOption,
        },
      }));
    }
    // if (name === "address.country") {
    //   const [, nestedName] = name.split(".");
    //   setCityList([]);
    //   setAddresValue((prevState) => ({
    //     ...prevState,
    //     address: {
    //       ...prevState.address,
    //       state: null,
    //       country: selectedOption,
    //     },
    //   }));

    //   if (statesDropdownRef) {
    //     statesDropdownRef.clearValue();
    //   }
    //   getMasterData("states", selectedOption?.id);
    // } else if (name === "address.state") {
    //   const [, nestedName] = name.split(".");
    //   setAddresValue((prevState) => ({
    //     ...prevState,
    //     address: {
    //       ...prevState.address,
    //       city: "",
    //       state: selectedOption,
    //     },
    //   }));
    //   if (cityDropdownRef) {
    //     cityDropdownRef.clearValue();
    //   }
    //   if (selectedOption?.id) {
    //     getMasterData("locations", selectedOption.id);
    //   }
    // } else if (name === "address.city") {
    //   const [, nestedName] = name.split(".");
    //   setAddresValue((prevState) => ({
    //     ...prevState,
    //     address: {
    //       ...prevState.address,
    //       city: selectedOption,
    //     },
    //   }));
    // }
  };
  const handleInputChange = (e) => {
   
    const { name, value } = e.target;
    // console.log("zipcode-value",value)
    // console.log("zipcode-name",name)
    // console.log("zipcode-zipFieldName",zipFieldName)
    if (name===zipFieldName) {
      setAddresValue((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          zipcode: value,
        },
      }));
     // console.log("IF",addresValue)
    }
    else if(name===locationFieldName){
      setAddresValue((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          locationName: value,
        },
      }));
    }
    else{
      setAddresValue((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          line1: value,
        },
      }));
    }
  };
  const OnRefSet = (fieldName, selectRef) => {
    // if (fieldName === "address.city") {
    //   cityDropdownRef = selectRef;
    // } else if (fieldName === "address.state") {
    //   statesDropdownRef = selectRef;
    // } else {
    //   countryDropdownRef = selectRef;
    // }
    if (fieldName === cityFieldName) {
      cityDropdownRef = selectRef;
    } else if (fieldName === stateFieldName) {
      statesDropdownRef = selectRef;
    } else {
      countryDropdownRef = selectRef;
    }
  };
  useEffect(() => {
    if (countryDropdownRef) {
      countryDropdownRef.setValue(countryDefaultValue);
    }
    if (statesDropdownRef) {
      statesDropdownRef.setValue(stateDefaultValue);
    }
    if (cityDropdownRef) {
      cityDropdownRef.setValue(cityDefaultValue);
    }
  }, [countryDefaultValue, stateDefaultValue, cityDefaultValue]);

  return (
    <div className="address_sec">
      <label className="form-title">{title?title:"Address"}</label>
      {isLocationName && <Row>
        <Col>
          <SimpleInput
              label={'Location Name'}
              type='text'
              inputValue={locationDefaultValue}
              onInputChange={handleInputChange}
              errors={errors}
              register={register}
              isRequired={false}
              errorText={locationFieldErrorText}
              fieldName={locationFieldName}
          />
        </Col>
      </Row>}
      <Row>
        <Col>
          <SimpleInput
            label="Street Address"
            type="text"
            errors={errors}
            register={register}
            isRequired={true}
            errorText={streetAddressErrorText}
            fieldName={streetAddressFieldName}
            onInputChange={handleInputChange}
            inputValue={streetDefaultValue}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SimpleSelect
            label="Country"
            options={countryList}
            isMulti={false}
            placeholder={"Select"}
            onInputChange={handleSelectChange}
            formatOptionLabel={formatOptionLabel}
            defaultValue={countryDefaultValue}
            errors={errors}
            register={register}
            isRequired={true}
            errorText={countryErrorText}
            fieldName={countryFieldName}
            control={control}
            ref={countryDropdownRef}
            setRefValue={OnRefSet}
          />
        </Col>
        <Col>
          <SimpleSelect
            label="State"
            options={stateList}
            isMulti={false}
            placeholder={"Select"}
            onInputChange={handleSelectChange}
            formatOptionLabel={formatOptionLabel}
            defaultValue={stateDefaultValue}
            errors={errors}
            register={register}
            isRequired={true}
            errorText={stateErrorText}
            fieldName={stateFieldName}
            control={control}
            //ref={cityDropdownRef}
            ref={statesDropdownRef}
            setRefValue={OnRefSet}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SimpleSelect
            label="City"
            options={cityList}
            isMulti={false}
            placeholder={"Select"}
            onInputChange={handleSelectChange}
            formatOptionLabel={formatOptionLabel}
            defaultValue={cityDefaultValue}
            errors={errors}
            register={register}
            isRequired={false}
            errorText={cityErrorText}
            fieldName={cityFieldName}
            control={control}
            //ref={statesDropdownRef}
            ref={cityDropdownRef}
            setRefValue={OnRefSet}
          />
        </Col>
        <Col>
          <SimpleInput
            label="Zip Code"
            type="zipcode"
            //type="text"
            maxLength={5}
            errors={errors}
            register={register}
            isRequired={true}
            errorText={zipErrorText}
            fieldName={zipFieldName}
            control={control}
            onInputChange={handleInputChange}
            //inputValue={addresValue?.address?.zipcode}
           inputValue={zipcodeDefaultValue}
          />
        </Col>
      </Row>
    </div>
  );
}

export default AddressFields;
