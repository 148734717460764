import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import MyBot from "../components/MyBot";
import { useSelector } from "react-redux";



const IconChatBot = () => {
    const { isAuthenticated } = useSelector(state => state.auth)
    const [bot, setBot] = useState(false)
    const [showIcon, setShowIcon] = useState(true)
    useEffect(() => {
        if(window.location.pathname == '/' && (!isAuthenticated)){
            setShowIcon(false)
        }else{
            setShowIcon(true)
        }
    } ,[isAuthenticated])

    return (<>
        {showIcon && <div className="chat_bot" onClick={() => setBot(true)}>
            <label> How can I Help! </label>
            <span></span>
        </div>}
        {bot && <Offcanvas show={bot} onHide={() => setBot(false)} placement={'end'}>
            <MyBot />
        </Offcanvas>}
    </>

    )
}

export default IconChatBot;