
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    registerSuccess: '',
    registerFailure: '',
    communityDetails: "",
    communitySuccess: "",
    communityFailure: "",
    communityList: [],
    updateCommunitySuccess:"",
    updateCommunityFailure:"",
    updateUserSuccess:"",
    updateUserFailure:"",
    userDetails:"",
    branchInviteSuccess: "",
    branchInviteFailed: "",
    topicCreationSuccess: "",
    topicCreationFailure: "",
    topicList: [],
    topicUpdateSuccess: "",
    topicUpdateFailure: "",
    discussionUpdateSuccess: "",
    discussionUpdateFailure: "",
    eventsList: [],
    eventDetails: "",
    createEventFailure: "",
    createEventSuccess: "",
    updateEventSuccess: "",
    updateEventFailure: "",
    deleteEventSuccess: "",
    deleteEventFailure: "",
    followersList: [],
    topLocationsList: [],
    expCounts: [],
    topSkillsCount: [],
    interviewedStats: [],
    rejectedStats: [],
    hiredStats: [],
    hireStatsLoading: false,
    interviewStatsLoading: false,
    rejectedStatsLoading: false,
    favCompanies: [],
    favJobs: [],
    jobsPostedCmpny: [],
    mostAppliedUsers: [],
    jobSeekerList: [],
    topicDeleteSuccess: "",
    topicDeleteFailed: "",
    eventInterestSaveFail: "",
    eventInterestSaved: "",
    eventInterestedList: [],
    eventAttendees: [],
    talentsCount:'',
    jobsCount:'',
    matchesCount:'',
    upcomingEventsList: [],
    followersLoading: false,
    topLocationsLoading:false,
    experienceLoading:false,
    topicsListLoading:false,
    eventsLoading:false,
    talentsCountLoading:false,
    jobsCountLoading:false,
    matchesCountLoading:false,
    favCompaniesLoading: false,
    jobPostByCmpnyLoading: false,
    topSkillsCountLoading: false,
    favJobStatsLoading: false,
    jobSeekerListLoading: false,
    mostAppliedUsersLoading: false,
    resendInviteFailed: "",
    resendInviteSuccess: "",
    communityDataLoading: false,
    communitiesCount: '',
    comCountLoading: false,
    genderStatLoading: false,
    genderStats: "",
    raceStatsLoading: false,
    raceStats: ""
};

const communitySlice = createSlice({
    name: 'community',
    initialState,
    reducers: {
        communityRegisterRequest: (state, action) => {
            state.loading = true;
        },
        communityRegisterSuccess: (state, action) => {
            state.registerSuccess = action.payload;
            state.registerFailure = '';
            state.loading = false;
        },
        communityRegisterFailure: (state, action) => {
            state.registerSuccess = '';
            state.registerFailure = action.payload;
            state.loading = false;
        },
        communityDetailsSuccess: (state, action) => {
            state.communityDetails = action.payload;
        },
        communityDetailsFailure: (state, action) => {
            state.communityDetails = {};
        },
        inviteCommunitySuccess: (state, action) => {
            state.communitySuccess = action.payload;
            state.loading = false;
        },
        inviteCommunityFailure: (state, action) => {
            state.communityFailure = action.payload;
            state.loading = false;
        },
        communityListSuccess: (state, action) => {
            state.communityList = action.payload
            state.loading = false;
        },
        communityListFailure: (state, action) => {
            state.communityList = []
            state.loading = false;
        },
        communityListRequest: (state) => {
            state.loading = true;
            state.eventsLoading=true
            state.eventsList=[]
        },
        communityToastReset: (state) => {
            state.communitySuccess = ''
            state.communityFailure = ''
            state.registerSuccess = ''
            state.registerFailure = ''
            state.branchInviteSuccess = ''
            state.branchInviteFailed = ''
            state.topicList = []
        },
        updateCommunitySuccess: (state, action) => {
            state.updateCommunitySuccess = action.payload;
            state.loading = false;
        },
        updateCommunityFailure: (state, action) => {
            state.updateCommunityFailure = action.payload;
            state.loading = false;
        },
        sucessMessageRest: (state) => {
            state.updateCommunityFailure = ""
            state.updateCommunitySuccess = ""
            state.loading = false;
        },
        updateUserSuccessMessage: (state, action) => {
            state.updateUserSuccess = action.payload;
            state.loading = false;
        },
        updateUserFailureMessage: (state, action) => {
            state.updateUserFailure = action.payload;
            state.loading = false;
        },
        userMessageRest: (state) => {
            state.updateUserFailure = ""
            state.updateUserSuccess = ""
            state.loading = false;
        },
        communityDetailsData: (state, action) => {
            state.communityDetails = action.payload;
            state.communityDataLoading = false
        },
        userDetailsData: (state, action) => {
            state.userDetails = action.payload;
        },
        inviteBranchSuccess: (state, action) =>{
            state.branchInviteSuccess = action.payload
            state.loading = false
        },
        inviteBranchFailure: (state, action) =>{
            state.branchInviteFailed = action.payload
            state.loading = false
        },
        topicCreationReset: (state) => {
            state.topicCreationSuccess = ""
            state.topicCreationFailure = ""
        },
        topicCreateSuccess: (state, action) =>{
            state.topicCreationSuccess = action.payload
            state.loading = false
        },
        topicCreateFailure: (state, action) => {
            state.topicCreationFailure = action.payload
            state.loading = true
        },
        topicListSuccess: (state, action) => {
            state.topicList = action.payload
            state.topicsListLoading = false
        },
        topicListFailure: (state, action) => {
            state.topicList = action.payload
            state.topicsListLoading = false
         },
         topicUpdateReset: (state) => {
            state.topicUpdateSuccess = ""
            state.topicUpdateFailure = ""
         },
         updateTopicSuccess: (state, action)=>{
            state.topicUpdateSuccess = action.payload
            state.loading = false
         },
        updateTopicFailure: (state, action) => {
            state.topicUpdateFailure = action.payload
            state.loading = false
        },
        updateDiscussionReset: (state) => {
            state.discussionUpdateSuccess = ""
            state.discussionUpdateFailure = ""
        },
        updateDiscussionSuccess: (state, action) => {
            state.discussionUpdateSuccess = action.payload
            state.loading = false
        },
        updateDiscussionFailure: (state, action) => {
            state.discussionUpdateFailure = action.payload
            state.loading = false
        },
        eventsListSuccess: (state, action) => {
            state.eventsList=[]
            state.eventsList = action.payload
            state.loading = false
            state.eventsLoading = false
        },
        eventsListFailure: (state, action) => {
            state.eventsList = action.payload
            state.loading = false
            state.eventsLoading = false
        }, resetEventsList: (state, action) => {
            state.eventsList = []
        },eventDetailsSuccess: (state, action) => {
            state.eventDetails= action.payload
            state.loading = false
        },
        eventDetailsFailure: (state, action) => {
            state.eventDetails= ""
            state.loading = false
        },
        eventDetailsReset: (state) => {
            state.eventDetails = ""
        },
        eventCreateSuccess: (state,action) => {
            state.createEventSuccess= action.payload
            state.loading = false
        },eventCreateFailure: (state,action) => {
            state.createEventFailure= action.payload
            state.loading = false
        },
        eventCreateReset: (state) => {
            state.createEventFailure = ""
            state.createEventSuccess= ""
        },
        eventUpdateSuccess: (state,action) => {
            state.updateEventSuccess= action.payload
            state.loading = false
        },
        eventUpdateFailure: (state,action) => {
            state.updateEventFailure = action.payload
            state.loading = false
        },
        eventUpdateReset: (state) => {
            state.updateEventSuccess = ""
            state.updateEventFailure = ""
        },
        eventDeleteSuccess: (state, action) => {
            state.deleteEventSuccess = action.payload
            state.loading = false
        }, eventDeleteFailure: (state, action) => {
            state.deleteEventFailure = action.payload
            state.loading = false
        },
        eventDeleteReset: (state) => {
            state.deleteEventFailure = ""
            state.deleteEventSuccess = ""
        },
        followersListSuccess: (state, action) => {
            state.followersList = action.payload
            state.followersLoading = false
        },
        followersListFailure: (state, action) => {
            state.followersList = action.payload
            state.followersLoading = false
        },
        topLocationsListSuccess: (state, action) => {
            state.topLocationsList = action.payload
            state.topLocationsLoading = false
        }, 
        topLocationsListFailure: (state, action) => {
            state.topLocationsList = action.payload
            state.topLocationsLoading = false
        },
        experienceFailure: (state, action) => {
            state.expCounts = action.payload
            state.experienceLoading = false
        },
         experienceSuccess: (state, action) => {
            state.expCounts = action.payload
            state.experienceLoading = false
        },
        topSkillsStatSuccess: (state, action) => {
            state.topSkillsCount = action.payload
            state.topSkillsCountLoading = false

        },
        topSkillsStatFailure: (state, action) => {
            state.topSkillsCount = action.payload
            state.topSkillsCountLoading = false
        },
        hireStatsRequest: state => {
            state.hireStatsLoading = true 
            state.hiredStats = []
        },
        setHiredStats: (state, action) => {
            state.hiredStats = action.payload
            state.hireStatsLoading = false 
        },
        interviewStatsRequest: state =>{
            state.interviewStatsLoading = true 
            state.interviewedStats = []
        },
        setInterviewdStats: (state, action) => {
            state.interviewedStats = action.payload
            state.interviewStatsLoading = false 
        },
        rejectedStatsRequest: state =>{
            state.rejectedStatsLoading = true 
            state.rejectedStats = []
        },
        setRejectedStats: (state, action) => {
            state.rejectedStats = action.payload
            state.rejectedStatsLoading = false 
        },
        setFavoriteCompanies: (state, action) => {
            state.favCompanies = action.payload
            state.loading = false
            state.favCompaniesLoading = false
        },
        setFavoriteJobs: (state, action) => {
            state.favJobs = action.payload
            state.favJobStatsLoading = false
        },
        setJobsPostedByCmpny: (state, action) => {
            state.jobsPostedCmpny = action.payload
            state.jobPostByCmpnyLoading = false
        },
        setUsersAppliedMost: (state, action) => {
            state.mostAppliedUsers = action.payload
            state.mostAppliedUsersLoading = false
        },
        setJobSeekers: (state, action) =>{
            state.jobSeekerList = action.payload
            state.jobSeekerListLoading = false
        },
        resetUserDetails: (state) => {
            state.userDetails = ""
        }, 
        deleteTopicSuccess: (state, action) => {
            state.topicDeleteSuccess = action.payload
            state.loading = false
        }, 
        deleteTopicFailure: (state, action) => {
            state.topicDeleteFailed = action.payload
            state.loading = false
        },
        resetTopicDelete: (state) => {
            state.topicDeleteFailed = ""
            state.topicDeleteSuccess = ""
        },
        saveEventInterestSuccess: (state, action) => {
            state.eventInterestSaved = action.payload
            state.loading = false
        },
        saveEventInterestFailure: (state, action) => {
            state.eventInterestSaveFail = action.payload
            state.loading = false
        },

        upcomingEventsSuccess: (state, action) => {
            state.upcomingEventsList = action.payload
            state.eventsLoading = false
        },
        upcomingEventsFailure: (state, action) => {
            state.upcomingEventsList = []
            state.eventsLoading = false
        },
        resetUpcomingEventsReset: (state, action) => {
            state.upcomingEventsList = []
        },
        saveEventInterestReset: (state) => {
            state.eventInterestSaved = ""
            state.eventInterestSaveFail = ""
        },
        setEventInterestList: (state, action) => {
            state.eventInterestedList = action.payload
            state.loading = false
        },
        setEventAttendeesList: (state, action) => {
            state.eventAttendees = action.payload
            state.loading = false
        },setTalentsCount: (state, action) => {
            state.talentsCount = action.payload
            state.talentsCountLoading = false
        },setJobsCount: (state, action) => {
            state.jobsCount = action.payload
            state.jobsCountLoading = false
        },setMatchesCount: (state, action) => {
            state.matchesCount = action.payload
            state.matchesCountLoading = false
        },resetTalentsCount: (state, action) => {
            state.talentsCount = ""
            state.talentsCountLoading = false
        },resetJobsCount: (state, action) => {
            state.jobsCount = ""
            state.jobsCountLoading = false
        },resetMatchesCount: (state, action) => {
            state.matchesCount = ""
            state.matchesCountLoading = false
        },
        resetCounts: (state) => {
            state.matchesCount = ""
            state.jobsCount = ""
            state.talentsCount = ""
        },
        countsLoading: (state) => {
            state.talentsCountLoading = true
            state.jobsCountLoading = true
            state.matchesCountLoading = true
        },
        followerslistLoading: (state) => {
            state.followersLoading = true;
        },
        locationsDashboardLoading: (state) => {
            state.topLocationsLoading = true;
        },
        dashboardExperienceLoading: (state) => {
            state.experienceLoading = true;
        },
        dashboardTopicsListLoading: (state) => {
            state.topicsListLoading = true;
        },
        upcomingEventsLoading: (state) => {
            state.eventsLoading = true;
        },
        resetCommunityDetails: (state, action) => {
            state.communityDetails = "";
        },
        resetCommunityList: (state) => {
            state.communityList = []
            state.loading = true
        },
        favCompaniesListRequest: (state) => {
            state.favCompaniesLoading = true 
            state.favCompanies = []
        },
        jobPostByCompRequest: state => {
            state.jobsPostedCmpny = []
            state.jobPostByCmpnyLoading = true 
        },
        topSkillsCountRequest: state => {
            state.topSkillsCountLoading = true
            state.topSkillsCount = []
        },
        favJobStatsRequest: state => {
            state.favJobStatsLoading = true 
            state.favJobs = []
        },
        jobSeekerListRequest: state => {
            state.jobSeekerListLoading = true 
            state.jobSeekerList = []
        },
        mostAppliedUsersRequest : state => {
            state.mostAppliedUsersLoading = true 
            state.mostAppliedUsers = []
        },
        eventDetailsRequest: (state) => {
            state.eventDetails=""
            state.loading = true;
        },
        resetEventDetails: (state) => {
            state.eventDetails=""
        },
        setResendInviteLinkSuccess: (state, action) => {
            state.resendInviteSuccess = action.payload
        },
        setResendInviteLinkFailed: (state, action) => {
            state.resendInviteFailed = action.payload
        },
        resetResendInviteMsgs: (state) => {
            state.resendInviteFailed = ""
            state.resendInviteSuccess = ""
        },
        communityDetailsRequest: (state) => {
            state.communityDataLoading = true
        },
        communityListCountReq: (state) => {
            state.communitiesCount = ''
            state.comCountLoading = true
        },
        setCommunityListCount: (state, action) => {
            state.communitiesCount = action.payload
            state.comCountLoading = false
        },
        setGenderCount: (state, action) => {
            state.genderStats = action.payload
            state.genderStatLoading = false
        }, 
        genderwiseStatsRequest: (state) => {
            state.genderStats = []
            state.genderStatLoading = true
        },
        setRaceStats: (state, action) => {
            state.raceStats = action.payload
            state.raceStatsLoading = false
        },
        raceWiseStatsRequest: (state) => {
            state.raceStats = []
            state.raceStatsLoading = true
        },
    }
});

export const {userDetailsData,communityDetailsData,userMessageRest,updateUserFailureMessage,updateUserSuccessMessage,sucessMessageRest, updateCommunityFailure,updateCommunitySuccess,communityRegisterRequest, communityRegisterSuccess, communityRegisterFailure, inviteCommunityFailure, inviteCommunitySuccess, communityListSuccess, communityListFailure, communityListRequest, communityToastReset, inviteBranchFailure,
inviteBranchSuccess,topicCreateSuccess,
topicCreateFailure,topicListSuccess,
topicListFailure,updateTopicSuccess,
updateTopicFailure, updateDiscussionSuccess,
updateDiscussionFailure, topicUpdateReset,updateDiscussionReset, topicCreationReset,eventsListSuccess,
eventsListFailure,eventDetailsSuccess,
eventDetailsFailure,eventCreateFailure, eventCreateSuccess, eventCreateReset, eventUpdateSuccess,eventUpdateReset,
eventDeleteSuccess, eventDeleteFailure, eventUpdateFailure,eventDeleteReset,eventDetailsReset,  followersListSuccess, followersListFailure,
topLocationsListSuccess, topLocationsListFailure,  experienceFailure, experienceSuccess, topSkillsStatSuccess, topSkillsStatFailure,
setHiredStats, setInterviewdStats, setRejectedStats, setFavoriteCompanies, setFavoriteJobs,
setJobsPostedByCmpny, setUsersAppliedMost,setJobSeekers,resetUserDetails,
resetTopicDelete, deleteTopicSuccess, deleteTopicFailure,
saveEventInterestSuccess, saveEventInterestFailure, saveEventInterestReset,
setEventInterestList, setEventAttendeesList,setTalentsCount,setJobsCount,setMatchesCount,resetTalentsCount,resetJobsCount,
resetMatchesCount,resetCounts,followerslistLoading ,locationsDashboardLoading,dashboardExperienceLoading,dashboardTopicsListLoading,
upcomingEventsLoading,upcomingEventsFailure,upcomingEventsSuccess,countsLoading,resetUpcomingEventsReset,resetEventsList,
resetCommunityDetails, resetCommunityList,
favCompaniesListRequest,hireStatsRequest, interviewStatsRequest, rejectedStatsRequest,
jobPostByCompRequest, topSkillsCountRequest, favJobStatsRequest,jobSeekerListRequest,
mostAppliedUsersRequest,eventDetailsRequest,resetEventDetails,
setResendInviteLinkSuccess, setResendInviteLinkFailed, resetResendInviteMsgs,
communityDetailsRequest, setCommunityListCount, communityListCountReq,
setGenderCount, genderwiseStatsRequest,
setRaceStats, raceWiseStatsRequest,
} = communitySlice.actions;
export default communitySlice.reducer;
