import React, {useState,useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CommunityDisplay from '@/assets/images/landing/community_image.png';

import { getCommunityDetails } from '../services/communityService';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

function CommunityInfo({communityId}) {
    const dispatch = useDispatch();
    const { communityDetails } = useSelector(state => state.community);

    useEffect(() => {
        dispatch(getCommunityDetails({"communityId": communityId}))
    }, [communityId])
    return (
        <React.Fragment>
            {communityDetails&&<div className="description">
                
               {/* { communityDetails?.orgImages?.length > 0 && <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={10}
                        className="landing-Swiper"
                    >
                        {communityDetails?.orgImages?.map((image) => <SwiperSlide>
                            <figure style={{"height": "120px"}}>
                                <img src={image?.path ? image.path : CommunityDisplay}></img>
                            </figure>
                        </SwiperSlide>)}
                    </Swiper>} */}
                 
                <div className="data ">
                    <h5>Do you represent the national organization?</h5>
                    <label>{communityDetails?.nationalOrganization == true ? "Yes" : "No"}</label>
                </div>
                <div className="data">
                    <h5>Are you a chapter affiliate?</h5>
                    <label>{communityDetails?.chapterAffiliate == true ? "Yes" : "No"}</label>
                </div>
                <div className="data">
                    <h5>Do you want to create a matchplicity sourcing community? </h5>
                    <label>{communityDetails?.sourcingCommunity == true ? "Yes" : "No"}</label>
                </div>
                <div className="data">
                    <h5>Do you want your professional community hidden or open to receive new members?</h5>
                    <label>{communityDetails?.communityType == 1 ? "Hidden" : "Open"}</label>
                </div>
                <div className="data">
                    <h5>Do you have events to promote on matchplicity?</h5>
                    <label>{communityDetails?.promote == true ? "Yes" : "No"}</label>
                </div>
                {communityDetails?.tagsDetails?.length>0&&
                <div className="data">
                    <h5>Tags</h5>
                    <ul className="filters">
                    {communityDetails.tagsDetails.map((tag)=>
                        <li>
                            <label>{tag.name}</label>
                        </li>
                    )}
                    </ul>
                </div>}
                <h4 className="sub_title">Membership Demographics</h4>
                <div className="data">
                    <h5>Industries represented by membership</h5>
                    <ul className="filters">
                    {communityDetails?.industriesDetails?.length>0 && communityDetails.industriesDetails.map((industry)=>
                        <li>
                            <label>{industry?.name}</label>
                        </li>
                    )}
                    </ul>
                </div>
                <div className="data">
                    <h5>Location(s) of organization</h5>
                    <ul className="filters">
                    {communityDetails?.orgLocationDetails?.length>0 && communityDetails.orgLocationDetails.map((location)=>
                        <li>
                            <label>{location?.name} { location?.stateName ? `(${location.stateName})` : ""}</label>
                        </li>
                    )}
                    </ul>
                </div>
                <div className="data">
                    <h5>Career levels of members</h5>
                    <ul className="filters">
                    {communityDetails?.careerLevelDetails?.length>0 && communityDetails.careerLevelDetails.map((career)=>
                        <li>
                            <label>{career?.name}</label>
                        </li>
                    )}
                    </ul>
                </div>
                <div className="data">
                    <h5>Description</h5>
                    <div className='description_info' dangerouslySetInnerHTML={{ __html: communityDetails?.description }}></div>
                </div>
            </div>}
        </React.Fragment>
        
    )
}
export default CommunityInfo;