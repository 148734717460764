import { lazy } from 'react';

const ResetPassword = lazy(() => import('../views/ResetPassword'));
const SetPassword = lazy(() => import('../views/SetPassword'))
const AddJob = lazy(() => import('../views/addJob'));
const BulkJob = lazy(() => import('../views/bulkJob'));
const ProfileDetails = lazy(() => import('../views/Profile/details'));
const CommunityListPage = lazy(() => import('../views/Platform/communityList'));
const EmployerListPage = lazy(() => import('../views/Community/employerList'));
const TalentListPage = lazy(() => import('../views/Community/talentList'));
const CandidatesPage = lazy(() => import('../views/Candidates'));
const JobsPage = lazy(() => import('../views/Jobs'));
const EmployerBulkInvites =lazy(() => import('../views/bulkInvites/employersInvite'));
const TalentBulkInvites =lazy(() => import('../views/bulkInvites/talentInvite'));
const UsersListPage = lazy(() => import('../views/Platform/usersList'));
const LandingPage = lazy(() => import('../views/landing/landing'));
const talentCommunitiesPage = lazy(() => import('../views/landing/talentCommunities'));
const talentJoinPage = lazy(() => import('../views/landing/talentJoin'));
const forEmployerPage = lazy(() => import('../views/landing/forEmployer'));
const  FeaturedCommunity = lazy(() => import('../views/Employer/employeerCommunities'));
const SubScribe = lazy(() => import('../views/subscribe/subscribePlan'));
const ClaimWithOtp= lazy(() => import('../views/claimCommunity'));
const SignUpWithLink= lazy(() => import('../views/signUpWithLink'));
const Terms= lazy(() => import('../views/terms'));
const PrivacyPolicy= lazy(() => import('../views/privacyPolicy'));
const CaliforniaPrivacyPolicy= lazy(() => import('../views/CaliforniaPrivacyPolicy'));
const CompanyLicenseAgreement= lazy(() => import('../views/CompanyLicenseAgreement'));
const OrganizationLicenseAgreement= lazy(() => import('../views/OrganizationLicenseAgreement'));
 


const commonRoutes = [
    { path: '/account-activation', component: ResetPassword, exact: true },
    { path: '/public/reset-password', component: ResetPassword, exact: true },
    { path: '/public/set-password', component: SetPassword, exact: true },
    { path: '/add-job', component: AddJob, exact: true },
    { path: '/bulk-upload', component: BulkJob, exact: true },
    { path: '/profile', component: ProfileDetails, exact: true },
    { path: '/communitylist-dashboard', component: CommunityListPage, exact: true },
    { path: '/employerlist-dashboard', component: EmployerListPage, exact: true },
    { path: '/talentlist-dashboard', component: TalentListPage, exact: true },
    { path: '/jobs', component: JobsPage, exact: true },
    { path: '/candidates', component: CandidatesPage, exact: true },
    { path: '/bulk-employerinvite', component: EmployerBulkInvites, exact: true},
    { path: '/bulk-studentinvite', component: TalentBulkInvites, exact: true},
    { path: '/userslist-dashboard', component: UsersListPage, exact: true },
    { path: '/for-community', component: talentCommunitiesPage, exact: true },
    { path: '/for-talent', component: talentJoinPage, exact: true },
    { path: '/', component: LandingPage, exact: true },
    { path: '/for-employer', component: forEmployerPage, exact: true },
    {path: '/communities', component: FeaturedCommunity, exact: true},
    { path: '/subscribe/:communityId', component: SubScribe, exact: true},
    { path: '/claim-community' , component: ClaimWithOtp, exact: true },
    { path: '/:communityName/invitation/:communityId' , component: SignUpWithLink, exact: true },
    { path: '/terms', component: Terms, exact: false},
    { path: '/privacy-policy', component: PrivacyPolicy, exact: false},
    { path: '/california-privacy-policy', component: CaliforniaPrivacyPolicy, exact: false},
    { path: '/company-license-agreement', component: CompanyLicenseAgreement, exact: false},
    { path: '/organization-license-agreement', component: OrganizationLicenseAgreement, exact: false},


    
];

export default commonRoutes;
