import { combineReducers } from 'redux';
import authReducer from './slices/authSlice';
import commonReducer from './slices/commonSlice';

import userReducer from './slices/userSlice';
import communityReducer from './slices/communitySlice';
import employerReducer from './slices/employerSlice';
import talentReducer from './slices/talentSlice';

const rootReducer = combineReducers({
    common:commonReducer,
    auth: authReducer,
    user: userReducer,
    community: communityReducer,
    employer:employerReducer,
    talent: talentReducer
}); 

export default rootReducer;
