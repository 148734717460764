import React, { useState, useEffect, useCallback } from "react";
import  { uploadedfiles }  from "../../services/commonServices";
import Offcanvas from "react-bootstrap/Offcanvas";
/////////////
import profilePicIcon2 from "@/assets/images/main/profile_pic_2.png";
import locationIconWhite from "@/assets/images/main/location_white.svg";
import ExpIcon from "@/assets/images/main/clock.svg";
import JobtypeIcon from "@/assets/images/main/house-laptop.svg";
import salaryRangeIcon from "@/assets/images/main/coins.svg";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import "@/assets/styles/candidates.scss";
import { useSelector, useDispatch } from "react-redux";
import { getJobsList, talentDetails } from "../../services/talentService";
import ProfileLogo from "@/assets/images/icons/profile-icon.svg";
import {
  getFormatedDate,
  getFormatedDateTime,
  getRankingClass,
  getUsAddress,
  isEmpty,
  isMatchplicityDomain,
} from "../../utils/helpers";
import { commonStrings } from "../../utils/strings";
import FileIcon from "../../components/icons/file";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { fetchSignedUrl,uploadFiles } from "../../services/commonServices";
import { signedUrlReset,uploadedFileReset } from "../../redux/slices/commonSlice";
import { useForm } from "react-hook-form";
import { sendMessage,sendNote ,getMessagesList, getNotesList} from "../../services/employerService";
import sentIcon from "@/assets/images/main/sent.svg";
import SimpleInput from '../../components/SimpleInput';
import FileUploader from '../../components/FileUploader';
import { showToast } from "../Utils";
import { employerToastReset } from '../../redux/slices/employerSlice';
import { Button } from "react-bootstrap";
import moment from 'moment';
import JobDetails from '../Employer/jobDetails';
import NoData from "../../components/NoData";
import closeIcon from "@/assets/images/icons/close_white.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import suitcaseIcon from "@/assets/images/main/suitcase.svg";
import Pagination from '../../components/Pagination';

const salaryType = [
  { id: 1, name: 'Hourly' },
  { id: 2, name: 'Monthly' },
  { id: 3, name: 'Annually' },
]
function ProfileModal({ show = false, onHide, detailsId, isFromList = false,jobMsgDetails=null,
isFromEvent=false, userId}) {
  const { loginUserData, talentDetailsData, jobsList, jobsListLoading } = useSelector(
    (state) => state.talent
  );
  const { downloadSignedUrlDoc,uploadedfiles } = useSelector((state) => state.common);
  const {  sentMsgSuccess, sentMsgFailed, noteSSuccess, noteSFail,messageList,notesList } =  useSelector(state => state.employer)
  const { data } = useSelector((state) => state.auth);
  const [messageData,setMessageData] = useState({
    message: "",
    documents: []
  })
  const [noteData,setNoteData] = useState({
    note: "",
  })
  const [resetKey, setResetKey] = useState(0);
  const [ allMsgList,setAllMsgList ] = useState({
    page: 1,
    msgList: [],
    samePage: true
})
const [ allNotesList,setAllNotesList ] = useState({
  page: 1,
  notesList: [],
  samePage: true
})
const [showJob, setShowJob] = useState({
  show:false,
  jobId: ''
})
const [currentPage, setCurrentPage] = useState(1)

  const { handleSubmit, formState: { errors }, register, clearErrors, control, reset, setValue } = useForm();
  const { handleSubmit: handleSubmit2, formState: { errors: errors2 }, register: register2, clearErrors: clearErrors2, control :control2, reset: reset2, setValue: setValue2 } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState()
  const editProfile = () => {
    onHide();
    navigate("/student/edit-profile");
  };
  const getMsgList = () => {
    dispatch(getMessagesList({
      "jobId": jobMsgDetails?.jobId,
      "sent_to": jobMsgDetails?.userId,
      "page": allMsgList.page,
      "perpage": 50
    }))
  }
useEffect(()=>{ getMsgList()},[allMsgList.page])
const getNoteList = () => {
  dispatch(getNotesList({
    "jobId": jobMsgDetails?.jobId,
    "userId": jobMsgDetails?.userId,
    "page": allNotesList.page,
    "perpage": 50
  }))
}
useEffect(()=>{ getNoteList()},[allNotesList.page])

  useEffect(() => { }, [loginUserData]);
  let isInitialRender = false;
  useEffect(() => { 
    if(sentMsgSuccess){
      setResetKey(prevKey => prevKey + 1);
      setMessageData(()=>({
        message: "",
        documents: []
      }))
        reset()
        setAllMsgList({
          page: 1,
          samePage: true,
          msgList: []
      })
    
        dispatch(getMessagesList({
          "jobId": jobMsgDetails?.jobId,
          "sent_to": jobMsgDetails?.userId,
          "page": 1,
          "perpage": 50
        }))
        dispatch(uploadedFileReset())
      dispatch(employerToastReset())
    }
    if(sentMsgFailed){
      showToast(sentMsgFailed,'error')
      dispatch(employerToastReset())
    }
  }, [sentMsgSuccess, sentMsgFailed]);
  useEffect(() => { 
    if(noteSSuccess){
      setNoteData({note : ""})
        reset2()
        dispatch(getNotesList({
          "jobId": jobMsgDetails?.jobId,
          "userId": jobMsgDetails?.userId,
          "page": allNotesList.page,
          "perpage": 50
        }))
      dispatch(employerToastReset())
      setAllNotesList({
        page: 1,
        samePage: true,
        notesList: []
    })
    }
    if(noteSFail){
      showToast(noteSFail,'error')
      dispatch(employerToastReset())
    }
  }, [noteSSuccess, noteSFail]);
  useEffect(() => {
    if (detailsId) {
      if (!isInitialRender) {
        isInitialRender = true;
        let payLoad = { detailsId: detailsId };
        if (true) {
          payLoad.includeQues = true;
        }
        dispatch(talentDetails(payLoad));
      }
    }
  }, []);
  useEffect(() => {
    if (downloadSignedUrlDoc) {
      const fileUrl = downloadSignedUrlDoc;
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          const filename = fileName;
          // const filename = "filename.pdf";
          link.setAttribute("download", filename);
          link.click();
          window.URL.revokeObjectURL(url);
          dispatch(signedUrlReset())
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
    }
  }, [downloadSignedUrlDoc]);
  useEffect(() => {
        if(messageList?.list?.length > 0){
            setAllMsgList((prev) => ({
                ...prev,
                ["msgList"]: prev.samePage ? [ ...(messageList?.list)] :  [ ...(messageList?.list),...(prev.msgList)],
                ["samePage"]: true
            }))
        }
}, [ messageList])
useEffect(() => {
  if(notesList?.list?.length > 0){
      setAllNotesList((prev) => ({
          ...prev,
          ["notesList"]: prev.samePage ? [ ...(notesList?.list)] :  [...(prev.notesList), ...(notesList?.list)],
          ["samePage"]: true
      }))
  }
}, [ notesList])
useEffect(() => {
  if(isFromEvent){
    dispatch(getJobsList({
      "matcher": {
          "title": '',
          "employeeTypeIds": [],
          "locationIds": [],
          "salaryRangeIds": [],
          "createdDateRange": []
      },
      "page": currentPage,
      "perpage": 8,
      "isEvent": isFromEvent,
      "userId": userId,
      "communityId": data?.communityId,
      "employerId": data?.employerId
  }))
  }
 
},[detailsId])
  const handleDownloadDocument = () => {
    let keyName =
      !isEmpty(talentDetailsData) &&
        !isEmpty(talentDetailsData.questionnaire) &&
        talentDetailsData.questionnaire.resume.length > 0
        ? talentDetailsData.questionnaire.resume[0].path
        : "";
    let filename =
      !isEmpty(talentDetailsData) &&
        !isEmpty(talentDetailsData.questionnaire) &&
        talentDetailsData.questionnaire.resume.length > 0
        ? talentDetailsData.questionnaire.resume[0].name
        : "";
    setFileName(filename)
    dispatch(fetchSignedUrl({ keyName: keyName }));
  };
  const handleDownloadDocument2 = (file) => {
    setFileName(file.name)
    dispatch(fetchSignedUrl({ keyName: file.path }));
  };
  const handleSelectedFile = (file) => {
    setMessageData((prev) => ({
      ...prev,
      ["documents"] : file
    }))

  }
  const handleFileRemove = () => {
    setMessageData((prev) => ({
      ...prev,
      ["documents"] : []
    }))
  }
  const handleInputChange = (e) => {
    setMessageData((prev) => ({
      ...prev,
      ["message"]: e.target.value
    }))
  }
  const handleInputNoteChange = (e) => {
    setNoteData({"note": e.target.value})
  }
  const onSendMessage = (data) => {
    dispatch(sendMessage({
      "jobId": jobMsgDetails?.jobId,
    "message": data.message,
    "sent_to": jobMsgDetails?.userId,
    "documents": uploadedfiles
    }))
  }
  const onErrors = (err) => {
    console.log("errors in msg ",err)
  }
  const onSendNote = (data) =>{
    console.log("data note ",data)
    dispatch(sendNote({
      "jobId": jobMsgDetails?.jobId,
      "note": data.note,
      "userId": jobMsgDetails?.userId,
    }))
  }
  const handleLoadMoreMsg = () => {
    setAllMsgList((prev) => ({
        ...prev,
        page: prev.page + 1,
        samePage: false
    }))
}
const handleLoadMoreNotes = () => {
  setAllNotesList((prev) => ({
      ...prev,
      page: prev.page + 1,
      samePage: false
  }))
}
const downloadResume = (file) => {
  // const link = document.createElement("a");
  // link.href = file?.path;
  // const filename = file?.name;
  // // const filename = "filename.pdf";
  // link.setAttribute("download", filename);
  // link.click();
  // window.URL.revokeObjectURL(file?.path);
  window.open(file?.path, '_blank');

}
const handleShow = (jobId) => {
  setShowJob({
    show: true,
    jobId: jobId
  })
}
const handleClose = () => {
  setShowJob({
    show: false,
    jobId: ''
  })
}
const onHandlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber)
}

console.log("talentDetailsData.../",talentDetailsData)
  return (
    <>
      {/* <span onClick={() => setShow(true)}>Edit</span> */}

    {show && <Offcanvas show={show} onHide={onHide} placement={"end"}>
        <Offcanvas.Header>
          <div className="profile-info">
            <figure className="profile-img">
              <img src={talentDetailsData?.profilePicture ? talentDetailsData?.profilePicture : ProfileLogo} />
            </figure>
            <div className="profile-titles">
              <span className="profile-name">
                {talentDetailsData?.name ? talentDetailsData?.name : "--"}
              </span>
              <span className="profile-role">
                {talentDetailsData?.questionnaire?.memberType
                  ? talentDetailsData.questionnaire.memberType == "Student" ? "Student Jobseeker" : "Professional Jobseeker"
                  : "--"}
              </span>
              {getUsAddress(talentDetailsData?.address, [3,4,7].indexOf(data?.roleId) > -1) && <span className="profile-location">
                <img src={locationIconWhite} />
                {talentDetailsData?.address
                  ? getUsAddress(talentDetailsData?.address, [3,4,7].indexOf(data?.roleId) > -1)
                  : "--"}
              </span>}
            </div>
          </div>
          {
            <div className="profile-actions">
              {!isFromList && (
                <div className="profile-top">
                  {/* <span className='profile-matched' >87% Matched</span> */}
                  <span onClick={editProfile} className="primary-btn">
                    Edit Profile
                  </span>
                </div>
              )}
              <span className="member-since">
                {"Since Member " +
                  getFormatedDate(talentDetailsData?.createdOn)}
              </span>
            </div>
          }
        </Offcanvas.Header>
        <Offcanvas.Body>
          {
            <div className="profile_details_body">
              <div className="profile_exp_sec 2">
                <ul>
                  {/* <li>
                                    <div className='icon_sec'>
                                        <img src={ExpIcon} />
                                    </div>
                                    <div className='details_sec'>
                                        <span>8+ Years</span>
                                        <em>Experience</em>
                                    </div>
                                </li> */}
                  <li>
                    <div className="icon_sec">
                      <img src={JobtypeIcon} />
                    </div>
                    <div className="details_sec">
                      <span>
                        {talentDetailsData?.questionnaire?.workInterstedDetails
                          ?.name
                          ? talentDetailsData?.questionnaire
                            ?.workInterstedDetails.name
                          : "--"}
                      </span>
                      <em>{commonStrings.profile.Jobtypes}</em>
                    </div>
                  </li>
                  <li>
                    <div className="icon_sec">
                      <img src={salaryRangeIcon} />
                    </div>
                    <div className="details_sec">
                      <span>
                      {talentDetailsData?.updatedOn ? moment(talentDetailsData.updatedOn).format("MMM DD, YYYY") : "--"}
                      </span>
                      <em>
                        Last Updated
                      </em>
                    </div>
                  </li>
                </ul>
              </div>
              <Tabs defaultActiveKey={jobMsgDetails?.defaultActiveKey ? jobMsgDetails.defaultActiveKey : "Basic"}>
                {isFromEvent && <Tab eventKey="matchedJobs" title={'Matched Jobs'}>
                  <div className=''>
                    <div className='details_list'>
                      {(jobsList?.list?.length > 0) ?
                        <>
                          <div className='Jobs-list'>
                            {jobsList.list.map((item) => {
                              return (

                                <div className='job-list job-list-v2' key={item.id}>
                                  <div className='details_list_item' key={item.id}>
                                    <div className='job-item' onClick={() => handleShow(item?.jobId)}>
                                      <figure>
                                        <img src={suitcaseIcon} />
                                      </figure>
                                      <div className='job-info'>
                                        <span className='job_title'>{item?.jobDetails?.jobTitle}</span>
                                        {/* <p className='company-name'>
                                        {item?.companyName}
                                      </p> */}
                                        <p className='job-type'>
                                          {
                                            item?.jobDetails?.jobTypeDetails?.map((type) => (
                                              <span >{type.name}</span>
                                            ))
                                          }
                                        </p>
                                        <p className='job-location'>
                                          {
                                            item?.jobDetails?.joblocationsDetails?.map((location) => (
                                              <span>{location.name} {location?.stateName ? `(${location.stateName})` : ""}</span>
                                            ))
                                          }
                                        </p>
                                      </div>
                                    </div>
                                    <div className='job_right '>
                                      <span className='more-menu'>

                                        <DropdownButton
                                          align="end"
                                          variant="secondary"
                                          title=""
                                        >


                                          <Dropdown.Item onClick={() => handleShow(item._id)}>View</Dropdown.Item>
                                          {/* <Dropdown.Item>Delete</Dropdown.Item> */}
                                        </DropdownButton>
                                      </span>
                                    </div>

                                  </div>

                                </div>
                              );
                            })}

                          </div>
                          {showJob.show && <Offcanvas className="job-details" show={showJob.show} onHide={handleClose} placement={'end'}>
                            <Offcanvas.Header closeButton className='offcanvas-header-req'>
                              <Offcanvas.Title></Offcanvas.Title>
                            </Offcanvas.Header>
                            <JobDetails
                              jobId={showJob.jobId}
                            />
                          </Offcanvas>}
                        </> : <NoData loading={jobsListLoading} />}
                    </div>
                    <Pagination currentPage={currentPage} totalItems={jobsList?.totalCount > 0 ? jobsList.totalCount : 0} itemsPerPage={8} onPageChange={onHandlePageChange} />
                  </div>

                </Tab>}
                
                { !!jobMsgDetails && jobMsgDetails?.jobId && 
                <Tab eventKey="messages" title="Messages">
                  <div className="messages-list">
                    {(Math.ceil((messageList?.totalCount) / 50) > allMsgList.page) && <Button onClick={handleLoadMoreMsg}>Load more....</Button>}
                    {allMsgList.msgList.length > 0 && allMsgList.msgList.map((msg) => (
                      <div className='messages '>
                        <figure className='messages-img'>
                          <img alt="profile pic" src={msg?.messageDetails?.profilePicture ? msg.messageDetails.profilePicture : ProfileLogo} />
                        </figure>
                        <div className='messages-details'>
                          <h5 className='messenger-name'>{msg?.messageDetails?.userName ? msg.messageDetails.userName : "--"} - {msg?.createdOn ? getFormatedDateTime(msg.createdOn) : "--"}</h5>
                          <div className='message-content'>
                            <p>{msg?.message ? msg.message : ""}</p>

                            {msg?.documents && <div className='pdf-content' onClick={() => handleDownloadDocument2(msg.documents?.[0])}>
                              <figure>
                                <img src='' alt='' />
                              </figure>
                              <span className='pdf-name'>{msg.documents?.[0]?.name}</span>
                            </div>}
                          </div>
                        </div>
                      </div>
                    ))}
                    </div>
                  <div className='offcanvas_footer inner_footer'>
                                {/* <input type='text' placeholder='Write Messages�' />
                                <div className='actions'>
                                    <img src={attachIcon} />
                                    <span> <img src={sentIcon} /></span>
                                </div> */}
                    <form id="message-form" onSubmit={handleSubmit(onSendMessage, onErrors)}>
                    <SimpleInput
                      type='text'
                      inputValue={messageData.message?messageData.message:""}
                      onInputChange={handleInputChange}
                      className={''}
                      errors={errors}
                      placeholder={"Write Messages..."}
                      register={register}
                      isRequired={true}
                      errorText={"Message"}
                      fieldName={'message'}
                    />
                    <div className='actions'>
                      <FileUploader
                        key={resetKey}
                        type='file'
                        fieldName={'documents'}
                        isRequired={false}
                        control={control}
                        // defaultValue={messageData?.documents}
                        accepts={['.pdf', '.doc', '.docx']}
                        register={register}
                        errors={errors}
                        errorText={'Documents'}
                        onFilesUploaded={handleSelectedFile}
                        onFileRemoved={handleFileRemove}
                        fileUploader={uploadFiles}
                        clearErrors={clearErrors}
                        localupload={false}
                      />
                      <Button type="submit"><img src={sentIcon} />
                      </Button>
                      </div>
                    </form>
                   
                    </div>
                </Tab>
                }
                { !!jobMsgDetails && jobMsgDetails?.jobId && <Tab eventKey="notes" title="Notes">
                {(Math.ceil((notesList?.totalCount) / 50) > allNotesList.page) && <Button onClick={handleLoadMoreNotes}>Load more....</Button>}
                  {allNotesList.notesList.length > 0 && allNotesList.notesList.map((note) => (
                     <div className='notes'>
                      <figure className='notes-img'>
                       <img src={note?.noteDetails?.profilePicture ? note.noteDetails.profilePicture  : ProfileLogo} />
                     </figure> 
                     <div className='notes-details'>
                       <h5 className='notes-name'>{note?.noteDetails?.userName ? note.noteDetails.userName : "--"} - {note?.createdOn ? getFormatedDateTime(note.createdOn) : "--"}</h5>
 
                       <div className='notes-content'>
                         <p>{note?.note}</p>
                       </div>
                     </div>
                   </div>
                    
                  ))}
                  {/* <div className='notes'>
                    <figure className='notes-img'>
                      <img src={profilePicIcon} />
                    </figure>
                    <div className='notes-details'>
                      <h5 className='notes-name'>Victoria Mitchner - 3:35pm</h5>

                      <div className='notes-content'>
                        <p>Hi Simbo Osoba, Please upload the resume along with you portfolio</p>
                      </div>
                    </div>
                  </div>
                  <div className='notes'>
                    <figure className='notes-img'>
                      <img src={profilePicIcon} />
                    </figure>
                    <div className='notes-details'>
                      <h5 className='notes-name'>Victoria Mitchner - 3:35pm</h5>
                      <div className='notes-content'>
                        <p>Hi Simbo Osoba, We have shared task to your email and revert back to us by 25th September, Please keep these points for your task</p>
                        <ul>
                          <li>We have shared task to your email and revert back</li>
                          <li>We have shared task to your email and revert back</li>
                          <li>We have shared task to your email and revert back</li>
                          <li>We have shared task to your email and revert back</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='notes'>
                    <figure className='notes-img'>
                      <img src={profilePicIcon} />
                    </figure>
                    <div className='notes-details'>
                      <h5 className='notes-name'>Mitchner Victoria - 3:35pm</h5>

                      <div className='notes-content'>
                        <p>Hi Victoria Mitchner, I have seen the task lets proceed to next round of interview</p>
                      </div>
                    </div>
                  </div> */}
                  <div className='offcanvas_footer inner_footer'>
                                {/* <input type='text' placeholder='Write Messages�' />
                                <div className='actions'>
                                    <img src={attachIcon} />
                                    <span> <img src={sentIcon} /></span>
                                </div> */}
                    <form id="note-form" onSubmit={handleSubmit2(onSendNote, onErrors)}>
                    <SimpleInput
                      type='text'
                      inputValue={noteData.note?noteData.note:""}
                      onInputChange={handleInputNoteChange}
                      className={''}
                      errors={errors2}
                      placeholder={"Write Note..."}
                      register={register2}
                      isRequired={true}
                      errorText={"Note"}
                      fieldName={'note'}
                    />
                    <div className='actions'>
                     
                      <Button type="submit"><img src={sentIcon} />
                      </Button>
                      </div>
                    </form>
                   
                    </div>
                </Tab>
                }
               
                <Tab eventKey="Basic" title={commonStrings.BasicInfo}>
                  <div className="details_list">
                    <ul>
                      <li>
                        <span>{commonStrings.emailAddress}</span>
                        <label>
                          {talentDetailsData?.email
                            ? talentDetailsData?.email
                            : "--"}
                        </label>
                      </li>
                      {[3,4,7].indexOf(data?.roleId) > -1 && <li>
                        <span>{commonStrings.cellPhone}</span>
                        <label>
                          {talentDetailsData?.phone
                            ? talentDetailsData?.phone
                            : "--"}
                        </label>
                      </li>}
                      {talentDetailsData?.questionnaire?.salutationDetails && 
                        <li>
                          <span>Salutation/Prefix</span>
                          <label>{talentDetailsData?.questionnaire?.salutationDetails?.name}</label>
                        </li>
                      }

                      {talentDetailsData?.questionnaire?.currentlyEmployed && 
                        <li>
                          <span>If you are currently employed, what is the name of your employer?</span>
                          <label>{talentDetailsData?.questionnaire?.currentlyEmployed}</label>
                        </li>
                      } 

                      {talentDetailsData?.questionnaire?.currentTitle && 
                        <li>
                          <span>Current title</span>
                          <label>{talentDetailsData?.questionnaire?.currentTitle}</label>
                        </li>
                      }

                     
                      <li>
                        <span>{commonStrings.profile.whereInJobSearch}</span>
                        <label>
                          {talentDetailsData?.questionnaire?.jobSearchDetails
                            ?.name
                            ? talentDetailsData?.questionnaire?.jobSearchDetails
                              ?.name
                            : ""}
                        </label>
                      </li>
                      {/* <li className="listtitle">
                        <span>{commonStrings.Trades}</span>
                      </li> */}
                      
                      {/* {talentDetailsData?.questionnaire?.tradelist.length > 0 &&
                        talentDetailsData?.questionnaire?.tradelist?.map(
                          (item) => (
                            <>
                              <li>
                                <span>{commonStrings.profile.Trade}</span>
                                <label>{item?.name ? item?.name : "--"}</label>
                              </li>
                              <li className="pb0">
                                <span>
                                  {commonStrings.profile.CertificationStatus}
                                </span>
                                <label>
                                  {item?.statusId?.name
                                    ? item?.statusId?.name
                                    : "--"}
                                </label>
                              </li>
                              <li>
                                {item?.primaryDetails?.length > 0 &&
                                  <>
                                    <li className="pb0">
                                      <span>
                                        {commonStrings.profile.primaryCertification}
                                      </span>
                                    </li>
                                    <ul>
                                      {item?.primaryDetails?.map((primary) => (
                                        <li>
                                          <label>
                                            {primary?.name ? primary?.name : "--"}
                                          </label>
                                        </li>
                                      ))}
                                    </ul>
                                  </>
                                }
                              </li>
                              <li>
                                {item?.secondaryDetails?.length > 0 &&
                                  <>
                                    <li className="pb0">
                                      <span>
                                        {commonStrings.profile.secondaryCertification}
                                      </span>
                                    </li>
                                    <ul>
                                      {item?.secondaryDetails?.map((primary) => (
                                        <li className="w-full pb0">
                                          <label>
                                            {primary?.name ? primary?.name : "--"}
                                          </label>
                                        </li>
                                      ))}
                                    </ul>
                                  </>
                                }
                              </li>
                              <li>
                                <span>{commonStrings.profile.Other}</span>
                                <label>
                                  {item?.others ? item.others : "--"}
                                </label>
                              </li>
                            </>
                          )
                        )} */}

                      <li className="w-full">
                        <span>
                          {commonStrings.profile.LegallyAuthorizedToWork}
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire?.authorizeWorkInUs
                            ? "Yes"
                            : "No"}
                        </label>
                      </li>
                      <li>
                        <span>
                          {commonStrings.profile.SponsorshipforUSEmploymentVisa}
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire
                            ?.sponsorForUsEmployVisa
                            ? "Yes"
                            : "No"}
                        </label>
                      </li>
                      { talentDetailsData?._id == data?.detailsId && talentDetailsData?.hideCompaniesDetails?.length > 0 && (
                          <li className="w-full">
                            <span>
                              {'Hide my profile from these companies'}
                            </span>
                            {talentDetailsData?.hideCompaniesDetails?.map(
                              (item) =><label>{item?.name ? item.name : '-'}</label>
                            )}
                          </li>
                        )}
                      {/* <li className='w-full'>
                                            <span>{commonStrings.profile.DesiredWorkLlocations}</span>
                                            <div className='locations'>
                                                {talentDetailsData?.questionnaire?.desireTenWorkLocations ? talentDetailsData?.questionnaire?.desireTenWorkLocations : "--"}
                                            </div>
                                        </li> */}

                      {talentDetailsData?.questionnaire
                        ?.desireTenWorkLocationsDetails?.length > 0 && (
                          <li className="w-full">
                            <span>
                              {commonStrings.profile.DesiredWorkLlocations}
                            </span>
                            {talentDetailsData?.questionnaire?.desireTenWorkLocationsDetails?.map(
                              (item) => item?.name && item?.stateName && <label>{item.name} ({item.stateName})</label>
                            )}
                          </li>
                        )}

                      {/* {isMatchplicityDomain() && (
                        <>
                          <li>
                            <span>
                              {commonStrings.profile.Preferredcompanysize}
                            </span>
                            <label>
                              {" "}
                              {talentDetailsData?.questionnaire
                                ?.companySizeDetails
                                ? talentDetailsData?.questionnaire
                                  ?.companySizeDetails.name
                                : "--"}
                            </label>
                          </li>
                          <li>
                            <span>
                              {
                                commonStrings.profile
                                  .Opportunitiesdoyoumostvalue
                              }
                            </span>
                            <label>
                              {talentDetailsData?.questionnaire
                                ?.mostValueTwoOpportunities
                                ? talentDetailsData?.questionnaire
                                  ?.mostValueTwoOpportunities
                                : "--"}
                            </label>
                          </li>
                          <li>
                            <span>
                              {commonStrings.profile.Idealworksetting}
                            </span>
                            <label>
                              {talentDetailsData?.questionnaire
                                ?.idealWorkSettingDetails
                                ? talentDetailsData?.questionnaire
                                  ?.idealWorkSettingDetails.name
                                : "--"}
                            </label>
                          </li>
                          {talentDetailsData?.questionnaire
                            ?.recreationalActiveDetails?.length > 0 && (
                              <li className="w-full">
                                <span>
                                  {
                                    commonStrings.profile
                                      .Recreationalactivitiesofferforemployees
                                  }
                                </span>
                                {talentDetailsData?.questionnaire?.recreationalActiveDetails?.map(
                                  (item) =>
                                    item?.name && <label>{item.name}</label>
                                )}
                              </li>
                            )}
                        </>
                      )} */}
                     {getUsAddress(talentDetailsData?.address, [3,4,7].indexOf(data?.roleId) > -1) && <li>
                        <span>{commonStrings.Address}</span>
                        <label>
                          {getUsAddress(talentDetailsData?.address, [3,4,7].indexOf(data?.roleId) > -1)}
                        </label>
                      </li>}

                       {talentDetailsData?.questionnaire?.memberType === "Student" ? <li>
                        <span>
                          Student Type
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire?.studentType
                            ? talentDetailsData.questionnaire.studentType
                              ?.name
                            : "--"}
                        </label>
                      </li> : 
                      <li>
                      <span>
                        Career Level
                      </span>
                      <label>
                        {talentDetailsData?.questionnaire?.careerLevelDetails
                          ? talentDetailsData.questionnaire.careerLevelDetails
                            ?.name
                          : "--"}
                      </label>
                    </li> 
                      }

                      {[3,4,7].indexOf(data?.roleId) > -1 && <li>
                        <span>
                          {commonStrings.profile.GenderIdentification}
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire?.genderDetails
                            ? talentDetailsData?.questionnaire?.genderDetails
                              ?.name
                            : "--"}
                        </label>
                      </li>}

                      {talentDetailsData?.questionnaire?.anotherGender && 
                       <li>
                           <span>Another Gender</span>
                          <label>
                            {talentDetailsData?.questionnaire?.anotherGender
                              ? talentDetailsData?.questionnaire?.anotherGender
                              : "--"}
                          </label>
                       </li>
                      }


                      {talentDetailsData?.questionnaire?.orientationDetails &&
                       <li>
                          <span>
                             Sexual orientation
                          </span>
                          <label>
                            {talentDetailsData?.questionnaire?.orientationDetails?.name
                              ? talentDetailsData?.questionnaire?.orientationDetails?.name
                              : "--"}
                          </label>
                         </li>
                      }

                      {talentDetailsData?.questionnaire?.anotherOrientation && 
                       <li>
                           <span>Another Orientation</span>
                          <label>
                            {talentDetailsData?.questionnaire?.anotherOrientation
                              ? talentDetailsData?.questionnaire?.anotherOrientation
                              : "--"}
                          </label>
                       </li>
                      } 

                      {talentDetailsData?.questionnaire?.transgender && 
                       <li>
                           <span>Do you identify as transgender?</span>
                          <label>
                            {talentDetailsData?.questionnaire?.transgender == 1
                              ? "Yes"
                              : talentDetailsData?.questionnaire?.transgender == 2 ? "No" : "Prefer not to say"}
                          </label>
                       </li>
                      } 

                      {talentDetailsData?.questionnaire?.attendCollege && 
                       <li>
                           <span>Are you the first in your family to attend college?</span>
                          <label>
                            {talentDetailsData?.questionnaire?.attendCollege == 1
                              ? "Yes"
                              : talentDetailsData?.questionnaire?.attendCollege == 2 ? "No" : "Prefer not to say"}
                          </label>
                       </li>
                      } 

                      {talentDetailsData?.questionnaire?.childImmigrants && 
                       <li>
                           <span>Are you the child of immigrants or do you consider yourself to be a first-generation American?</span>
                          <label>
                            {talentDetailsData?.questionnaire?.childImmigrants == 1
                              ? "Yes"
                              : talentDetailsData?.questionnaire?.childImmigrants == 2 ? "No" : "Prefer not to say"}
                          </label>
                       </li>
                      }

                      
                      {talentDetailsData?.questionnaire?.dob && 
                       <li>
                           <span>Date of Birth</span>
                          <label>
                            {talentDetailsData?.questionnaire?.dob
                              ? moment(talentDetailsData?.questionnaire?.dob).format("MMM DD, YYYY")
                              : "--"}
                          </label>
                       </li>
                      }  

                      {talentDetailsData?.questionnaire?.geographicAffiliationDetails && 
                       <li>
                           <span>U.S. geographic affiliation</span>
                          <label>
                            {talentDetailsData?.questionnaire?.geographicAffiliationDetails?.name
                              ? talentDetailsData?.questionnaire?.geographicAffiliationDetails?.name
                              : "--"}
                          </label>
                       </li>
                      } 

                      {talentDetailsData?.questionnaire?.politicalPartyDetails && 
                       <li>
                           <span>Political party</span>
                          <label>
                            {talentDetailsData?.questionnaire?.politicalPartyDetails?.name
                              ? talentDetailsData?.questionnaire?.politicalPartyDetails?.name
                              : "--"}
                          </label>
                       </li>
                      } 

                      {talentDetailsData?.questionnaire?.managedPeopleDetails && 
                       <li>
                           <span>Managed people</span>
                          <label>
                            {talentDetailsData?.questionnaire?.managedPeopleDetails?.name
                              ? talentDetailsData?.questionnaire?.managedPeopleDetails?.name
                              : "--"}
                          </label>
                       </li>
                      }  

                      {talentDetailsData?.questionnaire?.managedBudgetDetails && 
                       <li>
                           <span>Managed budget</span>
                          <label>
                            {talentDetailsData?.questionnaire?.managedBudgetDetails?.name
                              ? talentDetailsData?.questionnaire?.managedBudgetDetails?.name
                              : "--"}
                          </label>
                       </li>
                      } 
                      
                      {[3,4,7].indexOf(data?.roleId) > -1 && <li>
                        <span>{commonStrings.profile.ethnicity}</span>
                        <label>
                          {" "}
                          {talentDetailsData?.questionnaire?.ethnicityDetails
                            ? talentDetailsData?.questionnaire?.ethnicityDetails
                              ?.name
                            : "--"}
                        </label>
                      </li>}
                      {[3,4,7].indexOf(data?.roleId) > -1 && <li>
                        <span>
                          {commonStrings.profile.AreyouMilitaryVeteran}
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire
                            ?.areyouMilitaryVeteran
                            ? "Yes"
                            : "No"}
                        </label>
                      </li>}
                      <li>
                        <span>{commonStrings.profile.AreYouUSCitizen}</span>
                        <label>
                          {talentDetailsData?.questionnaire?.areYouUsCitizen
                            ? "Yes"
                            : "No"}
                        </label>
                      </li>
                      {talentDetailsData?.questionnaire?.eligibleToWorkInUs && 
                       <li>
                           <span>If you are not a U.S. Citizen, are you eligible to work in the United States?</span>
                          <label>
                            {talentDetailsData?.questionnaire?.eligibleToWorkInUs == 1
                              ? "Not Applicable"
                              : talentDetailsData?.questionnaire?.eligibleToWorkInUs == 2 ? "Yes" : "No"}
                          </label>
                       </li>
                      } 

                      {talentDetailsData?.questionnaire?.interestedWorkingDetails && 
                       <li>
                           <span>Are you interested in working for:</span>
                          <label>
                            {talentDetailsData?.questionnaire?.interestedWorkingDetails?.name
                              ? talentDetailsData?.questionnaire?.interestedWorkingDetails?.name
                              : "--"}
                          </label>
                       </li>
                      }

                       <li>
                           <span>Are you currently employed by the Federal government?</span>
                          <label>
                            {talentDetailsData?.questionnaire?.federalGovernment 
                              ? "Yes"
                              :  "No"}
                          </label>
                       </li>

                      <li>
                        <span>
                          {commonStrings.profile.AreYouPersonWithDisabilities}
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire?.personDisability
                            ? "Yes"
                            : "No"}
                        </label>
                      </li>
                      <li>
                        <span>
                          {commonStrings.profile.AreYouWillingToRelocate}
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire?.relocate
                            ? "Yes"
                            : "No"}
                        </label>
                      </li>
                      <li>
                        <span>
                          {commonStrings.profile.CommunityTag}
                        </span>
                          {talentDetailsData?.questionnaire?.communityTagsDetails?.length > 0
                            ? talentDetailsData?.questionnaire?.communityTagsDetails?.map((tag) =>  <label>{tag?.name}</label>)
                            : <label>--</label>}
                      </li>

                      {talentDetailsData?.questionnaire?.otherCommunities && 
                       <li>
                           <span>Other communities that have historically been under-represented in government</span>
                          <label>
                            {talentDetailsData?.questionnaire?.otherCommunities}
                          </label>
                       </li>
                      } 

                      {false&&talentDetailsData?.questionnaire?.bidenHarrisAdministration && 
                       <li>
                           <span> Biden-Harris administration</span>
                          <label>
                            {talentDetailsData?.questionnaire?.bidenHarrisAdministration}
                          </label>
                       </li>
                      } 

                       {talentDetailsData?.questionnaire?.managementExperience && 
                       <li>
                           <span>Management experience</span>
                          <label>
                            {talentDetailsData?.questionnaire?.managementExperience}
                          </label>
                       </li>
                      } 

                      {talentDetailsData?.questionnaire?.pastGovernmentExperience && 
                       <li>
                           <span>Past government experience</span>
                          <label>
                            {talentDetailsData?.questionnaire?.pastGovernmentExperience}
                          </label>
                       </li>
                      }

                      {talentDetailsData?.questionnaire?.additionalQualifications && 
                       <li>
                           <span>Additional qualifications</span>
                          <label>
                            {talentDetailsData?.questionnaire?.additionalQualifications}
                          </label>
                       </li>
                      } 

                    </ul>
                  </div>
                </Tab>
                {/* <Tab
                  eventKey="messages"
                  title={commonStrings.profile.EmploymentTradeInformation}
                >
                  <div className="details_list">
                    <ul>
                      {talentDetailsData?.questionnaire?.tradelist.length > 0 &&
                        talentDetailsData?.questionnaire?.tradelist?.map(
                          (item) => (
                            <>
                              <li>
                                <span>{commonStrings.profile.Trade}</span>
                                <label>{item?.name ? item?.name : "--"}</label>
                              </li>
                              <li>
                                <span>
                                  {commonStrings.profile.CertificationStatus}
                                </span>
                                <label>
                                  {item?.statusId?.name
                                    ? item?.statusId?.name
                                    : "--"}
                                </label>
                              </li>
                              {item?.primaryDetails?.map((primary) => (
                                <li>
                                  <span>
                                    {commonStrings.profile.primaryCertification}
                                  </span>
                                  <label>
                                    {primary?.name ? primary?.name : "--"}
                                  </label>
                                </li>
                              ))}
                              {item?.secondaryDetails?.map((secondary) => (
                                <li>
                                  <span>
                                    {
                                      commonStrings.profile
                                        .secondaryCertification
                                    }
                                  </span>
                                  <label>
                                    {secondary?.name ? secondary?.name : "--"}
                                  </label>
                                </li>
                              ))}
                              <li>
                                <span>{commonStrings.profile.Other}</span>
                                <label>
                                  {item?.others ? item.others : "--"}
                                </label>
                              </li>
                            </>
                          )
                        )}

                      <li>
                        <span>
                          {commonStrings.profile.LegallyAuthorizedToWork}
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire?.authorizeWorkInUs
                            ? "Yes"
                            : "No"}
                        </label>
                      </li>
                      <li>
                        <span>
                          {commonStrings.profile.SponsorshipforUSEmploymentVisa}
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire
                            ?.sponsorForUsEmployVisa
                            ? "Yes"
                            : "No"}
                        </label>
                      </li>
                      {/* <li className='w-full'>
                                            <span>{commonStrings.profile.DesiredWorkLlocations}</span>
                                            <div className='locations'>
                                                {talentDetailsData?.questionnaire?.desireTenWorkLocations ? talentDetailsData?.questionnaire?.desireTenWorkLocations : "--"}
                                            </div>
                                        </li> */}

                {/* {talentDetailsData?.questionnaire
                        ?.desireTenWorkLocationsDetails?.length > 0 && (
                        <li className="w-full">
                          <span>
                            {commonStrings.profile.DesiredWorkLlocations}
                          </span>
                          {talentDetailsData?.questionnaire?.desireTenWorkLocationsDetails?.map(
                            (item) => item?.name && <label>{item.name}</label>
                          )}
                        </li>
                      )}

                      {isMatchplicityDomain() && (
                        <>
                          <li>
                            <span>
                              {commonStrings.profile.Preferredcompanysize}
                            </span>
                            <label>
                              {" "}
                              {talentDetailsData?.questionnaire
                                ?.preferredCompanySize
                                ? talentDetailsData?.questionnaire
                                    ?.preferredCompanySize
                                : "--"}
                            </label>
                          </li>
                          <li>
                            <span>
                              {
                                commonStrings.profile
                                  .Opportunitiesdoyoumostvalue
                              }
                            </span>
                            <label>
                              {talentDetailsData?.questionnaire
                                ?.mostValueTwoOpportunities
                                ? talentDetailsData?.questionnaire
                                    ?.mostValueTwoOpportunities
                                : "--"}
                            </label>
                          </li>
                          <li>
                            <span>
                              {commonStrings.profile.Idealworksetting}
                            </span>
                            <label>
                              {talentDetailsData?.questionnaire
                                ?.idealWorkSettingDetails
                                ? talentDetailsData?.questionnaire
                                    ?.idealWorkSettingDetails.name
                                : "--"}
                            </label>
                          </li>
                          {talentDetailsData?.questionnaire
                            ?.recreationalActiveDetails?.length > 0 && (
                            <li className="w-full">
                              <span>
                                {
                                  commonStrings.profile
                                    .Recreationalactivitiesofferforemployees
                                }
                              </span>
                              {talentDetailsData?.questionnaire?.recreationalActiveDetails?.map(
                                (item) =>
                                  item?.name && <label>{item.name}</label>
                              )}
                            </li>
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                </Tab>  */}

                <Tab eventKey="skills" title={commonStrings.profile.Skills}>
                  <div className="details_list skills">
                     
                    {talentDetailsData?.questionnaire?.techSkillsDetails
                        ?.length > 0 && (
                          <>
                            <span>{commonStrings.profile.HardSkills}</span>
                            <ul>                      
                              {talentDetailsData?.questionnaire?.techSkillsDetails?.map(
                                (item) => item?.name && <li> {item.name}</li>
                              )} 
                          </ul>
                          </>
                        )}
                      {talentDetailsData?.questionnaire?.softSkillsDetails
                        ?.length > 0 && (
                          <>
                          <span>{commonStrings.profile.SoftSkills}</span>
                          <ul>                            
                            {talentDetailsData?.questionnaire?.softSkillsDetails?.map(
                              (item) => item?.name && <li>{item.name}</li>
                            )}
                          </ul>
                          </>
                        )}

                        {talentDetailsData?.questionnaire?.desiredFunctions
                        ?.length > 0 && (
                          <>
                          <span>Desired Functions</span>
                          <ul>                            
                            {talentDetailsData?.questionnaire?.desiredFunctions?.map(
                              (item) => item?.name && <li>{item.name}</li>
                            )}
                          </ul>
                          </>
                        )}

                        {talentDetailsData?.questionnaire?.desiredPolicy
                        ?.length > 0 && (
                          <>
                          <span>Desired Policy</span>
                          <ul>                            
                            {talentDetailsData?.questionnaire?.desiredPolicy?.map(
                              (item) => item?.name && <li>{item.name}</li>
                            )}
                          </ul>
                          </>
                        )}

                        {talentDetailsData?.questionnaire?.desiredDepartment
                        ?.length > 0 && (
                          <>
                          <span>Desired Department</span>
                          <ul>                            
                            {talentDetailsData?.questionnaire?.desiredDepartment?.map(
                              (item) => item?.name && <li>{item.name}</li>
                            )}
                          </ul>
                          </>
                        )}

                      {/* <li>
                                            <span>{commonStrings.profile.Resume}</span>

                                        </li> */}
                    
                    {talentDetailsData?.questionnaire?.accessibilityInstructions &&
                      <ul>
                        <li>
                          <span>Accessibility instructions</span>
                          <p>{talentDetailsData?.questionnaire?.accessibilityInstructions}</p>
                        </li>
                      </ul>
                      
                    }

                    <ul>
                      <li>
                        <span>Are you a registered federal lobbyist or have you been a registered federal lobbyist in the past 24 months?</span>
                        <p>{talentDetailsData?.questionnaire?.federalLobbyist ? "Yes":"No"}</p>
                      </li>
                    </ul>
                      

                  </div>
                </Tab>
                {!isEmpty(talentDetailsData) &&
                  !isEmpty(talentDetailsData.questionnaire) &&
                  talentDetailsData.questionnaire?.resume?.length > 0 && <Tab eventKey="resume" title={commonStrings.profile.Resume}>
                    {!isEmpty(talentDetailsData) &&
                      !isEmpty(talentDetailsData.questionnaire) &&
                      talentDetailsData.questionnaire?.resume?.length > 0
                      && talentDetailsData.questionnaire?.resume[0]?.path && <div className="uploads_list">
                        <ul>
                          <li>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Title</Tooltip>}
                            >
                              <div className="file_icon">
                                <span variant="secondary">
                                  <FileIcon></FileIcon>
                                </span>

                                <div className="file_actions">
                                  {/* <span>View</span> */}
                                  <span onClick={() =>downloadResume(talentDetailsData.questionnaire?.resume[0])}>
                                    Download
                                  </span>
                                </div>
                              </div>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </div>}
                    <h5>Resume Details</h5>
                    {!isEmpty(talentDetailsData) && !isEmpty(talentDetailsData.questionnaire) && !isEmpty(talentDetailsData.resumeDetails) && <div className="details_list">
                      <ul>
                        {talentDetailsData && talentDetailsData.questionnaire && talentDetailsData.resumeDetails && (
                          <>
                            {talentDetailsData.resumeDetails.email &&
                              <li>
                                <span>Email</span>
                                <label>{talentDetailsData.resumeDetails.email}</label>
                              </li>
                            }
                            {talentDetailsData.resumeDetails.name &&
                              <li>
                                <span>Name</span>
                                <label>{talentDetailsData.resumeDetails.name}</label>
                              </li>
                            }
                            {talentDetailsData.resumeDetails.mobile_number &&
                              <li>
                                <span>Mobile Number</span>
                                <label>{talentDetailsData.resumeDetails.mobile_number}</label>
                              </li>
                            }
                            {talentDetailsData.resumeDetails.college_name &&
                              <li>
                                <span>College Name</span>
                                <label>{talentDetailsData.resumeDetails.college_name}</label>
                              </li>
                            }
                            {talentDetailsData.resumeDetails.degree &&
                              <li>
                                <span>Degree</span>
                                <label>{talentDetailsData.resumeDetails.degree}</label>
                              </li>
                            }
                            {talentDetailsData.resumeDetails.designation &&
                              <li>
                                <span>Designation</span>
                                <label>{talentDetailsData.resumeDetails.designation}</label>
                              </li>
                            }
                            {talentDetailsData.resumeDetails.experience &&
                              <li>
                                <span>Experience</span>
                                <label>{talentDetailsData.resumeDetails.experience}</label>
                              </li>
                            }
                          </>
                        )}
                      </ul>


                      <div>
                        <h5>Skills</h5>
                        <ul className="filters">
                          <li><p>
                            {
                              !isEmpty(talentDetailsData.resumeDetails.skills) && talentDetailsData.resumeDetails.skills.length > 0 ?
                                talentDetailsData.resumeDetails.skills.map((skill, index, skillsArray) =>
                                  <span style={{ display: 'inline' }} key={skill}>
                                    {skill}
                                    {index !== skillsArray.length - 1 ? ', ' : ''}
                                  </span>
                                )
                                : <label>{"--"}</label>
                            }
                          </p></li>
                        </ul>

                      </div>

                      {
                        talentDetailsData &&
                          talentDetailsData.questionnaire &&
                          talentDetailsData.resumeDetails &&
                          !isEmpty(talentDetailsData.resumeDetails.company_names) &&
                          talentDetailsData.resumeDetails.company_names.length > 0 ? (
                          <div className="data">
                            <h5>Company Names</h5>
                            <ul className="filters">
                              {talentDetailsData.resumeDetails.company_names.map((companyName) => (
                                <li key={companyName}>
                                  <label>{companyName}</label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : null
                      }


                    </div>}




                  </Tab>}
                <Tab
                  eventKey="employment"
                  title={commonStrings.profile.EmploymentGoals}
                >
                  <div className="details_list">
                    <ul>
                      {talentDetailsData?.questionnaire?.jobTypeGoalsDetails?.length>0 &&
                      <li className="w-full">
                        <span>{commonStrings.profile.JobTypeGoals}</span>
                        {talentDetailsData?.questionnaire?.jobTypeGoalsDetails?.map(
                          (item) => item?.name && <label>{item.name}</label>
                        )}
                      </li>}
                      <li>
                        <span>{commonStrings.profile.Whatjobtypeareyoulookingfor}</span>
                        <label>
                          {talentDetailsData?.questionnaire
                            ?.workInterstedDetails?.name
                            ? talentDetailsData?.questionnaire
                              ?.workInterstedDetails.name
                            : "--"}
                        </label>
                      </li>
                      <li>
                        <span>Which is your preferred work environment?</span>
                        <label>
                          {talentDetailsData?.questionnaire
                            ?.preferredWorkEnvDetails?.name
                            ? talentDetailsData?.questionnaire
                              ?.preferredWorkEnvDetails.name
                            : "--"}
                        </label>
                      </li>
                      <li>
                        <span>
                          Last Updated
                        </span>
                        <label>
                          {talentDetailsData?.updatedOn ? moment(talentDetailsData.updatedOn).format("MMM DD, YYYY") : "--"}
                        </label>
                      </li>

                      <li>
                        <span>
                          Wage goal ({talentDetailsData?.questionnaire?.salaryType ? salaryType.find((t) => t.id == talentDetailsData?.questionnaire?.salaryType)?.name : "Hourly"})
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire?.wageGoalDetails
                            ?.name
                            ? talentDetailsData?.questionnaire?.wageGoalDetails
                              .name
                            : "--"}
                        </label>
                      </li>
                      <li>
                        <span>{commonStrings.profile.Whencanyoustart}</span>
                        <label>
                          {talentDetailsData?.questionnaire?.employmentStart
                            ? getFormatedDate(talentDetailsData.questionnaire.employmentStart)
                            : "--"}
                        </label>
                      </li>
                    </ul>
                  </div>
                </Tab>

                {isMatchplicityDomain() && (
                  <Tab
                    eventKey="ranking"
                    title={commonStrings.profile.WorkPreferences}
                  >
                    <div className="skill_sec pad10">
                      <h5 className="rate-title">
                        {commonStrings.profile.Ranking}
                      </h5>
                      <div className="details_list mt20">

{isMatchplicityDomain() && (
                       <ul>
                         <li>
                           <span>
                             {commonStrings.profile.Preferredcompanysize}
                           </span>
                           <label>
                             {" "}
                             {talentDetailsData?.questionnaire
                               ?.preferredCompanySizeDetails
                               ? talentDetailsData?.questionnaire
                                 ?.preferredCompanySizeDetails?.name
                               : "--"}
                           </label>
                         </li>
                         {/* <li>
                           <span>
                             {
                               commonStrings.profile
                                 .Opportunitiesdoyoumostvalue
                             }
                           </span>
                           <label>
                             {talentDetailsData?.questionnaire
                               ?.mostValueTwoOpportunities
                               ? talentDetailsData?.questionnaire
                                 ?.mostValueTwoOpportunities
                               : "--"}
                           </label>
                         </li> */}
                         <li>
                           <span>
                             {commonStrings.profile.Idealworksetting}
                           </span>
                           <label>
                             {talentDetailsData?.questionnaire
                               ?.idealWorkSettingDetails
                               ? talentDetailsData?.questionnaire
                                 ?.idealWorkSettingDetails.name
                               : "--"}
                           </label>
                         </li>
                         {talentDetailsData?.questionnaire
                           ?.recreationalActiveDetails?.length > 0 && (
                             <li className="w-full">
                               <span>
                                 {
                                   commonStrings.profile
                                     .Recreationalactivitiesofferforemployees
                                 }
                               </span>
                               {talentDetailsData?.questionnaire?.recreationalActiveDetails?.map(
                                 (item) =>
                                   item?.name && <label>{item.name}</label>
                               )}
                             </li>
                           )}
                       </ul>
                     )}
                     </div>
                      <div className="skill_body">
                      
                        <ul>
                       
                          <li>
                            <div className="skill_body_left">
                              <span>
                                {commonStrings.profile.Competitivesalaries}
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire?.ranking
                                      ?.competiSalaries
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>
                                {commonStrings.profile.Worklifebalance}
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire?.ranking
                                      ?.workBalance
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>
                                {
                                  commonStrings.profile
                                    .Excellentphysicalandmentalhealthcarecoverage
                                }
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire?.ranking
                                      ?.healthCovrge
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>
                                {commonStrings.profile.Robustretirementbenefits}
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire?.ranking
                                      ?.retireBenifits
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>
                                {commonStrings.profile.Childcareofferings}
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire?.ranking
                                      ?.childCare
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>
                                {commonStrings.profile.Companystockoptions}
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire?.ranking
                                      ?.stockOptions
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>
                                {commonStrings.profile.Autonomyintheworkplace}
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire?.benifits
                                      ?.autonomy
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>

                        </ul>
                      </div>
                      <div className="details_list mt20">
                        <ul>
                          {talentDetailsData?.questionnaire
                            ?.healthActivitiesDetails?.length > 0 && (
                              <li>
                                <span>
                                  {
                                    commonStrings.profile
                                      .Whathealthandwellnessactivitiesaremostimportanttoyou
                                  }
                                </span>
                                {talentDetailsData?.questionnaire?.healthActivitiesDetails?.map(
                                  (item) =>
                                    item?.name && <label>{item.name}</label>
                                )}
                              </li>
                            )}
                          {talentDetailsData?.questionnaire
                            ?.leadershipDevlopmentDetails?.length > 0 && (
                              <li>
                                <span>
                                  {
                                    commonStrings.profile
                                      .Whatkindofprefessionaldevelopmentareyouinterestedin
                                  }
                                </span>
                                {talentDetailsData?.questionnaire?.leadershipDevlopmentDetails?.map(
                                  (item) =>
                                    item?.name && <label>{item.name}</label>
                                )}
                              </li>
                            )}
                        </ul>
                      </div>
                      <h5 className="rate-title">
                        {
                          commonStrings.profile
                            .Ratewhatsmostimportanttogetvaluedforyouraccomplishments
                        }
                      </h5>
                      <div className="skill_body">
                        <ul>
                          <li>
                            <div className="skill_body_left">
                              <span>{commonStrings.profile.Raises}</span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire
                                      ?.accomplishments?.raises
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>{commonStrings.profile.Bonuses}</span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire
                                      ?.accomplishments?.bonuses
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          {/* <li>
                            <div className="skill_body_left">
                              <span>
                                {commonStrings.profile.Publicacknowledgments}
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire
                                      ?.accomplishments?.acknowledgment
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li> */}
                          <li>
                            <div className="skill_body_left">
                              <span>{commonStrings.profile.Promotions}</span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire
                                      ?.accomplishments?.promotions
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>
                                {commonStrings.profile.Privaterecognition}
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire
                                      ?.accomplishments?.recognition
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>{commonStrings.profile.CompanyWideRecog}</span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire
                                      ?.contributions?.recognition
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      {/* <h5 className="rate-title">
                      Top 2 preferred methods for evaluating your work contributions
                      </h5>
                      <div className="skill_body">
                        <ul>
                          <li>
                            <div className="skill_body_left">
                              <span>{commonStrings.profile.ManagerAckn}</span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire
                                      ?.contributions?.acknowlgement
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                        
                          <li>
                            <div className="skill_body_left">
                              <span>
                                {commonStrings.profile.Publicacknowledgments}
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire
                                      ?.accomplishments?.acknowledgment
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>{commonStrings.profile.Promotions}</span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire
                                      ?.accomplishments?.promotions
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="skill_body_left">
                              <span>
                                {commonStrings.profile.Privaterecognition}
                              </span>
                            </div>
                            <div className="ratings-view">
                              <p>
                                <span
                                  className={getRankingClass(
                                    talentDetailsData?.questionnaire
                                      ?.accomplishments?.recognition
                                  )}
                                ></span>
                              </p>
                              <label>
                                <small>1</small>
                                <small>2</small>
                                <small>3</small>
                                <small>4</small>
                                <small>5</small>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="details_list mt20">
                        <ul>
                          {talentDetailsData?.questionnaire?.impBenifitsDetails
                            ?.length > 0 && (
                              <li>
                                <span>{commonStrings.profile.Whatbenefitsaremostimportanttoyou}</span>
                                {talentDetailsData?.questionnaire?.impBenifitsDetails?.map(
                                  (item) =>
                                    item?.name && <label>{item.name}</label>
                                )}
                              </li>
                            )}
                        </ul>
                      </div> */}
                      
                     

                    
                      { <div className="details_list mt20">
                        <ul>
                          <li>
                            <span>{commonStrings.profile.AreYouInterestedToSitInBoard}</span>
                            <label>{talentDetailsData?.questionnaire?.opportunitiesOnBoard == true ? "Yes" : "No"}</label>
                          </li>
                        </ul>
                      </div>}
                    </div>
                  </Tab>
                )}
                {!(talentDetailsData?.questionnaire?.memberType === "Student" && talentDetailsData?.questionnaire?.studentType?.value === 1) &&
                 <Tab
                  eventKey="biography"
                  title={commonStrings.profile.EducationInformation}
                >
                  <div className="details_list">
                    <ul>
                      <li>
                        <span>
                          {commonStrings.profile.HighestLevelofEducation}
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire?.fieldStudyDetails
                            ? talentDetailsData?.questionnaire
                              ?.fieldStudyDetails.name
                            : "--"}
                        </label>
                      </li>
                      <li>
                        <span>
                          {'Major'}
                        </span>
                        <label>
                          {talentDetailsData?.questionnaire?.majorDetails?.name
                            ? talentDetailsData.questionnaire.majorDetails.name
                            : "--"}
                        </label>
                      </li>
                      {isMatchplicityDomain() && (
                        <>
                          <li>
                            <span>{commonStrings.profile.University}</span>
                            <label>
                              {talentDetailsData?.questionnaire?.universityDetails?.name
                                ? talentDetailsData?.questionnaire?.universityDetails?.name
                                : "--"}
                            </label>
                          </li>
                         { talentDetailsData?.questionnaire?.gpa && talentDetailsData?.questionnaire?.memberType == "Student" && <li>
                            <span>{commonStrings.profile.GPA}</span>
                            <label>
                               {talentDetailsData.questionnaire.gpa}
                            </label>
                          </li>}
                          <li>
                            <span>{commonStrings.profile.GraduationYear}</span>
                            <label>
                              {talentDetailsData?.questionnaire?.graduationYr
                                ? talentDetailsData?.questionnaire?.graduationYr
                                : "--"}
                            </label>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </Tab>}
                <Tab
                  eventKey="socialMedia"
                  title={commonStrings.profile.SocialMedia}
                >
                  <div className="details_list">
                    <ul>
                      <li className="listtitle">
                        <span>{commonStrings.SocialMediaHandles}</span>
                      </li>
                      <li>
                        <span>{commonStrings.LinkedIn}</span>
                        <label>
                          {talentDetailsData?.socialMedia?.linkedIn
                            ? talentDetailsData?.socialMedia?.linkedIn
                            : "--"}
                        </label>
                      </li>
                      <li>
                        <span>{commonStrings.Facebook}</span>
                        <label>
                          {talentDetailsData?.socialMedia?.facebook
                            ? talentDetailsData?.socialMedia?.facebook
                            : "--"}
                        </label>
                      </li>
                      <li>
                        <span>{commonStrings.Twitter}</span>
                        <label>
                          {talentDetailsData?.socialMedia?.twitter
                            ? talentDetailsData?.socialMedia?.twitter
                            : "--"}
                        </label>
                      </li>
                    </ul>
                  </div>
                </Tab>
              </Tabs>
            </div>
          }
        </Offcanvas.Body>
      </Offcanvas>}
    </>
  );
}

export default ProfileModal;
