
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createContext, useContext, useEffect, useState } from 'react';
export function showToast(message, type) {
  switch (type) {
    case 'success':
      toast.success(message,{
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
      break;
    case 'error':
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
      break;
    default:
      toast(message,{
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
      break;
  }
}

 const UserContext = createContext();
 export function useUserData() {
   return useContext(UserContext);
 }

 export function UserProvider({ children }) {
   const [userData, setUserData] = useState({ username: 'staticUsername'});

   useEffect(() => {
     const storedUser = localStorage.getItem('user');
     if (storedUser) {
       setUserData(JSON.parse(storedUser));
     }
   }, []);

   return (
     <UserContext.Provider value={{ userData }}>
       {children}
     </UserContext.Provider>
   );
 }

 
// Function to cancel the request using AbortController
export const cancelCommunityListRequest = (abortController) => {
  if (abortController && abortController.abort) {
    abortController.abort();
  }
};