
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    uploadedfiles: null,
    ProfileImg:"",
    downloadSignedUrlDoc:"",
    loading: false,
    individualSignupFail : "",
    individualSignupFail : "",
    topicData: "",
    updateViewCountSuccess: "",
    updateViewCountFail: "",
    discCreateSuccess: "",
    discCreateFailure: "",
    discDetailsSuccess: "",
    discDetailsFailure: "",
    communityFeaturedSuccess: "",
    communityFeaturedFailure: "",
    featuredList: [],
    followFailed : "",
    followSuccess: "",
    joinFailed: "",
    joinedSuccess: "",
    featureListFilter: {
        title: "",
        locationIds: []
    },
    unfollowSuccess : "",
    unfollowFailure : "",
    unjoinSuccess: "",
    unjoinFailure: "",
    plansList: [],
    paymentMethodsSuccess: "",
    paymentMethodsFailure: "",
    attachPayFailed: "",
    attachPaySuccess : "",
    subscribeSuccess: "",
    subscribeFailure: "",
    chargePlanSuccess: "",
    chargePlanFailure: "",
    saveEventSuccess: "",
    saveEventFailure: "",
    communityList: [],
    community: "",
    invoiceList: [],
    planSuccess:"",
    planFailed:"",
    attachPaymentLoading: false,
    claimRequestSuccessMsg: "",
    claimRequestFail: "",
    confirmOtpS: "",
    confirmOtpFail: "",
    addedOption:null,
    addMasterFailure:"",
    featuredListLoading: false,
    addMdCategory: ""
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        commonUploads: (state, action) => {
           
            state.uploadedfiles = action.payload;
        },
        uploadedImage:(state, action)=>{
            state.ProfileImg=action.payload
            
        },
        profileImgReset: (state) => {
            state.ProfileImg = ""
        },
        getSignedUrl:(state, action)=>{
        state.downloadSignedUrlDoc=action.payload
        },
        signedUrlReset:(state)=>{
            state.downloadSignedUrlDoc=""
        },
        uploadedFileReset: (state) => {
            state.uploadedfiles = null;
            state.ProfileImg=""
        },
        individualSignupRequest: (state) => {
            state.loading = true
        },
        signupIndividualSuccess: (state,action) => {
            state.individualSignupSuccess = action.payload
            state.loading = false
        },
        signupIndividualFailure: (state,action) => {
            state.individualSignupFail = action.payload
            state.loading = false
        },
        signupToastReset: (state) => {
            state.individualSignupFail = ""
            state.individualSignupSuccess = ""
        },
        topicDetailsData: (state, action) => {
            state.topicData = action.payload
            state.loading = false
        },
        topicDataReset: (state) => {
            state.topicData = ""
        },
        viewCountSuccess: (state, action) => {
            state.updateViewCountSuccess = action.payload
            state.loading = false
        },
        viewCountFailure: (state, action) => {
            state.updateViewCountFail = action.payload
            state.loading = false
        },
        resetViewCount: (state) => {
            state.updateViewCountSuccess = ""
            state.updateViewCountFail = ""
        },
        createDiscussionReset: (state) => {
            state.discCreateSuccess = ""
            state.discCreateFailure = ""
        },
        createDiscussionSuccess: (state, action) => {
            state.discCreateSuccess = action.payload
            state.loading = false
        },
        createDiscussionFailure: (state, action) => {
            state.discCreateFailure = action.payload
            state.loading = false
        },
        discussionDetailsReset: (state) => {
            state.discDetailsSuccess = ""
            state.discCreateFailure = ""
        },
        discussionsDetailsSuccess: (state, action) => {
            state.discDetailsSuccess = action.payload
            state.loading = false
        },
        discussionsDetailsFailure: (state, action) => {
            state.discDetailsFailure = action.payload
            state.loading = false
        },setFeCommunitySuccess: (state, action) => {
            state.communityFeaturedSuccess = action.payload
            state.loading = false
        },
        setFeCommunityFailure: (state, action) => {
            state.communityFeaturedFailure = action.payload
            state.loading = false
        },
        addFeCommunityReset: (state) => {
            state.communityFeaturedFailure = ""
            state.communityFeaturedSuccess = ""
        },
        featCommunityListRequest: state =>{
            state.featuredListLoading = true
            state.featuredList = []
        },
        setFeatureCommList: (state, action) =>{
            state.featuredList = action.payload
            state.loading = false
            state.featuredListLoading = false
        },
        followFeatCommunitySuccess: (state, action) => {
            state.followSuccess = action.payload
            state.loading = false
        },
        followFeatCommunityFailure: (state, action) =>{
            state.followFailed = action.payload
            state.loading =false
        },
        followReset: (state) => {
            state.followFailed = ""
            state.followSuccess = ""
        },
        joinSuccess: (state, action) => {
            state.loading = false
            state.joinedSuccess = action.payload
        },
        joinFailure: (state, action) => {
            state.loading = false
            state.joinFailed = action.payload
        },
        joinReset: (state) => {
            state.joinFailed = ""
            state.joinedSuccess = ""
        },
        updateFeatureListFilter : (state, action) =>{
            state.featureListFilter = action.payload
        },
        resetFeatureFilter : (state)=>{
            state.featureListFilter = {
                title: "",
                locationIds: []
            }
        }, setUnfollowSuccess : (state, action) =>{
            state.unfollowSuccess = action.payload
            state.loading = false
        },
        setUnfollowFailure: (state, action) => {
            state.unfollowFailure = action.payload
            state.loading = false
        },
        resetUnfollow : (state, action) => {
            state.unfollowFailure = ""
            state.unfollowSuccess = ""
        },
        setUnjoinSuccess: (state, action) =>{
            state.unjoinSuccess = action.payload
            state.loading = false
        },setUnjoinFailure: (state, action) => {
            state.unjoinFailure = action.payload
            state.loading = false
        },
        resetUnjoin : (state, action) => {
            state.unjoinSuccess = ""
            state.unjoinFailure = ""
        },
        setPlansList: (state, action) => {
            state.plansList = action.payload
            state.loading = false
        },
        setPaymentMethodSuccess: (state, action) => {
            state.paymentMethodsSuccess = action.payload
            state.loading = false
        },
        setPaymentMethodFailure: (state, action) => {
            state.paymentMethodsFailure = action.payload
            state.loading = false
        },
        paymentMethodsReset: (state) =>{
            state.paymentMethodsSuccess = ""
            state.paymentMethodsFailure = ""
        },
        attachPaymentRequest: (state) => {
            state.attachPaymentLoading = true
        },
        attachPaymentSuccess: (state, action) => {
            state.attachPaySuccess = action.payload
            state.attachPaymentLoading = false
        },
        attachPaymentFailure: (state, action) => {
            state.attachPayFailed = action.payload
            state.attachPaymentLoading = false
        },
        resetAttachPayment : (state) => {
            state.attachPayFailed = ""
            state.attachPaySuccess = ""
        },
        subscribePlanSuccess : (state, action) => {
            state.subscribeSuccess = action.payload
            state.loading = false
        },
        subscribePlanFailure : (state, action) => {
        state.subscribeFailure = action.payload
        state.loading = false
        },
        resetSubscribe: (state) => {
            state.subscribeSuccess = ""
            state.subscribeFailure = ""
        },
        chargeForPlanSuccess: (state, action) => {
            state.chargePlanSuccess = action.payload
            state.loading = false
            },
        chargeForPlanFailure: (state, action) => {
            state.chargePlanFailure = action.payload
            state.loading = false
            },
        chargeForPlanReset: (state) => {
            state.chargePlanFailure = ""
            state.chargePlanSuccess = ""
        },
        eventSaveSuccess: (state, action) => {
            state.saveEventSuccess = action.payload
            state.loading = false
            },
        eventSaveFailure: (state, action) => {
            state.saveEventFailure = action.payload
            state.loading = false
            },
        eventSaveReset: (state) => {
            state.saveEventSuccess = ""
            state.saveEventFailure = ""
        },

        masterAddSuccess: (state, action) => {
            state.addedOption = action.payload
            state.loading = false
            },
        masterAddFailure: (state, action) => {
            state.addedOption = null
            state.addMasterFailure=action.payload
            state.loading = false
            },
        masterAddReset: (state) => {
            state.addedOption = null
        },


        updateCommunityList: (state,action) => {
            state.communityList = action.payload
        },
        updateCommunity: (state, action) => {
            state.community = action.payload
        },
        setInvoiceList: (state, action) => {
            state.invoiceList = action.payload
            state.loading = false
        },
        createPlanSuccess: (state, action) => {
            state.planSuccess = action.payload
            state.loading = false
        },
        createPlanFailure: (state, action) => {
            state.planFailed = action.payload
            state.loading = false
        },
        resetCreatePlan : (state) => {
            state.planSuccess = ""
            state.planFailed = ""
        },
        claimRequestSuccess : (state, action) => {
            state.claimRequestSuccessMsg = action.payload
            state.loading = false
        },
        claimRequestFailure : (state, action) => {
            state.claimRequestFail = action.payload
            state.loading = false
        },
        claimRequestReset: (state) => {
            state.claimRequestFail = ""
            state.claimRequestSuccessMsg = ""
        },
        confirmOtpSuccess: (state, action) => {
            state.confirmOtpS = action.payload
            state.loading = false
        },
        confirmOtpFailure: (state, action) => {
            state.confirmOtpFail = action.payload
            state.loading = false
        },
        confirmOtpReset: (state, action) => {
            state.confirmOtpS = ""
            state.confirmOtpFail = ""
        },
        addedCategory: (state, action) => {
            state.addMdCategory = action.payload
        }
        
        }
});

export const { getSignedUrl,signedUrlReset,commonUploads,uploadedImage ,profileImgReset, uploadedFileReset, signupIndividualFailure, signupIndividualSuccess, individualSignupRequest,
    signupToastReset, topicDetailsData, topicDataReset,   viewCountSuccess,viewCountFailure,  createDiscussionSuccess,
    createDiscussionFailure, createDiscussionReset,discussionsDetailsSuccess,discussionsDetailsFailure, resetViewCount,setFeCommunitySuccess,
    setFeCommunityFailure,addFeCommunityReset,setFeatureCommList,followFeatCommunitySuccess,
    followFeatCommunityFailure,followReset, joinReset, joinFailure, joinSuccess,updateFeatureListFilter,resetFeatureFilter, setUnfollowFailure, setUnfollowSuccess, resetUnfollow,
    setUnjoinSuccess, setUnjoinFailure ,resetUnjoin, setPlansList,setPaymentMethodSuccess,setPaymentMethodFailure,
    paymentMethodsReset, attachPaymentSuccess,attachPaymentFailure,resetAttachPayment,subscribePlanSuccess,
    subscribePlanFailure,resetSubscribe,chargeForPlanSuccess,chargeForPlanFailure, chargeForPlanReset,  eventSaveSuccess,
    eventSaveFailure, eventSaveReset, updateCommunityList, updateCommunity,setInvoiceList,createPlanSuccess,createPlanFailure,resetCreatePlan,
    attachPaymentRequest,
    claimRequestSuccess,
    claimRequestFailure,
    claimRequestReset,
    confirmOtpSuccess,
    confirmOtpFailure,
    confirmOtpReset,
    masterAddFailure,masterAddSuccess,masterAddReset,
    featCommunityListRequest,addedCategory
 } = commonSlice.actions;
export default commonSlice.reducer;
